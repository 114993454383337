import React, { useState, useEffect, useRef, useCallback } from "react";

function Index(props) {
  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);
  return (
    <section className="relative bg-gradient-to-l from-primary to-secondary ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 h-full py-14 flex items-center justify-center text-center flex-col">
        <div className="pb-10 text-3xl lg:text-4xl text-white font-medium">
          <h1>{props.heading}</h1>
          <h1 data-aos="fade-in" data-aos-once="true">
            Simply follow the steps
          </h1>
        </div>
        <div className="w-full">
          <div>
            <div>
              <div
                className={`mt-6 w-full grid gap-y-5 grid-cols-1 gap-x-10 lg:grid-cols-${props.data.length}`}
              >
                {props.data.map((step) => (
                  <>
                    {step.buttonText && step.buttonHref && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={step.buttonHref}
                        className="hover:transition-shadow"
                      >
                        <div
                          data-aos="fade-in"
                          data-aos-once="true"
                          className="flex align-top items-center  flex-col"
                        >
                          <div className="rounded-3xl bg-white w-full h-full shadow-lg p-[30px] lg:p-[40px] flex items-center justify-center">
                            {step.imageURI && (
                              <img
                                src={
                                  window.location.origin +
                                  `/uploads/svg/steps/${step.imageURI}`
                                }
                                alt={step.imageURI}
                                className="h-[200px] w-[200px]"
                              />
                            )}
                          </div>

                          <div className="grid grid-cols-1 gap-y-1 py-5">
                            <h1 className="text-white font-base text-2xl">
                              {step.heading}
                            </h1>
                            <p className="lg:text-base text-base">
                              {step.title}
                            </p>
                          </div>
                          {/* {step.buttonText && step.buttonHref && (
                            <div className="flex justify-center items-start uppercase bg-white w-1/3 my-3 rounded-full hover:shadow-lg hover:brightness-110 cursor-pointer">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={step.buttonHref}
                                className="p-2 px-6 text-xs lg:text-sm text-primary font-medium"
                              >
                                {step.buttonText}
                              </a>
                            </div>
                          )} */}
                        </div>
                      </a>
                    )}
                    {step.buttonText == "" && (
                      <div
                        data-aos="fade-in"
                        data-aos-once="true"
                        className=" flex align-top items-center  flex-col"
                      >
                        <div className="rounded-3xl bg-white w-full h-full shadow-lg p-[30px] lg:p-[40px] flex items-center justify-center">
                          {step.imageURI && (
                            <img
                              src={
                                window.location.origin +
                                `/uploads/svg/steps/${step.imageURI}`
                              }
                              alt={step.imageURI}
                              className="h-[200px] w-[200px]"
                            />
                          )}
                        </div>

                        <div className="grid grid-cols-1 gap-y-1 py-5">
                          <h1 className="text-white font-base text-2xl">
                            {step.heading}
                          </h1>
                          <p className="lg:text-base text-base">{step.title}</p>
                        </div>
                        {/* {step.buttonText && step.buttonHref && (
                          <div className="flex justify-center items-start uppercase bg-white w-1/3 my-3 rounded-full hover:shadow-lg hover:brightness-110 cursor-pointer">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={step.buttonHref}
                              className="p-2 px-6 text-xs lg:text-sm text-primary font-medium"
                            >
                              {step.buttonText}
                            </a>
                          </div>
                        )} */}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
