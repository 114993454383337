import React, { Fragment, useRef, useState, useEffect } from "react";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";
import { Popover, Transition } from "@headlessui/react";

function Index(props) {
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(1);

  const dateFormater = (date) => {
    const newDate = moment(`${date}`, "YYYY-MM-DD HH:mm:ss Z").format(
      "Do MMMM YYYY"
    );
    return <p>{newDate.toString()}</p>;
  };

  useEffect(async () => {
    console.log(props.data);
    const AOS = (await import("aos")).default;
    AOS.init();
  });
  return (
    <section className="bg-lightgray min-h-screen lg:min-h-timeline overflow-y-auto scrollbar ">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="relative flex items-center justify-center mx-auto px-10 lg:px-4 sm:px-6 py-10 lg:py-20">
        <div className="max-w-7xl grid grid-cols-1">
          <div className="mb-14 grid grid-cols-1 gap-y-4">
            <h1 className="text-3xl  font-bold tracking-tight lg:text-4xl pt-20 text-center">
              Our Timeline
            </h1>
            <p className="text-center text-base lg:text-xl text-gray">
              Click on a year to expand
            </p>
          </div>

          <div className=" mt-4">
            {props.data.map((year) => (
              <div className="flex lg:items-center item-start justify-start flex-col">
                <div
                  onClick={() => setOpenIndex(year.index)}
                  className={`${
                    year.index === openIndex ? "" : "cursor-pointer"
                  }`}
                >
                  <h1
                    className={`${
                      openIndex === year.index
                        ? "text-button"
                        : "text-secondary"
                    } text-3xl lg:text-5xl mb-10 font-medium  `}
                  >
                    {year.year}
                  </h1>
                </div>
                {year.index == openIndex && (
                  <div className="grid grid-cols-1 mb-7">
                    {year.yearEvents.map((milestone) => (
                      <div>
                        {year.yearEvents.indexOf(milestone) % 2 == 0 && (
                          <div className="relative">
                            <div
                              className="h-6 w-6 hidden bg-button rounded-full lg:flex items-center z-10 justify-center"
                              style={{
                                position: "absolute",
                                left: 628,
                                top: 20,
                              }}
                            >
                              <div className="bg-secondary border-2 border-white rounded-full h-3 w-3 " />
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 relative">
                              <div className="lg:border-r  lg:border-l-lightgray border-l border-l-button lg:border-r-button p-4 lg:p-20">
                                <div
                                  data-aos="fade-right"
                                  data-aos-once={true}
                                  className="grid grid-cols-1 bg-white rounded-xl lg:drop-shadow-none drop-shadow-lg lg:bg-lightgray p-4 gap-y-10 text-left"
                                >
                                  <h1 className="lg:text-xl text-left lg:text-right text-base font-medium rela">
                                    {milestone.title}
                                  </h1>
                                  <p className="text-gray text-sm lg:text-base  text-left lg:text-right">
                                    {dateFormater(milestone.eventDate)}
                                  </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/uploads/images/timeline/${milestone.imageURI})`,
                                }}
                                className=" bg-cover rounded-2xl md:border-8 md:border-white shadow-2xl bg-center hidden lg:flex border-l-button ml-20 max-w-xl"
                                data-aos="fade-left"
                                data-aos-once={true}
                              ></div>
                            </div>
                          </div>
                        )}
                        {!year.yearEvents.indexOf(milestone) % 2 == 0 && (
                          <div className="relative">
                            <div
                              className="h-6 w-6 hidden bg-button rounded-full lg:flex items-center z-10 justify-center"
                              style={{
                                position: "absolute",
                                left: 628,
                                top: 20,
                              }}
                            >
                              <div className="bg-secondary border-2 border-white rounded-full h-3 w-3 " />
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 relative">
                              <div
                                style={{
                                  backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/uploads/images/timeline/${milestone.imageURI})`,
                                }}
                                className=" bg-cover rounded-2xl md:border-8 md:border-white shadow-2xl bg-center hidden lg:flex border-l-button mr-20 max-w-xl"
                                data-aos="fade-left"
                                data-aos-once={true}
                              ></div>
                              <div className="lg:border-l  lg:border-l-button border-l border-l-button lg:border-r-lightgray p-4 lg:p-20">
                                <div
                                  data-aos="fade-right"
                                  data-aos-once={true}
                                  className="grid grid-cols-1 bg-white rounded-xl lg:drop-shadow-none drop-shadow-lg lg:bg-lightgray p-4 gap-y-10 text-left"
                                >
                                  <h1 className="lg:text-xl text-base font-medium rela">
                                    {milestone.title}
                                  </h1>
                                  <p className="text-gray text-sm lg:text-base  text-left">
                                    {dateFormater(milestone.eventDate)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
