import React, { useState, useEffect, useRef, useCallback } from "react";

function Index(props) {
  const [name, setName] = useState("truck");

  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  return (
    <section className="relative  bg-gradient-to-r from-primary to-secondary ">
      <div
        className="max-w-6xl mx-auto  sm:px-6 h-full  px-10 lg:px-4  text-white"
        data-aos-once="true"
        data-aos="fade-up"
      >
        <div className="lg:py-20 py-10">
          <div className="lg:text-4xl text-2xl font-bold text-center">
            <h1>How to dispose these wastes?</h1>
          </div>
          <div
            className={`grid grid-cols-1 lg:grid-cols-${props.data.length} gap-y-10 gap-24 pt-10 lg:pt-20`}
          >
            {props.data.map((step) => (
              <div className="flex flex-col gap-y-3 lg:gap-y-6 justify-start lg:px-0 ">
                <div
                  data-aos="fade-up"
                  data-aos-once={true}
                  className="flex h-card justify-center items-center p-[20px] bg-white drop-shadow-lg rounded-2xl"
                >
                  {step.imageURI && (
                    <img
                      src={
                        window.location.origin +
                        `/uploads/svg/steps/${step.imageURI}`
                      }
                      alt={step.imageURI}
                      className="p-10"
                    />
                  )}
                </div>
                <p className="text-center py-7 text-xl  font-medium font">
                  {step.heading}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
