import React, { useState, useEffect } from "react";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Vacancies from "../../../../components/vacancies";
import Banner from "../../../../components/product-banner";
import Axios from "axios";

function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageData, setPageData] = useState([]);
  const [banner, setBanner] = useState([]);

  async function getPageData() {
    Axios.get(
      `${process.env.REACT_APP_DEV_API}/about-us/career/vacancies/page-load`,
      {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
          "api-secret": `${process.env.REACT_APP_API_SECRET}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setPageData(res.data.jobs);
        setBanner(res.data.banner);
      }
    });
  }

  useEffect(async () => {
    getPageData();
    const AOS = (await import("aos")).default;
    AOS.init();
  }, []);
  return (
    <div className="">
      <Banner
        href={banner.bannerButtonHref}
        heading={banner.bannerHeading}
        title={banner.bannerTitle}
        buttonText={banner.bannerButtonText}
        svg={banner.bannerImageURI}
      />
      {pageData && <>{pageData.length >= 1 && <Vacancies data={pageData} />}</>}
    </div>
  );
}

export default Index;
