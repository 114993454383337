import Axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";
export default function Strategies(props) {
  const [strategiesSVG, setstrategiesSVG] = useState();
  const [loading, setLoading] = useState(false);
  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  async function getSVG() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/get-svgs`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setstrategiesSVG(res.data[2].imageURI);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  useEffect(() => {
    if (strategiesSVG == null) {
      getSVG();
    }
  }, []);
  return (
    <section className="relative bg-lightgray ">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="relative max-w-7xl  mx-auto lg:px-4 sm:px-6 py-10 px-10 lg:py-20">
        <div className="grid grid-cols-1 gap-y-20">
          {/* Section header */}
          <div className="text-center" data-aos-once="true" data-aos="fade-up">
            <div className="grid grid-cols-1 lg:grid-cols-6 gap-y-10 gap-10 gap-x-10">
              <div
                className={`bg-gradient-to-l hidden from-primary to-secondary col-span-1 lg:col-span-2 rounded-xl p-10  lg:flex justify-center items-center`}
              >
                {strategiesSVG && (
                  <img
                    src={
                      window.location.origin +
                      `/uploads/svg/others/${strategiesSVG}`
                    }
                    alt={strategiesSVG}
                  />
                )}
              </div>
              <div className="flex flex-col col-span-1 lg:col-span-4 items-center justify-center p-5 ">
                <div className="grid grid-cols-1 gap-y-10">
                  <div>
                    <div className="grid grid-cols-1 gap-y-8 justify-center items-center">
                      <div className="col-span-11 flex lg:items-left justify-center  lg:justify-start ">
                        <p className=" lg:text-left text-center text-black text-3xl lg:text-4xl font-bold">
                          Strategies
                        </p>
                      </div>
                      <div className="grid grid-cols-1 gap-y-2 lg:gap-y-4">
                        {props.data.map((strategy) => (
                          <div
                            data-aos="fade-up"
                            data-aos-once="true"
                            className="flex flex-row justify-start items-start "
                          >
                            <div className="mr-5 mt-2 border-4 p-1 rounded-full border-primary h-2 w-2" />
                            <p className="lg:text-lg max-w-xl leading-relaxed text-base text-left">
                              {strategy.title}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
