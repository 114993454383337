import React from "react";
import AnimatedNumber from "animated-number-react";

function Statistics(props) {
  const formatValue = (value) => `${Number(value).toFixed(0)}`;
  return (
    <section className="relative bg-gradient-to-r  from-secondary to-primary ">
      <div className="relative max-w-7xl mx-auto  ">
        <div className="lg:py-20 py-10 flex flex-col items-center justify-center">
          {/* Section header */}
          <div className="flex mb-10 text-center items-left justify-left px-10 lg:px-4 ">
            <p className="text-left text-white text-3xl lg:text-4xl font-bold">
              At a Glance
            </p>
          </div>

          {/* Items */}
          <div
            className={`mx-auto grid gap-y-0 lg:gap-6 lg:grid-cols-${props.data.length} items-start w-full px-20 rounded-3xl border-none  lg:border border-white`}
          >
            {props.data.map((statitic) => (
              <div
                className="relative flex flex-col items-center p-6 "
                data-aos-once="true"
                data-aos="fade-up"
              >
                <h4 className="lg:text-5xl text-4xl text-white font-bold leading-snug tracking-tight mb-4">
                  <AnimatedNumber
                    value={statitic.title}
                    duration={800}
                    formatValue={formatValue}
                  />
                </h4>
                <h4 className="lg:text-lg text-base text-center font-normal leading-snug tracking-tight mb-1 uppercase">
                  {statitic.description}
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Statistics;
