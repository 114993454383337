import React from 'react'

function index() {
  return (
    <section>

              <div className=" h-14 bg-gradient-to-l from-primary to-secondary pointer-events-none" aria-hidden="true"/>
              


    </section>
  )
}

export default index