import React, { useState, useEffect, useRef, useCallback } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import Axios from "axios";

function Index(props) {
  const [svg, setSvg] = useState("");
  const [loading, setLoading] = useState(true);

  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  async function getSVG() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/commercial-svg`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      setSvg(res.data);
      console.log(res.data);
    });
    setLoading(false);
  }

  useEffect(() => {
    getSVG();
  }, []);

  return (
    <section className="relative bg-gradient-to-r from-secondary to-primary">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-10 lg:py-20 h-full flex items-center justify-center ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 gap-x-20">
          <div
            data-aos="fade-up"
            data-aos-once={true}
            className="rounded-3xl invisible  lg:visible h-0 lg:h-full bg-white p-0 lg:p-10 shadow-lg flex items-center justify-center "
          >
            <div
              data-aos="fade-up"
              data-aos-once={true}
              className="flex  justify-center h-full items-center"
            >
              {svg && (
                <img
                  src={
                    window.location.origin +
                    `/uploads/svg/others/${svg}`
                  }
                  alt={svg}
                  className="h-60"
                />
              )}
            </div>
          </div>
          <div className="gap-5">
            <h1 className="lg:text-4xl text-3xl mb-10 font-bold px-10 text-center lg:text-left text-white">
              Key Features
            </h1>
            {props.data && (
              <div>
                <div className="grid grid-cols-1 gap-y-6">
                  {props.data.map((feature) => (
                    <ul class=" ml-6">
                      <li class="text-white grid grid-cols-12">
                        <div className="col-span-1 mt-[5px] rounded-full bg-white max-w-[20px] max-h-[20px] flex justify-center items-center " />
                        <div className="col-span-11">
                          <span class="text-white text-lg md:text-xl">
                            {feature.heading}
                          </span>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
