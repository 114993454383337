import React from "react";
import parse from "html-react-parser";

function index(props) {
  return (
    <section className="relative bg-warning">
      <div className="max-w-6xl md:max-w-5xl mx-auto px-4 sm:px-6 h-full  flex items-center justify-center text-center flex-col">
        <div className="">
          <div>
            <div>
              <div className=" text-black py-3 px-10 grid grid-cols-1 max-w-5xl gap-y-1">
                {/* <h1 className="font-bold">Important Notice</h1> */}
                <p className="text-base md:text-lg xl:text-base ">
                  {parse(`${props.data}`)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default index;
