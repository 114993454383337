import React, { useEffect } from "react";
import "aos/dist/aos.css"; // You can also use <link> for styles
import parse from "html-react-parser";

function Index(props) {
  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });

  function changeToCurrency(num) {
    const price = parseInt(num);
    return "MVR " + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const renderDocumentComponent = (location) => {
    const arrayIndex = props.locations.findIndex(function (loc) {
      return loc.heading === location.heading;
    });

    return (
      <div className="grid grid-cols-1 gap-y-4 mt-5">
        {props.documents[arrayIndex].map((doc) => (
          <div className="flex flex-row justify-between items-center">
            <p className="text-base lg:text-xl">{doc.heading}</p>
            <a
              href={
                window.location.origin +
                `/uploads/documents/${doc.docURI}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 uppercase px-4 rounded-full text-xs lg:text-base cursor-pointer bg-button hover:shadow-lg hover:brightness-105 text-white font-medium"
            >
              Download
            </a>
          </div>
        ))}
      </div>
    );
  };

  const renderRateTable = (location) => {
    const arrayIndex = props.locations.findIndex(function (loc) {
      return loc.heading === location.heading;
    });

    return props.rates[arrayIndex].map((rate) => (
      <div className="lg:mx-5 mx-0 grid grid-cols-12 text-sm lg:text-lg font-medium text-black">
        <p className="capitalize col-span-4">{rate.category}</p>
        <p className="mr-2 col-span-8">
          {changeToCurrency(rate.price)}
          <span className="lg:text-sm text-xs text-gitHub ml-3">
            (Including GST)
          </span>
        </p>
      </div>
    ));
  };

  return (
    <section className="relative bg-white">
      <div className="max-w-7xl mx-auto py-20 flex items-center justify-center">
        <div className="text-center px-10 lg:px-0  text-sm grid grid-cols-1  max-w-7xl gap-y-14 ">
          {props.locations != null && (
            <>
              {props.locations.map((location) => (
                <>
                  <div className="grid  grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-0 lg:gap-y-20">
                    {/* Picture */}
                    <div
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/uploads/images/locations/${location.imageURI})`,
                      }}
                      className="bg-cover bg-center invisible rounded-xl shadow-md lg:visible max-w-2xl"
                    />
                    <div
                      data-aos-once="true"
                      data-aos="fade-up"
                      className=" grid grid-cols-1 gap-y-3   scrollbar lg:gap-y-6 text-left rounded-lg lg:border lg:border-lightgray lg:shadow-lg  py-5 lg:p-10 "
                    >
                      <div className="text-black flex  items-center justify-start text-lg rounded-xl">
                        <div className="flex justify-center items-center w-10 h-10 bg-white rounded-full shadow flex-shrink-0">
                          <svg
                            className="w-6 h-6 text-button fill-button"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </div>
                        <div>
                          <div className="ml-5 text-2xl lg:text-3xl font-medium leading-snug tracking-tight">
                            {location.heading}
                          </div>
                        </div>
                      </div>
                      <div className="lg:text-xl text-base">
                        <p className="text-black lg:leading-relaxed ">
                          {parse(`${location.title}`)}
                        </p>
                      </div>
                      <div className="flex items-start justify-start flex-col">
                        <p className="lg:text-xl text-sm">Disposal Fee:</p>
                      </div>
                      <div>
                        <div className="grid grid-cols-1 gap-2 border border-borderGray rounded-xl p-4">
                          <div className="lg:mx-5 mx-0 grid grid-cols-12">
                            <p className="text-sm text-darkGray col-span-4">
                              Unit
                            </p>
                            <p className="text-sm text-darkGray col-span-8">
                              Rate*
                            </p>
                          </div>
                          <div className="border-b-2  border-lightgray" />
                          {renderRateTable(location)}
                        </div>
                        <p className="mt-3">*The price includes GST</p>
                      </div>
                      {renderDocumentComponent(location)}
                    </div>
                  </div>
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Index;
