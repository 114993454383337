import PDFView from "../../../../components/pdf-viewer";
import React, { useState, useEffect } from "react";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Menu from "../../../../components/media-center-menu";
import View from "../../../../components/media-center-home";

const menuItems = [
  {
    active: true,
    name: "Home",
    to: "/about-us/media-center",
  },
  {
    name: "Press Releases",
    to: "/about-us/media/press-releases",
  },
  {
    name: "Announcements",
    to: "/about-us/media/announcements",
  },
  {
    name: "Bids",
    to: "/about-us/media/bids",
  },
  {
    name: "Circulars",
    to: "/about-us/media/circulars",
  },
  {
    name: "CSR",
    to: "/about-us/media/csr",
  },
];

function Index() {

        useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });
  return (
    <div className="relative ">
      <div className=" max-w-7xl mx-auto px-4 sm:px-6 ">
        <div className="pt-10 lg:pt-10 grid grid-cols-1 lg:grid-cols-12">
          <div className="col-span-3">
            <Menu active="Home" menuItems={menuItems} />
          </div>

          <div className="col-span-9">
            <PDFView />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
