import React, { useState, useRef, useEffect } from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import noData from "../../assets/lotties/nodata.json";
import Lottie from "lottie-react";
import { ReactComponent as Check } from "../../assets/svg/check.svg";

function Index(props) {
  const id = props.data;
  const [dataLimit, setDataLimit] = useState(6);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageArray, setPageArray] = useState([1]);
  const [trigger, setTrigger] = useState("cds");
  // const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [menu, setMenu] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState();

  // Pagination
  async function generateArrayOfNumbers() {
    setPageArray(Array.from(Array(pages).keys(), (n) => n + 1));
  }

  async function getPosts() {
    Axios.get(
      `${process.env.REACT_APP_DEV_API}/about-us/media-center/posts/${id}`,
      {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
          "api-secret": `${process.env.REACT_APP_API_SECRET}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setPosts(res.data.posts);
        setPages(Math.round(res.data.posts.length / 4));

        if (pageArray.length <= 1) {
          generateArrayOfNumbers(pages);
        }
        if (pageArray.length === 1) {
          setTrigger("ss");
          console.log("overload");
        }
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  useEffect(async () => {
    getPosts();
    if (posts) {
      generateArrayOfNumbers(pages);
    }
  }, [trigger]);

  function goToNextPage() {
    if (currentPage < pages) {
      setCurrentPage((page) => page + 1);
    }
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((page) => page - 1);
    }
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return posts.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const dateComponent = (date) => {
    const newDate = moment(`${date}`, "YYYY-MM-DD HH:mm:ss Z").format(
      "Do MMMM YYYY"
    );
    return <p className="text-sm text-gray">{newDate.toString()}</p>;
  };

  useEffect(() => {
    // findNumberOfPages()
  }, []);

  return (
    <section className="relative ">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="lg:pt-5  sm:pt-0">
          {/* Content */}
          <div
            className="max-w-xl lg:max-w-none lg:w-full mx-auto lg:mt-2 mb-10 "
            data-aos-once="true"
            data-aos="fade-up"
          >
            <div className="grid grid-rows-6">
              <div className="grid row-span-5 gap-4 lg:grid-cols-2 sm:grid-cols-1 grid-cols-1 lg:mt-3 mb-2">
                {posts.length > 0 && (
                  <>
                    {getPaginatedData().map((post, idx) => (
                      <div
                        key={post.id}
                        className="drop-shadow-lg  bg-white border-borderGray p-4 rounded-xl h-fit grid grid-cols-1 gap-y-4"
                      >
                        <div className="grid grid-cols-1 gap-y-2">
                          <Link to={post.href}>
                            <p className="lg:text-xl text-base hover:text-secondary cursor-pointer font-medium">
                              {post.heading}
                            </p>
                          </Link>

                          <p className="text-sm">{post.description}</p>
                        </div>
                        <div>
                          {/* Apply Button */}
                          {post.href && (
                            <Link to={post.href}>
                              <a className="rounded-full text-center font-medium px-4 p-1 bg-primary text-white  hover:brightness-110 hover:shadow-lg cursor-pointer text-sm">
                                View
                              </a>
                            </Link>
                          )}
                        </div>
                        <div className="flex flex-row items-center">
                          <CalendarIcon className="w-4 h-4 mr-2 text-gitHub" />
                          {dateComponent(post.createdAt)}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>

              {/* Pagination */}
              {posts.length >= 7 && (
                <div className="flex row-span-1 items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                  <div className="lg:w-5/5 w-full  flex items-center justify-between border-t border-darkGray">
                    <div
                      onClick={() => goToPreviousPage()}
                      className="flex items-center pt-3 text-darkGray hover:text-secondary cursor-pointer"
                    >
                      <svg
                        width={14}
                        height={8}
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.1665 4H12.8332"
                          stroke="currentColor"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.1665 4L4.49984 7.33333"
                          stroke="currentColor"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.1665 4.00002L4.49984 0.666687"
                          stroke="currentColor"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>

                    <div className="flex ">
                      {pageArray.map((page) => (
                        <div
                          className="flex items-center"
                          onClick={() => setCurrentPage(page)}
                        >
                          <p
                            className={`text-sm font-medium leading-none cursor-pointer hover:text-secondary border-t-2 border-white hover:border-indigo-400 pt-3 mr-4 px-2 ${
                              currentPage == page
                                ? "text-secondary border-t-secondary"
                                : "text-black"
                            }`}
                          >
                            {page}
                          </p>
                        </div>
                      ))}
                    </div>

                    <div
                      onClick={() => goToNextPage()}
                      className="flex items-center pt-3 text-darkGray hover:text-secondary cursor-pointer"
                    >
                      <svg
                        width={14}
                        height={8}
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.1665 4H12.8332"
                          stroke="currentColor"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.5 7.33333L12.8333 4"
                          stroke="currentColor"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.5 0.666687L12.8333 4.00002"
                          stroke="currentColor"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {posts.length == 0 && (
              <div className=" h-full row-span-5 space-y-1 mt-0 lg:mt-10  flex justify-center items-center flex-col  text-center">
                <Lottie
                  loop
                  animationData={noData}
                  play
                  style={{ width: 400, height: 400 }}
                />
                <p>No documents currently uploaded</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
