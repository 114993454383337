import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import Axios from "axios";
import parse from "html-react-parser";

function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [searchResults, setSearchResults] = useState([]);
  const [searched, setSearched] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = {
    isCaseSensitive: false,
    includeScore: true,
    shouldSort: true,
    includeMatches: false,
    findAllMatches: false,
    minMatchCharLength: 5,
    location: 0,
    threshold: 0.6,
    distance: 100,
    useExtendedSearch: false,
    ignoreLocation: true,
    ignoreFieldNorm: false,
    fieldNormWeight: 1,
    keys: ["category", "heading", "description", "href", "path"],
  };

  const fuse = new Fuse(searchData, options);

  function searchWithFuse(query) {
    if (!query) {
      setSearched(false);
      setSearchResults(null);
    } else {
      const results = fuse.search(query).map((result) => result.item);
      setSearchResults(results);
      setSearched(true);
      console.log(fuse.search(query));
    }
  }

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_DEV_API}/search`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setSearchData(res.data);
      } else {
        setLoading(true);
      }
    });
  }, []);

  return (
    <div>
      <section className="relative bg-gradient-to-l from-primary to-secondary">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 py-10 ">
          <div className="rounded-xl p-8 flex  flex-col items-center justify-center">
            <input
              onChange={(e) => searchWithFuse(e.target.value)}
              className="w-full px-6 p-2 rounded-full focus:outline-none text-sm lg:text-base"
              placeholder="Search for anything"
              autoFocus
            />
          </div>
        </div>
      </section>
      {searched && (
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 py-5 ">
            <div className="rounded-xl lg:p-8 px-8 flex  flex-col">
              <div className="border-b text-center border-b-borderGray font-medium text-lg overflow">
                <h1 className="text-lg py-5 text-left font-normal">
                  <span className="font-bold">{searchResults.length}</span>{" "}
                  Matching Results found
                </h1>
              </div>

              <div>
                {searchResults.length == 0 && (
                  <div className="grid gap-y-6  text-center pt-8 text-base lg:text-lg pb-4 min-h-fit">
                    <p>No results found</p>
                  </div>
                )}
                {searchResults.length > 0 && (
                  <div className="grid grid-cols-1 gap-2 my-10 h-image overflow-y-auto scrollbar h-full  gap-x-16">
                    <div className="grid grid-cols-1 gap-y-3 mb-5">
                      {searchResults.map((result) => (
                        <a
                          href={result.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="grid grid-cols-1 gap-y-3 rounded-xl drop-shadow-lg bg-white border border-lightgray p-6 cursor-pointer hover"
                        >
                          <h1 className="uppercase font-medium text-primary">
                            {result.category}
                          </h1>
                          <h1 className="lg:text-2xl text-xl font-medium">
                            {result.heading}
                          </h1>
                          <p className="text-xs">{result.path}</p>
                          <p className="lg:text-base text-sm">
                            {parse(result.description)}
                          </p>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Index;
