import Axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";

function FeaturesBlocks(props) {
  const [valuesSVG, setvaluesSVG] = useState();
  const [loading, setLoading] = useState(false);
  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  async function getSVG() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/get-svgs`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setvaluesSVG(res.data[3].imageURI);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  useEffect(() => {
    if (valuesSVG == null) {
      getSVG();
    }
  }, []);

  return (
    <section className="relative">
      <div className="relative max-w-7xl mx-auto px-10 lg:px-4 ">
        <div className="py-10 p lg:py-20">
          <div className="grid grid-cols-1 lg:grid-cols-6 gap-y-10 gap-10 gap-x-10">
            <div className="flex flex-col col-span-1 lg:col-span-4  items-start justify-left  p-5 ">
              <div className="grid grid-cols-1 gap-y-10">
                <div>
                  <div className="grid grid-cols-1 gap-y-8 justify-center items-center">
                    <div className="col-span-11 flex lg:items-left justify-center  lg:justify-start ">
                      <p
                        data-aos="fade-right"
                        data-aos-once="true"
                        className=" lg:text-left text-center text-black text-3xl lg:text-4xl font-bold"
                      >
                        Values
                      </p>
                    </div>
                    <div className="grid grid-cols-1 gap-y-2 lg:gap-y-4">
                      {props.data.map((value) => (
                        <div
                          data-aos="fade-up"
                          data-aos-once="true"
                          className="flex flex-row justify-start items-start "
                        >
                          <div className="mr-5 mt-2 border-4 p-1 rounded-full border-primary h-2 w-2" />
                          <p className="lg:text-lg max-w-xl leading-relaxed text-base text-left">
                            {value.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`bg-lightgray col-span-1 lg:col-span-2 rounded-xl p-10  hidden lg:flex justify-center items-center`}
            >
              {valuesSVG && (
                <img
                  src={
                    window.location.origin +
                    `/uploads/svg/others/${valuesSVG}`
                  }
                  alt={valuesSVG}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
