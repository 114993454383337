import React, { useEffect, useState } from "react";
import ProductBanner from "../../../components/product-banner";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Notice from "../../../components/caps-notice";

function Index() {
  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });
  return (
    <div>
      <ProductBanner heading="Resale" />
    </div>
  );
}

export default Index;
