import React, { useEffect } from "react";

// Animations
import "aos/dist/aos.css"; // You can also use <link> for styles

function Index(props) {
  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });

  return (
    <section className="relative">
      <div className="bg-white">
        <div className="max-w-7xl mx-auto  lg:px-4 text-center sm:px-6 px-10 py-10 lg:py-20 mb-20">
          <div className="space-y-20">
            <div
              data-aos-once="true"
              data-aos="fade-up"
              className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl"
            >
              <h1 className="text-3xl  font-bold tracking-tight lg:text-4xl pt-20">
                Our Partners
              </h1>
            </div>
            <div
              className={`flex items-center justify-center flex-wrap md:grid-cols-2 grid-cols-${
                props.data.length + 1
              } gap-y-12 gap-6 `}
            >
              {props.data.map((partner) => (
                <div className="flex justify-center items-center">
                  {partner.imageURI && (
                    <img
                      className="mx-auto"
                      src={
                        window.location.origin +
                        `/uploads/images/partners/${partner.imageURI}`
                      }
                      alt=""
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
