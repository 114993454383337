import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs, pdf } from "react-pdf";
import { saveAs } from "file-saver";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Axios from "axios";
import throttle from "lodash/throttle";
import { useParams } from "react-router-dom";
import {
  CalendarIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import { DocumentIcon } from "@heroicons/react/solid";

import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

export default function Sample() {
  let { id } = useParams();
  const pdfWrapper = useRef(null);
  const [initialWidth, setInitialWidth] = useState(null);
  const [docs, setDocs] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [mainDoc, setMainDoc] = useState({});
  const [docSelected, setDocSelected] = useState(false);
  const [postData, setPostData] = useState({});
  const [mainPath, setMainPath] = useState("");
  const renderDoc = selectedDoc;

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener("resize", throttle(setPdfSize, 3000));
    };
  }, [selectedDoc]);

  const dateComponent = (date) => {
    const newDate = moment(`${date}`, "YYYY-MM-DD HH:mm:ss Z").format(
      "Do MMMM YYYY"
    );
    return <p>{newDate.toString()}</p>;
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function previousPage() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  function nextPage() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  async function getDocuments(id) {
    Axios.get(
      `${process.env.REACT_APP_DEV_API}/about-us/media-center/posts/load-documents/${id}`,
      {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
          "api-secret": `${process.env.REACT_APP_API_SECRET}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setDocs(res.data.docs);
        setSelectedDoc(res.data.docs[0]);
        setPostData(res.data.post);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  async function makeSelected(id) {
    const index = docs.findIndex((doc) => doc.id === id);
    setSelectedDoc(docs[index]);
    setMainPath(JSON.stringify(docs[index].path));
    console.log(mainDoc);
  }

  useEffect(() => {
    if (loading) {
      getDocuments(id);
      console.log("ipd");
      // setMainDocument()
    }
  });

  return (
    <div className="mx-6 my-10 ">
      {!loading && (
        <>
          <div className="grid grid-cols-1 gap-4 ">
            <h1 className="lg:text-3xl text-lg  font-medium">
              {postData.heading}
            </h1>
            <p>{postData.description}</p>
            {/* <div className="flex flex-row items-center">
              <CalendarIcon className="h-4 w-4 mr-3 text-secondary" />
              <p className="text-gray text-sm">
                {dateComponent(postData.createdAt)}
              </p>
            </div> */}
          </div>
          <div className=" mt-5 ">
            {docs && (
              <>
                {docs.length >= 1 && (
                  <>
                    <div className={`grid grid-cols-1 gap-x-10 gap-y-1`}>
                      {docs.map((doc) => (
                        <>
                          <div
                            onClick={() => makeSelected(doc.id)}
                            className="grid  border-b border-b-lightgray grid-cols-12 items-center  cursor-pointer"
                          >
                            <div className="col-span-8 w-3/4  items-start justify-start flex">
                              <p
                                className={`text-base ${
                                  selectedDoc.id === doc.id
                                    ? `text-primary`
                                    : `text-black`
                                }   hover:text-secondary text-center lg:text-left font-medium`}
                              >
                                {doc.heading}
                              </p>
                            </div>
                            <div className="flex justify-center col-span-2 items-center">
                              <a
                                href={
                                  window.location.origin +
                                  `/uploads/documents/${doc.enDocURI}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cursor-pointer px-12 col-span-2 flex flex-row items-center justify-around bg-secondary p-2 hover:brightness-110 hover:shadow-lg text-white font-medium rounded-full text-sm my-5"
                              >
                                <DownloadIcon className="h-4 w-4 mr-3 text-white" />
                                <p>Download</p>
                              </a>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="invisible rounded-xl h-0 lg:h-full lg:visible drop-shadow-lg shadow-lg p-10 mt-10">
            <div className="grid grid-cols-1 gap-4  mb-2 ">
              <h1 className="text-base font-medium">{selectedDoc.heading}</h1>
              <div className="flex flex-row items-center">
                <CalendarIcon className="h-4 w-4 mr-3 text-secondary" />
                <p className="text-gray text-sm">
                  {dateComponent(selectedDoc.createdAt)}{" "}
                </p>
              </div>
            </div>
            <div className="flex  invisible lg:visible items-center justify-center h-0 lg:h-pdf border bg-white border-borderGray">
              <div id="pdfWrapper" ref={pdfWrapper}>
                {selectedDoc && (
                  <Document
                    file={{
                      url:
                        window.location.origin +
                        `/uploads/documents/${selectedDoc.enDocURI}`,
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                )}
              </div>
            </div>
            <div className="flex invisible lg:visible justify-center items-center my-9">
              <div
                onClick={() => previousPage()}
                className="p-3 mx-5 cursor-pointer hover:shadow-lg rounded-full hover:text-primary"
              >
                <ArrowLeftIcon className="text-secondary w-5 h-5" />
              </div>
              <div className="flex flex-row items-center text-sm">
                Page {pageNumber} of {numPages}
              </div>
              <div
                onClick={() => nextPage()}
                className="p-3 mx-5 cursor-pointer hover:shadow-lg rounded-full hover:text-primary"
              >
                <ArrowRightIcon className="text-secondary w-5 h-5 rounded-full " />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
