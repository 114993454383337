import Layout from "./components/layout";
import Ads from "./components/slider";
import Navbar from "./components/flayout-menu";
import Footer from "./components/footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Import Pages --------------------------------------------------------------------->
import Home from "./pages/home";

// Sustainability
import Sustainability from "./pages/sustainability";

// Collection Services
import BuildingService from "./pages/collection-services/house-hold/building-service";
import BuildingPlusService from "./pages/collection-services/house-hold/building-plus-service";
import CAPS from "./pages/collection-services/on-demand/caps";
import CAPSLite from "./pages/collection-services/on-demand/caps-lite";
import CDW from "./pages/collection-services/on-demand/cdw";
import Commercial from "./pages/collection-services/commercial";

// Disposal Services
import ExpiredWaste from "./pages/disposal-services/expired-waste";
import SpecialProjects from "./pages/disposal-services/special-projects";
import TsFacilities from "./pages/disposal-services/ts-facilities";
import WmFacilities from "./pages/disposal-services/wm-facilities";
// Docking Services
import Docking from "./pages/other-services/docking-service";
import Resale from "./pages/other-services/resale";
import VehicleTransfer from "./pages/other-services/vehicle-transfer-service";
// About us

import Timeline from "./pages/about-us/corporate-profile/timeline";
import Company from "./pages/about-us/corporate-profile/our-company";
import Team from "./pages/about-us/corporate-profile/our-team";
import Location from "./pages/about-us/corporate-profile/location";
import Press from "./pages/about-us/media/press-releases";
import PressView from "./pages/about-us/media/press-releases/view";
import Annual from "./pages/about-us/media/annual-reports";
import AnnualView from "./pages/about-us/media/annual-reports/view";
import Announcements from "./pages/about-us/media/announcements";
import AnnouncementsView from "./pages/about-us/media/announcements/view";
import Bids from "./pages/about-us/media/bids";
import BidsView from "./pages/about-us/media/bids/view";
import Circulars from "./pages/about-us/media/circulars";
import CircularView from "./pages/about-us/media/circulars/view";
import CSR from "./pages/about-us/media/csr";
import CSRView from "./pages/about-us/media/csr/view";
import Downloads from "./pages/about-us/others/dowloads";
import Careers from "./pages/about-us/others/careers";
import Partners from "./pages/about-us/others/partners";
import MediaViewPDF from "./pages/about-us/media/view";

// Sitewide Search
import Search from "./pages/search";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter forceRefresh basename="/">
          <Navbar />
          <Routes>
            {/* Routes */}
            <Route forceRefresh path="/" element={<Home />} />
            {/* Sustainability */}
            <Route
              forceRefresh
              path="sustainability"
              element={<Sustainability />}
            />
            {/* Collection Services */}
            {/*<Route path="building-service" element={<BuildingService />} />*/}
            <Route
              exact
              path="building-service"
              element={<BuildingPlusService />}
            />
            <Route exact path="caps" element={<CAPS />} />
            <Route exact path="caps-lite" element={<CAPSLite />} />
            <Route exact path="caps-cdw" element={<CDW />} />
            <Route exact path="commercial" element={<Commercial />} />
            {/* Disposal Services */}
            <Route
              exact
              path="waste-management-facilities"
              element={<WmFacilities />}
            />
            <Route
              exact
              path="transfer-station-facilities"
              element={<TsFacilities />}
            />
            <Route
              exact
              path="special-projects"
              element={<SpecialProjects />}
            />
            <Route exact path="expired-waste" element={<ExpiredWaste />} />
            {/* Other Services */}
            <Route exact path="docking-service" element={<Docking />} />
            <Route exact path="resale" element={<Resale />} />
            <Route
              exact
              path="vehicle-transfer-service"
              element={<VehicleTransfer />}
            />
            {/* About Us */}
            <Route exact path="timeline" element={<Timeline />} />
            <Route exact path="our-company/:id" element={<Company />} />
            <Route exact path="our-team" element={<Team />} />
            <Route exact path="location" element={<Location />} />
            <Route
              exact
              path="about-us/media/press-releases"
              element={<Press />}
            />
            <Route
              exact
              path="about-us/media/press-releases/view/:id"
              element={<PressView />}
            />
            <Route
              exact
              path="about-us/media/bids/view/:id"
              element={<BidsView />}
            />
            <Route
              exact
              path="about-us/media/announcements/view/:id"
              element={<AnnouncementsView />}
            />
            <Route
              exact
              path="about-us/media/circulars/view/:id"
              element={<CircularView />}
            />
            <Route
              exact
              path="about-us/media/annual-reports/view/:id"
              element={<AnnualView />}
            />
            <Route
              exact
              path="about-us/media/csr/view/:id"
              element={<CSRView />}
            />
            <Route
              exact
              path="about-us/media/announcements"
              element={<Announcements />}
            />
            <Route exact path="about-us/media/bids" element={<Bids />} />
            <Route
              exact
              path="about-us/media/circulars"
              element={<Circulars />}
            />
            <Route
              exact
              path="about-us/media/annual-reports"
              element={<Annual />}
            />
            <Route exact path="about-us/media/CSR" element={<CSR />} />
            <Route exact path="downloads" element={<Downloads />} />
            <Route exact path="careers" element={<Careers />} />
            <Route exact path="partners" element={<Partners />} />
            <Route
              exact
              path="/website/about-us/media-center-view/:id"
              element={<MediaViewPDF />}
            />
            {/* Search */}\
          </Routes>
          <Footer />
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
