import React, { useState, useRef, useEffect } from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  LocationMarkerIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import Fuse from "fuse.js";
import moment from "moment";

function Index(props) {
  const [dataLimit, setDataLimit] = useState(6);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageArray, setPageArray] = useState([1]);
  const [query, updateQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searched, setSearched] = useState(false);

  const dateComponent = (date) => {
    const newDate = moment(`${date}`, "YYYY-MM-DD HH:mm:ss Z").format(
      "Do MMMM YYYY"
    );
    return <p>{newDate.toString()}</p>;
  };

  // Posted time and date render
  const RenderedDate = (today) => {
    const postedTimeDate = moment(
      `${today}`,
      "YYYY-MM-DD HH:mm:ss Z"
    ).fromNow();
    return <p>Posted {postedTimeDate}</p>;
  };

  const options = {
    isCaseSensitive: false,
    includeScore: false,
    shouldSort: true,
    includeMatches: false,
    findAllMatches: false,
    minMatchCharLength: 1,
    location: 0,
    threshold: 0.6,
    distance: 100,
    useExtendedSearch: false,
    ignoreLocation: false,
    ignoreFieldNorm: false,
    fieldNormWeight: 1,
    keys: ["title", "location"],
  };

  const fuse = new Fuse(props.data, options);

  // Pagination

  const [pages] = useState(Math.round(props.data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);

  function generateArrayOfNumbers(pages) {
    setPageArray(Array.from(Array(pages).keys(), (n) => n + 1));
  }

  function goToNextPage() {
    if (currentPage < pages) {
      setCurrentPage((page) => page + 1);
    }
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((page) => page - 1);
    }
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return props.data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  function searchWithFuse(query) {
    if (!query) {
      setSearched(false);
      setSearchResults(null);
    } else {
      const results = fuse.search(query).map((result) => result.item);
      setSearchResults(results);
      setSearched(true);
    }
  }

  const ActiveStatus = (status, deadline) => {
    // const currentDate = moment().format('MM/DD/YYYY');
    // const deadlineDate = moment(`${deadline}`, "MM/DD/YYYY");
    // // const difference = currentDate.from(deadlineDate);
    var a = moment();
    var b = moment(deadline);
    var statusOfTime = b.from(a);
    // "a day ago"
    if (status === "inActive") {
      return <p className="text-red">Deceased</p>;
    } else {
      if (statusOfTime.includes("ago")) {
        return <p className="text-red">Expired</p>;
      } else {
        return <p className="text-primary">Active</p>;
      }
    }
  };

  const deadlineDate = (deadline) => {
    const deadlineF = moment(`${deadline}`, "MMMM Do YYYY").format(
      "Do MMMM YYYY"
    );
    return <p>{deadlineF.toString()}</p>;
  };

  useEffect(() => {
    generateArrayOfNumbers(pages);
    // findNumberOfPages()
  }, []);

  return (
    <section className="relative">
      {!searched && (
        <div
          className="absolute inset-0 h-mini-banner bottom-3/4  bg-lightgray pointer-events-none"
          aria-hidden="true"
        />
      )}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:pb-10">
        <div className="pt-12 lg:pt-10">
          {/* Content */}
          <div
            className="max-w-xl lg:max-w-none lg:w-full mx-auto  lg:mt-6 "
            data-aos-once="true"
            data-aos="fade-right"
          >
            {/* Vacancies */}
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-6 gap-y-4 my-4">
                <div className="col-span-4">
                  <h1 className="font-medium text-gitHub text-2xl lg:text-4xl">
                    Vacancies
                  </h1>
                </div>
                <div className="flex lg:justify-end lg:col-span-2">
                  <div className="border-borderGray bg-white focus:border-primary focus:outline ring-offset-primary outline-primary w-full border rounded-lg flex flex-row items-center px-5">
                    <SearchIcon className="w-4 h-4 text-secondary" />
                    <input
                      onChange={(e) => searchWithFuse(e.target.value)}
                      className="border-none focus:border-none focus:ring-0 w-full ring-primary focus:outline-none  px-4 p-1"
                      placeholder="Search for a job or a location"
                      type={"search"}
                    />
                  </div>
                </div>
              </div>

              {searched && (
                <div>
                  <h1 className="text-lg py-5">
                    Results : {searchResults.length} Matching Results found
                  </h1>
                  <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-1 grid-cols-1 lg:mt-6 mb-16">
                    {searchResults.map((job) => (
                      <div className="border bg-white shadow-lg border-borderGray p-4 rounded-xl h-full grid grid-cols-1 gap-y-4">
                        <div>
                          <div className="flex flex-row justify-between mb-2">
                            <p className="text-xs text-primary">
                              {ActiveStatus(job.status, job.deadline)}
                            </p>
                            <p className="text-xs text-darkGray">
                              {RenderedDate(job.createdAt)}
                            </p>
                          </div>
                          <p className="text-xl font-medium">{job.title}</p>
                        </div>
                        <div className="">
                          {/* View Button */}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={job.buttonHref}
                            className="w-24 rounded-xl font-medium text-ellipsis text-center px-4 p-1  text-white bg-primary hover:brightness-110 hover:text-white cursor-pointer text-xs"
                          >
                            View
                          </a>
                        </div>
                        <div className="flex flex-row justify-between  text-xs">
                          <div className="flex flex-row items-center">
                            <BriefcaseIcon className="w-3 h-3 text-gitHub" />
                            <p className="pl-1 text-xs text-gray">
                              {job.salaryRange}
                            </p>
                          </div>

                          <div className="flex flex-row items-center">
                            <CalendarIcon className="w-3 h-3 text-gitHub" />
                            <p className="pl-1 text-xs text-gray">
                              {dateComponent(job.deadline)}
                            </p>
                          </div>

                          <div className="flex flex-row items-center">
                            <LocationMarkerIcon className="w-3 h-3 text-gitHub" />
                            <p className="pl-1 text-xs text-gray">
                              {job.location}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {!searched && (
                <div>
                  <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-1 grid-cols-1 lg:mt-6 mb-2">
                    {getPaginatedData().map((job, idx) => (
                      <div key={idx} data={job}>
                        <div className="bg-white drop-shadow-lg hover:shadow-lg p-4 rounded-xl h-full grid grid-cols-1 gap-y-4">
                          <div>
                            <div className="flex flex-row justify-between mb-2">
                              <p className="text-xs text-primary">
                                {ActiveStatus(job.status, job.deadline)}
                              </p>
                              <p className="text-xs text-darkGray">
                                {RenderedDate(job.createdAt)}
                              </p>
                            </div>
                            <p className="text-xl font-medium">{job.title}</p>
                          </div>
                          <div className="">
                            {/* Apply Button */}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={job.buttonHref}
                              className="w-24 rounded-xl text-center px-4 p-1  text-white bg-primary hover:brightness-110 hover:text-white cursor-pointer text-xs"
                            >
                              View
                            </a>
                          </div>
                          <div className="flex flex-row justify-between  text-xs">
                            <div className="flex flex-row items-center">
                              <BriefcaseIcon className="w-3 h-3 text-gitHub" />
                              <p className="pl-1 text-xs text-gray">
                                {job.salaryRange}
                              </p>
                            </div>

                            <div className="flex flex-row items-center">
                              <CalendarIcon className="w-3 h-3 text-gitHub" />
                              <p className="pl-1 text-xs text-gray">
                                {dateComponent(job.deadline)}
                              </p>
                            </div>

                            <div className="flex flex-row items-center">
                              <LocationMarkerIcon className="w-3 h-3 text-gitHub" />
                              <p className="pl-1 text-xs text-gray">
                                {job.location}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Pagination */}
                  <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                    <div className="lg:w-5/5 w-full  flex items-center justify-between border-t border-darkGray">
                      <div
                        onClick={() => goToPreviousPage()}
                        className="flex items-center pt-3 text-darkGray hover:text-secondary cursor-pointer"
                      >
                        <svg
                          width={14}
                          height={8}
                          viewBox="0 0 14 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.1665 4H12.8332"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.1665 4L4.49984 7.33333"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.1665 4.00002L4.49984 0.666687"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>

                      <div className="sm:flex hidden">
                        {pageArray.map((page) => (
                          <div
                            className="flex items-center"
                            onClick={() => setCurrentPage(page)}
                          >
                            <p
                              className={`text-sm font-medium leading-none cursor-pointer hover:text-secondary border-t-2 border-white hover:border-indigo-400 pt-3 mr-4 px-2 ${
                                currentPage == page
                                  ? "text-secondary border-t-secondary"
                                  : "text-black"
                              }`}
                            >
                              {page}
                            </p>
                          </div>
                        ))}
                      </div>

                      <div
                        onClick={() => goToNextPage()}
                        className="flex items-center pt-3 text-darkGray hover:text-secondary cursor-pointer"
                      >
                        <svg
                          width={14}
                          height={8}
                          viewBox="0 0 14 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.1665 4H12.8332"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.5 7.33333L12.8333 4"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.5 0.666687L12.8333 4.00002"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
