import React, { useState, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import SubHeader from "../ts-subheaders";
import { Tab } from "@headlessui/react";
import parse from "html-react-parser";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Index(props) {
  const [locationIndex, setLocationIndex] = useState(0);
  const [arrayIndex, setArrayIndex] = useState(0);

  async function findLocationIndex(location) {
    setArrayIndex(0);
    console.log(location);
    const __locationIndex = await props.locations.findIndex(function (loc) {
      return loc.heading === location;
    });
    setLocationIndex(__locationIndex);
  }

  async function findArrayIndex(catName) {
    console.log(catName);
    const __arrayIndex = props.rates[locationIndex].findIndex(function (rate) {
      return rate.category === catName;
    });
    setArrayIndex(__arrayIndex);
  }

  const componentMaker = () => {
    function calculateTotal(total) {
      const __total = parseInt(total);
      return __total.toFixed(2);
    }

    return (
      <div className="grid grid-cols-1 col-span-6 md:col-span-4 gap-x-3 gap-y-2 lg:gap-y-4">
        <label className="lg:text-xl text-base lg:font-medium text-white">
          Total
        </label>
        <div
          className="rounded-xl
                            w-full
                            p-2
                            px-3
                            py-2
                            lg:text-lg
                            text-sm
                            text-black
                            bg-white bg-clip-padding bg-no-repeat
                            transition
                            ease-in-out
                            m-0
                            font-medium
                            border
                            border-borderGray"
        >
          {arrayIndex == null ? (
            <di>00.00</di>
          ) : (
            <div className="">
              MVR {calculateTotal(props.rates[locationIndex][arrayIndex].price)}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderPanel = (location) => {
    const arrayIndex = props.locations.findIndex(function (loc) {
      return loc.heading === location.heading;
      console.log(arrayIndex + "Hello");
    });

    if (
      location.heading === "Greater Male'" ||
      location.heading === "South Region"
    ) {
      return (
        <Tab.Panel key={location.heading}>
          <div className="lg:mt-6 mt-2 grid gap-x-20 grid-cols-1 lg:grid-cols-2 max-w-6xl w-full">
            <div className="p-4 w-full">
              <div className="grid grid-cols-1 gap-y-5 lg:gap-y-10 py-2 lg:py-5">
                <h1 className="text-white font-bold text-xl lg:text-2xl">
                  Prices for Greater Male Area (GMA)
                </h1>
                <h5 className="text-white lg:text-lg text-base">
                  {location.title}
                </h5>
                <div className="grid grid-cols-12 gap-x-2 lg:gap-x-4">
                  <div className="grid grid-cols-1 col-span-6 md:col-span-8 gap-y-2 lg:gap-y-4">
                    <label className="lg:text-xl text-base lg:font-medium text-white">
                      Vehicle Capacity
                    </label>
                    <div class="grid grid-cols-1 gap-y-4 col-span-1 cursor-pointer">
                      <select
                        onChange={(e) => findArrayIndex(e.target.value)}
                        class="form-select appearance-none
                            cursor-pointer
                            block
                            rounded-xl
                            w-full
                            p-2
                            px-3
                            py-2
                            font-normal
                            text-black
                            bg-white bg-clip-padding bg-no-repeat
                            transition
                            ease-in-out
                            lg:text-lg
                            text-sm
                            m-0
                            border-borderGray
                            focus:text-black focus:ring-button focus:border-parrot focus:bg-white focus:outline-none"
                        aria-label="Default select example"
                      >
                        {props.rates[arrayIndex].map((rate) => (
                          <>
                            <option
                              key={rate.category}
                              className="cursor-pointer rounded-lg lg:text-xl text-sm"
                            >
                              {rate.category}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                  {componentMaker(locationIndex)}
                </div>
                <p className="text-white text-sm md:text-base">
                  *Price is inclusive of GST
                </p>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/uploads/images/locations/${location.imageURI})`,
              }}
              className="bg-cover transition-all duration-500 ease-in-out transform  bg-center rounded-md invisible lg:visible max-h-[43vh]"
            />
          </div>
        </Tab.Panel>
      );
    }
  };

  return (
    <section className="bg-white relative">
      <div className="">
        <SubHeader
          heading="For Private Vehicles and Vessels"
          description={props.data}
          image={props.image}
        />

        <section className="relative bg-gradient-to-l from-primary to-secondary">
          <div className="max-w-7xl mx-auto lg:px-4 sm:px-6 py-10 px-10 lg:py-20 h-full flex items-center justify-center ">
            <div data-aos="fade-up" data-aos-once="true" className="">
              <Tab.Group>
                <Tab.List
                  className={`grid grid-cols-${props.locations.length} w-full`}
                >
                  {props.locations.map((location) => (
                    <Tab
                      key={location.heading}
                      className={({ selected }) =>
                        classNames(
                          "py-2.5 text-white border-b-4 border-b-primary/[0.1] border-transparent font-semibold text-lg leading-5  px-4",
                          "focus:outline-none",
                          selected
                            ? "border-b-lime/[9] "
                            : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      <p
                        onClick={() => findLocationIndex(location.heading)}
                        className="text-base lg:text-2xl"
                      >
                        {location.heading}
                      </p>
                    </Tab>
                  ))}
                </Tab.List>
                <div>
                  {props.locations.map((location) => (
                    <>{renderPanel(location)}</>
                  ))}
                </div>
              </Tab.Group>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Index;
