import React, { useState, useRef, useEffect, useCallback } from "react";
// Animations
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Tab } from "@headlessui/react";
import parse from "html-react-parser";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Index(props) {
  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });

  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  return (
    <section className="relative bg-gradient-to-l from-primary to-secondary ">
      <div className="sm:max-w-6xl md:max-w-5xl lg:max-w-7xl px-10 md:mx-auto  h-full py-14 flex items-center justify-center text-center flex-col">
        <div
          data-aos="fade-up"
          data-aos-once={true}
          className="lg:pb-10 pb-5 text-2xl md:text-4xl lg:text-4xl capitalize text-white font-semibold"
        >
          {props.heading}
        </div>
        {/* Tabs */}
        <div className="w-full">
          <Tab.Group>
            {props.locationData && (
              <div>
                {props.locationData.length > 0 && (
                  <Tab.List
                    className={`grid py-5 grid-cols-${props.locationData.length}  px-10 md:px-0 lg:px-0  `}
                  >
                    {props.locationData.map((location) => (
                      <Tab
                        key={location.index}
                        className={({ selected }) =>
                          classNames(
                            "py-2.5 text-white border-b-4 border-b-primary/[0.1] border-transparent font-semibold text-lg leading-5  px-4",
                            "focus:outline-none",
                            selected
                              ? "border-b-lime/[9] "
                              : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                      >
                        <p className="text-xl font-medium">
                          {location.heading}
                        </p>
                      </Tab>
                    ))}
                  </Tab.List>
                )}

                {props.locationData.length <= 1 && (
                  <Tab.List
                    className={`grid  sm:grid-cols-1 gap-y-5 md:grid-cols-${props.locationData.length}   lg:grid-cols-${props.locationData.length}  `}
                  >
                    {props.locationData.map((location) => (
                      <Tab
                        key={location.index}
                        className={({ selected }) =>
                          classNames(
                            "py-2.5 text-white border-b-4 border-b-primary/[0.1] border-transparent font-semibold text-lg leading-5  px-4",
                            "focus:outline-none"
                          )
                        }
                      >
                        <p></p>
                      </Tab>
                    ))}
                  </Tab.List>
                )}
              </div>
            )}

            {props.locationData && (
              <div>
                {props.locationData.length >= 1 && (
                  <div>
                    {/* Panels */}
                    {props.locationFeaturesData && (
                      <div>
                        {props.locationFeaturesData.map((location) => (
                          <Tab.Panel key={location}>
                            <div
                              className={`grid mt-6 sm:grid-cols-1 gap-y-5 md:grid-cols-${location.length}   lg:grid-cols-${location.length} `}
                            >
                              {location.map((feature) => (
                                <div
                                  data-aos="fade-in"
                                  data-aos-once={true}
                                  className="px-10 lg:p-4"
                                >
                                  <div
                                    className={`rounded-3xl bg-white shadow-lg  ${
                                      props.features.length >= 4
                                        ? "p-[20px]"
                                        : "p-[40px]"
                                    }  flex items-center justify-center`}
                                  >
                                    {feature.imageURI && (
                                      <img
                                        src={
                                          window.location.origin +
                                          `/uploads/svg/features/${feature.imageURI}`
                                        }
                                        alt={feature.imageURI}
                                        className="h-[220px] w-[220px]"
                                      />
                                    )}
                                  </div>
                                  <div className="grid grid-cols-1 gap-y-3 py-5">
                                    <h1 className="text-white font-medium sm:text-xl text-xl">
                                      {feature.heading}
                                    </h1>
                                    <p> {parse(`${feature.title}`)}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Tab.Panel>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </Tab.Group>

          {/* If there is no location */}

          {props.features && (
            <div
              className={`grid w-full mt-6 gap-x-5 sm:grid-cols-1 gap-y-5 md:grid-cols-3  lg:grid-cols-${props.features.length}  `}
            >
              {props.features.map((feature) => (
                <div data-aos="fade-in" data-aos-once={true} className=" ">
                  <div
                    className={`rounded-3xl bg-white shadow-lg  ${
                      props.features.length >= 4 ? "p-[30px]" : "p-[40px]"
                    }  flex items-center justify-center`}
                  >
                    {feature.imageURI && (
                      <img
                        src={
                          window.location.origin +
                          `/uploads/svg/features/${feature.imageURI}`
                        }
                        className="h-[220px] w-[220px]"
                        alt={feature.imageURI}
                      />
                    )}
                  </div>
                  <div className="grid grid-cols-1 gap-y-1 py-5">
                    <h1 className="text-white font-base sm:text-xl md:text-base lg:text-2xl">
                      {feature.heading}
                    </h1>
                    <p className="sm:text-sm md:text-xs lg:text-base">
                      {feature.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Index;
