/* This example requires Tailwind CSS v2.0+ */
import { Link, NavLink } from "react-router-dom";
import { Fragment } from "react";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { ArrowCircleRightIcon, MenuIcon, XIcon } from "@heroicons/react/solid";
import { ChevronDownIcon, LockClosedIcon } from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/solid";
import Search from "../../pages/search";
import { useNavigate } from "react-router-dom";
import MobileMenu from "../../components/mobile-menu";
import Axios from "axios";

// const Logo = require("../../assets/images/logo/logo-final.png");
const transition = { duration: 4, yoyo: Infinity, ease: "easeInOut" };

const household = [
  {
    name: "Building Service",
    href: "building-service",
    icon: ArrowCircleRightIcon,
  },
  /*{
    name: "Building Plus Service",
    href: "building-plus-service",
    icon: ArrowCircleRightIcon,
  },*/
];

const onDemand = [
  {
    name: "Call and Pickup Services (CAPS)",
    href: "caps",
    icon: ArrowCircleRightIcon,
  },
  {
    name: "CAPS lite",
    href: "caps-lite",
    icon: ArrowCircleRightIcon,
  },
  {
    name: "CAPS - Construction and Demolition Waste",
    href: "caps-cdw",
    icon: ArrowCircleRightIcon,
  },
];

const commercial = [
  {
    name: "Commercial Collection Service",
    href: "commercial",
    icon: ArrowCircleRightIcon,
  },
];

const otherServices = [
  {
    id: 7,
    name: "Docking Service",
    description:
      "For those customers who wish to use our ramp at our Malé Waste Transfer station",
    href: "docking-service",
    icon: ArrowCircleRightIcon,
    current: false,
    children: [{ name: "", href: "#" }],
  },
  {
    id: 8,
    name: "Vehicle Transfer Service",
    description:
      "For safe transportation of your automobiles between the Greater Malé Area",
    href: "vehicle-transfer-service",
    icon: ArrowCircleRightIcon,
    current: false,
    children: [{ name: "", href: "#" }],
  },
  {
    id: 5,
    name: "Special Projects ",
    description:
      "Providing solutions for projects that require custom waste disposal needs",
    href: "special-projects",
    icon: ArrowCircleRightIcon,
    current: false,
    children: [{ name: "", href: "#" }],
  },
];

const disposalServices = [
  {
    id: 3,
    name: "Our Transfer Station Facilities",
    description:
      "Rely on WAMCO transfer stations for convenient self-disposal of your waste",
    href: "transfer-station-facilities",
    icon: ArrowCircleRightIcon,
    current: false,
    children: [{ name: "", href: "#" }],
  },
  {
    id: 4,
    name: "Our Waste Management Facilities",
    description:
      "Depend on WAMCO Waste Management Facilities to dispose your industrial waste",
    href: "waste-management-facilities",
    icon: ArrowCircleRightIcon,
    current: false,
    children: [{ name: "", href: "" }],
  },

  {
    id: 6,
    name: "Expired / Damaged",
    description:
      "Safe and convenient disposal services for expired and damaged goods",
    href: "expired-waste",
    icon: ArrowCircleRightIcon,
    current: false,
    children: [{ name: "", href: "#" }],
  },
];

const corporateProfile = [
  {
    name: "Our Company",
    href: "our-company/1",
    icon: ArrowCircleRightIcon,
  },
  {
    name: "Board of Directors",
    href: "our-company/2",
    icon: ArrowCircleRightIcon,
  },
  {
    name: "Timeline",
    href: "our-company/3",
    icon: ArrowCircleRightIcon,
  },
  {
    name: "Our Partners",
    href: "our-company/4",
    icon: ArrowCircleRightIcon,
  },
];

const resourceCenter = [
  {
    name: "Media",
    href: "/about-us/media/press-releases",
    icon: ArrowCircleRightIcon,
  },
  {
    name: "Downloads",
    href: "downloads",
    icon: ArrowCircleRightIcon,
  },
  {
    name: "Sustainability",
    href: "sustainability",
    icon: ArrowCircleRightIcon,
  },
];

const careers = [
  {
    name: "Open Vacancies",
    href: "careers",
    icon: ArrowCircleRightIcon,
  },
];

const contactUs = [
  {
    name: "Locations",
    href: "location",
    icon: ArrowCircleRightIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Framer Motion for icon animation
const Container = styled(motion.div)`
  position: relative;
  max-width: 250px;
  cursor: pointer;
`;

const AvasPayLogoMotion = {
  rest: {
    color: "black",
    opacity: 0,
    x: -160,
    y: -35,
    ease: "easeOut",
    duration: 0.2,
    type: "tween",
  },
  hover: {
    color: "black",
    opacity: 1,
    size: 24,
    x: -160,
    y: -35,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeIn",
    },
  },
};

const Mousemotion = {
  rest: {
    color: "grey",
    x: 0,
    transition: {
      duration: 2,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    color: "blue",
    x: 0,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
};

const textMotion = {
  rest: {
    fill: "#08A0A2",
    x: 3,
    opacity: 0.7,
    ease: "easeOut",
    duration: 0.4,
    type: "tween",
  },
  hover: {
    color: "#1CEAB9",
    fill: "#92C740",
    x: 10,
    opacity: 1,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
};

export default function Example() {
  const [isShowingCS, setIsShowingCS] = useState(false);
  const [isShowingDS, setIsShowingDS] = useState(false);
  const [isShowingOS, setIsShowingOS] = useState(false);
  const [isShowingAU, setIsShowingAU] = useState(false);
  const [menuHover, setMenuHover] = useState(true);
  const [icons, setIcons] = useState([]);
  const [iWantToCollectionServices, setIWantToCollectionServices] = useState(
    []
  );
  const [iWantToOtherServices, setIWantToOtherServices] = useState([]);

  function CSOpenHandler() {
    setIsShowingCS(true);
    setMenuHover(true);
  }

  function CSCloseHandler() {
    setIsShowingCS(false);
    setMenuHover(false);
  }

  function CSMouseOpenHandler() {
    if (menuHover) {
      setIsShowingCS(true);
    }
  }

  function DSOpenHandler() {
    setIsShowingDS(true);
    setMenuHover(true);
  }

  function DSCloseHandler() {
    setIsShowingDS(false);
    setMenuHover(false);
  }

  function DSMouseOpenHandler() {
    if (menuHover) {
      setIsShowingDS(true);
    }
  }

  function OSOpenHandler() {
    setIsShowingOS(true);
    setMenuHover(true);
  }

  function OSCloseHandler() {
    setIsShowingOS(false);
    setMenuHover(false);
  }

  function OSMouseOpenHandler() {
    if (menuHover) {
      setIsShowingOS(true);
    }
  }

  function AUOpenHandler() {
    setIsShowingAU(true);
    setMenuHover(true);
  }

  function AUCloseHandler() {
    setIsShowingAU(false);
    setMenuHover(false);
  }

  function AUMouseOpenHandler() {
    if (menuHover) {
      setIsShowingAU(true);
    }
  }

  async function getIcons() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/menu-icons`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      if (res.status == 200) {
        setIcons(res.data);
      }
    });
    Axios.get(
      `${process.env.REACT_APP_DEV_API}/collection-services/all-quick-links`,
      {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
          "api-secret": `${process.env.REACT_APP_API_SECRET}`,
        },
      }
    ).then((res) => {
      if (res.status == 200) {
        setIWantToCollectionServices(res.data);
      }
    });
    Axios.get(
      `${process.env.REACT_APP_DEV_API}/other-services/all-quick-links`,
      {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
          "api-secret": `${process.env.REACT_APP_API_SECRET}`,
        },
      }
    ).then((res) => {
      if (res.status == 200) {
        setIWantToOtherServices(res.data);
      }
    });
  }

  useEffect(() => {
    // getQuickLinks();
    getIcons();
    setMenuHover(false);
  }, []);
  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  const [currentMenu, setCurrentMenu] = useState();
  const [hightlightedMenu, setHighlightedMenu] = useState();

  const getHighligtedMenu = (current) => {
    if (
      current === `${process.env.REACT_APP_PUBLIC_URL}/building-service` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/building-plus-service` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/caps` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/caps-lite` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/caps-cdw` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/commercial`
    ) {
      setCurrentMenu(1);
    }
    if (
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/waste-management-facilities` ||
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/transfer-station-facilities` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/special-projects` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/expired-waste`
    ) {
      setCurrentMenu(2);
    }
    if (
      current === `${process.env.REACT_APP_PUBLIC_URL}/docking-service` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/vehicle-transfer-service`
    ) {
      setCurrentMenu(3);
    }
    if (
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/about-us/corporate-profile/our-company/1` ||
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/about-us/corporate-profile/our-company/2` ||
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/about-us/corporate-profile/our-company/3` ||
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/about-us/corporate-profile/our-company/4` ||
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/about-us/corporate-profile/location` ||
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/about-us/media/press-releases` ||
      current ===
        `${process.env.REACT_APP_PUBLIC_URL}/about-us/others/downloads` ||
      current === `${process.env.REACT_APP_PUBLIC_URL}/about-us/others/careers`
    ) {
      setCurrentMenu(4);
    }
    if (current === `${process.env.REACT_APP_PUBLIC_URL}`) {
      setCurrentMenu(0);
    }
  };

  useEffect(() => {
    setCurrentMenu(0);
    getHighligtedMenu(window.location.href);
  }, []);

  let navigate = useNavigate();

  const icon = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      fill: "#08A0A2",
    },
  };

  const icon1 = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      fill: "#08A0A2",
    },
  };

  const icon2 = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      fill: "#92C740",
    },
  };

  return (
    <Popover className=" sticky top-0 w:full z-30">
      <div className=" absolute  right-10 hidden  lg:flex top-96 justify-around items-center ">
        <div className="grid grid-cols-1 gap-y-5 gap-x-0 ">
          {/* Missed */}

          {/* Avas PAy */}

          <Container
            className="w-14 h-14 bg-white rounded-full drop-shadow-md  flex items-center justify-center"
            initial="rest"
            whileHover="hover"
            animate="rest"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wamco.com.mv/avaspay/"
              className="w-full"
            >
              <div className="flex flex-col p-2 justify-center items-center">
                <svg
                  id="Component_17_6"
                  data-name="Component 17 6"
                  xmlns="https://www.w3.org/2000/svg"
                  width="30"
                  height="35"
                  viewBox="0 0 15.815 20.72"
                >
                  <path
                    id="Path_5246"
                    data-name="Path 5246"
                    d="M1012.847,1033.378h-8.228l4.7-1.509A2.926,2.926,0,0,1,1012.847,1033.378Z"
                    transform="translate(-999.811 -1031.728)"
                    fill="#1acdce"
                  />
                  <path
                    id="Path_5247"
                    data-name="Path 5247"
                    d="M1007.108,1042.116a2.945,2.945,0,0,0,2.082,5.026h6.436v2.379a2.928,2.928,0,0,1-2.927,2.927h-9.961a2.928,2.928,0,0,1-2.927-2.927v-12.052a2.927,2.927,0,0,1,2.926-2.927h9.962a2.927,2.927,0,0,1,2.927,2.926h0v3.785h-6.436A2.936,2.936,0,0,0,1007.108,1042.116Z"
                    transform="translate(-999.811 -1031.728)"
                    fill="#1acdce"
                  />
                </svg>

                <motion.div
                  variants={textMotion}
                  className="h-2 w-2 top-7  absolute rounded-full bg-secondary"
                />
              </div>

              <motion.div
                className="bg-white shadow-sm text-sm absolute left-0 top-[50px] content-center w-[150px] p-1 px-4 text-center rounded-full"
                variants={AvasPayLogoMotion}
              >
                <p>Avas Pay</p>
              </motion.div>
            </a>
          </Container>

          {/* WAMCO Online */}

          <Container
            className="w-14 h-14 bg-white rounded-full drop-shadow-md flex items-center justify-center"
            initial="rest"
            whileHover="hover"
            animate="rest"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wamco.com.mv/online/"
              className="w-full"
            >
              <div className="flex flex-col justify-center items-center">
                <svg
                  id="Component_16_7"
                  data-name="Component 16 7"
                  xmlns="https://www.w3.org/2000/svg"
                  width="30"
                  height="35"
                  viewBox="0 0 13.855 19.331"
                >
                  <path
                    variants={Mousemotion}
                    id="Path_5243"
                    data-name="Path 5243"
                    d="M1042.262,1039.987h-6.317v-2.257a4.2,4.2,0,0,1,4.2-4.2h2.114Z"
                    transform="translate(-1035.944 -1033.527)"
                    fill="#28e4b8"
                  />
                  <path
                    id="Path_5244"
                    data-name="Path 5244"
                    d="M1049.8,1037.73v2.257h-6.317v-6.46h2.114a4.2,4.2,0,0,1,4.2,4.2Z"
                    transform="translate(-1035.944 -1033.527)"
                    fill="#28e4b8"
                  />
                  <path
                    id="Path_5245"
                    data-name="Path 5245"
                    d="M1035.945,1041.12H1049.8v4.729a6.927,6.927,0,0,1-13.853.164c0-.055,0-.109,0-.164Z"
                    transform="translate(-1035.944 -1033.527)"
                    fill="#28e4b8"
                  />
                </svg>
              </div>

              <motion.div
                className="bg-white shadow-sm text-sm absolute left-0 top-[50px] content-center w-[150px] p-1 px-4 text-center rounded-full"
                variants={AvasPayLogoMotion}
              >
                <p>WAMCO Online</p>
              </motion.div>
            </a>
          </Container>
        </div>
      </div>

      <div
        className="absolute inset-0 shadow z-30 pointer-events-none"
        aria-hidden="true"
      />
      <div className="relative z-20  bg-white  flex justify-center">
        <div className="bg-gradient-to-r from-secondary to-primary h-2 z-50"></div>
        <div className="flex w-full max-w-7xl items-center mx-3 lg:mx-10 xl:mx-0   py-3 md:py-0 justify-between md:space-x-4">
          <div className="mr-0">
            <a href="/" className="flex">
              {/* Logo */}
              <div className="p-2">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                  className="item"
                >
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M51.4755 38.2565C51.4755 38.2565 51.4304 38.3341 51.3553 38.4743L51.0099 39.0825C50.7196 39.5831 50.3091 40.2264 49.8686 40.8522C49.2795 41.6857 48.6418 42.4839 47.959 43.2426L46.0193 41.5255C46.6174 40.8319 47.1756 40.1049 47.6912 39.3478C48.0741 38.7797 48.4295 38.1914 48.6798 37.7434L48.9751 37.2002L49.0903 36.98L51.4755 38.2565Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M43.3512 47.2546L43.1435 47.3922L42.5478 47.7702C42.0648 48.0731 41.404 48.456 40.7258 48.8064C39.8214 49.2699 38.8904 49.6794 37.9376 50.0329L37.0967 47.8227C37.9529 47.4847 38.7886 47.0969 39.5995 46.6613C40.2077 46.3335 40.7958 45.978 41.2288 45.6927C41.4416 45.55 41.6193 45.4424 41.7419 45.3473L41.9471 45.2046L43.3437 47.2546H43.3512Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.0359 51.4318L31.7857 51.4594L31.0974 51.5195C30.5292 51.562 29.7684 51.5945 29.01 51.587C28.6371 51.587 28.2592 51.572 27.8963 51.552C27.5334 51.532 27.2105 51.5094 26.9277 51.4844C26.3596 51.4318 25.9841 51.3793 25.9841 51.3793L26.2344 49.2542C26.2344 49.2542 26.5773 49.2943 27.0979 49.3318C27.3482 49.3493 27.661 49.3669 27.9864 49.3769C28.3117 49.3869 28.6521 49.3919 29.005 49.3769C29.6983 49.3769 30.3916 49.3268 30.9097 49.2793L31.5304 49.2117L31.7806 49.1816L32.0309 51.4193L32.0359 51.4318Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.1051 49.8824L19.8723 49.7898L19.2366 49.5245C18.716 49.2992 18.0328 48.9764 17.367 48.6184C16.4798 48.1394 15.6216 47.6087 14.7966 47.029L15.8854 45.4546C16.6568 45.9724 17.4573 46.4455 18.283 46.8713C18.9037 47.1892 19.5345 47.4745 20.02 47.6723L20.6057 47.9026L20.8384 47.9902L20.1051 49.8824Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.3241 42.9943L10.1614 42.8015L9.72593 42.2734C9.36803 41.8354 8.91502 41.2346 8.49205 40.6114C7.92948 39.7804 7.41634 38.9171 6.95532 38.0258L8.45701 37.2373C8.9018 38.0592 9.39488 38.8541 9.93367 39.6177C10.3366 40.1884 10.7696 40.739 11.1075 41.1395L11.5129 41.6226L11.6781 41.8078L10.3216 42.9943H10.3241Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.87549 32.4036L4.82043 32.1533C4.7879 31.9931 4.73784 31.7628 4.68027 31.4875C4.57015 30.9343 4.45002 30.1934 4.36492 29.45C4.25656 28.4576 4.20558 27.4597 4.21225 26.4614H5.6764C5.68911 27.4006 5.75597 28.3383 5.87662 29.2698C5.96672 29.9657 6.09437 30.659 6.20699 31.1721C6.26706 31.4224 6.31712 31.6427 6.34965 31.7904C6.38219 31.938 6.40972 32.0407 6.40972 32.0407L4.87549 32.4061V32.4036Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.97559 20.5471C4.97559 20.5471 5.06318 20.1992 5.22587 19.6535C5.38855 19.1079 5.61631 18.402 5.88661 17.7112C6.25013 16.7846 6.66799 15.8803 7.13802 15.0029L8.23425 15.5786C7.80696 16.4194 7.42923 17.2844 7.10298 18.1693C6.8527 18.8326 6.64997 19.5084 6.51231 20.019C6.37466 20.5296 6.29207 20.88 6.29207 20.88L4.98059 20.5471H4.97559Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.2729 3.54408C20.2729 3.54408 20.6108 3.41642 21.1439 3.24121C21.677 3.066 22.3803 2.86075 23.1011 2.69055C24.0597 2.46559 25.0308 2.29842 26.0094 2.18994L26.1045 2.97088C25.1596 3.09339 24.2228 3.27141 23.2989 3.50403C22.6081 3.68425 21.9248 3.897 21.4217 4.07472C20.9187 4.25243 20.5833 4.38509 20.5833 4.38509L20.2729 3.54408Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M31.8909 2.22998C31.8909 2.22998 32.2513 2.27503 32.7994 2.36514C33.3475 2.45525 34.0683 2.60043 34.7791 2.78065C35.7296 3.02359 36.6648 3.32281 37.5798 3.67673L37.382 4.20487C36.4815 3.87653 35.5622 3.60238 34.629 3.38388C33.9307 3.21868 33.2249 3.09352 32.6918 3.01593C32.1587 2.93834 31.8008 2.90079 31.8008 2.90079L31.8859 2.22998H31.8909Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M42.7431 6.44531C42.7431 6.44531 43.0409 6.65056 43.4814 6.98597C43.9219 7.32137 44.4825 7.77942 45.0306 8.27002C45.7545 8.92384 46.4398 9.61918 47.0829 10.3525L46.8327 10.5778C45.5392 9.15751 44.0806 7.89704 42.4878 6.82327L42.7381 6.45532L42.7431 6.44531Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M50.3616 15.1755C51.2536 16.904 51.9319 18.7347 52.3814 20.6271L52.2713 20.6522C52.0285 19.7174 51.7302 18.798 51.3778 17.8988C51.0243 16.9988 50.6182 16.1204 50.1614 15.2682L50.3616 15.1655V15.1755Z"
                    fill="#CEEEEB"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: { duration: 0.6, ease: "easeInOut" },
                      fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.7544 49.147C22.275 46.3562 23.7767 42.7017 25.8565 41.265C24.2848 38.8371 24.1246 35.5606 24.3073 33.0451C26.3646 36.7195 28.8975 40.4115 32.3313 42.8069C28.5295 43.5903 24.938 45.9457 21.7544 49.157V49.147ZM33.5026 40.4715C31.1125 38.782 28.9801 36.1588 26.6474 32.0314L22.24 24.2319L21.4716 32.4544C21.1938 35.443 21.4566 38.1287 22.27 40.4015C20.3554 42.6241 19.0964 45.8781 18.5508 48.4687L16.1206 60.0001L23.9669 51.585C26.9703 48.3611 30.1213 46.3712 33.1147 45.6653L38.9162 44.3037L33.5026 40.4791V40.4715Z"
                    fill="#CEEEEB"
                    variants={icon2}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#92C740"
                    transition={{
                      default: { duration: 1.8, ease: "easeInOut" },
                      fill: { duration: 1.8, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M49.3056 31.2876C48.0141 32.2538 45.8016 32.0385 43.9095 31.5955C46.1921 30.344 48.2344 28.7921 49.1654 26.4368C50.2792 29.513 52.2113 32.444 54.3838 34.947C52.8345 34.4014 50.5169 33.0197 49.2956 31.2901L49.3056 31.2876ZM55.3248 33.6004C53.2525 31.22 51.6832 28.682 50.8048 26.3041L49.0102 21.4482L47.456 25.0701C46.7427 26.7346 45.2886 28.0737 42.6281 29.4379L36.8716 32.389L44.0497 33.9358C45.2587 34.2242 46.5019 34.3438 47.7438 34.2913C48.2989 34.2575 48.8474 34.1524 49.3757 33.9784C50.8464 35.3673 52.5892 36.436 54.4939 37.1172L60.0001 38.9669L55.3474 33.6204L55.3248 33.6004Z"
                    fill="#CEEEEB"
                    variants={icon2}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#92C740"
                    transition={{
                      default: { duration: 1.8, ease: "easeInOut" },
                      fill: { duration: 1.8, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                  <motion.path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.6365 16.5725C13.8556 12.9131 11.6606 9.37382 8.85746 6.22001C11.3603 7.2888 14.7191 9.31625 16.0956 11.3137C18.3481 10.7004 21.3891 11.4163 23.7567 12.2047C20.483 12.9231 17.0191 14.0945 14.6365 16.5725ZM23.9619 10.1673C22.4323 9.6198 20.8556 9.21427 19.2517 8.95582C18.446 8.83159 17.6316 8.77216 16.8164 8.7781C14.7816 6.86079 11.9209 5.2163 9.67338 4.23261L0 0L6.40721 7.18618C9.21787 10.3375 11.0124 13.5338 11.593 16.5049L12.8995 23.0729L17.1117 18.3872C18.7711 16.5525 21.2539 15.326 25.0281 14.3949L31.5355 12.7929L23.9619 10.1673Z"
                    fill="#CEEEEB"
                    variants={icon1}
                    initial="hidden"
                    animate="visible"
                    strokeWidth="0.5"
                    stroke="#08A0A2"
                    transition={{
                      default: {
                        duration: 1.8,
                        yoyo: "infinity",
                        ease: "easeInOut",
                      },
                      fill: { duration: 1.8, ease: [1, 0, 0.8, 1] },
                    }}
                  />
                </svg>
              </div>

              {/* <div className="text-darkGray flex flex-col px-3">
                        <h1 className="text-xl font-semibold ">WAMCO</h1>
                        <h3 className="text-sm uppercase justify-between">Waste Management</h3>
                    </div> */}
            </a>
          </div>

          <div className="flex-1  hidden  lg:flex items-center justify-center">
            <Popover.Group
              as="nav"
              className="flex space-x-0 md:space-x-0 text-sm xl:text-base  xl:space-x-5 h-full"
            >
              {/* Collection Service */}

              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      onClickCapture={() => CSOpenHandler()}
                      onMouseEnter={() => CSMouseOpenHandler()}
                      onMouseLeave={() => setIsShowingCS(false)}
                      className={classNames(
                        // open ? "text-secondary" : "text-black",
                        "group bg-white rounded-md inline-flex items-center  px-1 py-5 font-medium  hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      <span
                        className={`  ${
                          currentMenu === 1
                            ? " text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary"
                            : ""
                        }`}
                      >
                        Collection Service
                      </span>
                      <ChevronDownIcon
                        className={`ml-2 h-5 w-5 ${
                          currentMenu === 1 ? "text-secondary" : ""
                        }`}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      show={isShowingCS}
                      onMouseEnter={() => setIsShowingCS(true)}
                      onMouseLeave={() => CSCloseHandler()}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                        <div className="max-w-5xl xl:max-w-7xl mx-auto grid gap-y-6 py-3 sm:grid-cols-2 gap-2 xl:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-8 xl:py-16">
                          {/* Household */}
                          <a className="m-3 p-1 flex flex-col justify-between rounded-lg">
                            <div className="flex md:h-full lg:flex-col xl:p-6 p-2 rounded-md hover:bg-lightgray">
                              <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-12 w-12 rounded-md bg-secondary text-white sm:h-12 sm:w-12">
                                  {icons && (
                                    <>
                                      {icons.length >= 1 && (
                                        <img
                                          src={
                                            window.location.origin +
                                            `/uploads/svg/others/${icons[0].icon}`
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                              <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-start lg:ml-0 lg:mt-4">
                                <div>
                                  <p className="text-sm xl:text-base font-medium text-gray-900">
                                    Household
                                  </p>
                                  <p className="mt-1 text-xs xl:text-sm text-gray">
                                    Reliable collection services for the waste
                                    generated everyday
                                  </p>
                                </div>
                                <ul className="mt-10 space-y-1">
                                  {household.map((item) => (
                                    <NavLink
                                      key={item.name}
                                      to={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-sm font-sm "
                                      onClick={() => setIsShowingCS(false)}
                                    >
                                      {item.icon ? (
                                        <item.icon
                                          className="flex-shrink-0 h-5 w-5 text-secondary"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <div></div>
                                      )}
                                      <p className="ml-4 text-sm xl:text-base font-medium text-black hover:text-secondary">
                                        {item.name}
                                      </p>
                                    </NavLink>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </a>

                          {/* On Demand */}

                          <a className="m-3 p-1 flex flex-col justify-between rounded-lg">
                            <div className="flex md:h-full lg:flex-col xl:p-6 p-2 rounded-md hover:bg-lightgray">
                              <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-12 w-12 rounded-md bg-secondary text-white sm:h-12 sm:w-12">
                                  {icons && (
                                    <>
                                      {icons.length >= 1 && (
                                        <img
                                          src={
                                            window.location.origin +
                                            `/uploads/svg/others/${icons[1].icon}`
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                              <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-start lg:ml-0 lg:mt-4">
                                <div>
                                  <p className="text-sm xl:text-base font-medium text-gray-900">
                                    On Demand
                                  </p>
                                  <p className="mt-1 text-xs xl:text-sm text-gray">
                                    For big clean outs and projects, turn to
                                    WAMCO CAPS for the best pickups
                                  </p>
                                </div>
                                <ul className="mt-10 space-y-1">
                                  {onDemand.map((item) => (
                                    <NavLink
                                      key={item.name}
                                      to={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-sm font-sm "
                                      onClick={() => setIsShowingCS(false)}
                                    >
                                      {item.icon ? (
                                        <item.icon
                                          className="flex-shrink-0 h-5 w-5 text-secondary"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <div></div>
                                      )}
                                      <p className="ml-4 text-sm xl:text-base font-medium text-black hover:text-secondary">
                                        {item.name}
                                      </p>
                                    </NavLink>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </a>

                          {/* Commercial */}

                          <a className="m-3 p-1 flex flex-col justify-between rounded-lg">
                            <div className="flex md:h-full lg:flex-col xl:p-6 p-2 rounded-md hover:bg-lightgray">
                              <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-12 w-12 rounded-md bg-secondary text-white sm:h-12 sm:w-12">
                                  {icons && (
                                    <>
                                      {icons.length >= 1 && (
                                        <img
                                          src={
                                            window.location.origin +
                                            `/uploads/svg/others/${icons[2].icon}`
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                              <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-start lg:ml-0 lg:mt-4">
                                <div>
                                  <p className="text-sm xl:text-base font-medium text-gray-900">
                                    Commercial
                                  </p>
                                  <p className="mt-1 text-xs xl:text-sm text-gray">
                                    Take care of your business, and let WAMCO
                                    take care of your waste.
                                  </p>
                                </div>
                                <ul className="mt-10 space-y-1">
                                  {commercial.map((item) => (
                                    <NavLink
                                      key={item.name}
                                      to={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-sm font-sm "
                                      onClick={() => setIsShowingCS(false)}
                                    >
                                      {item.icon ? (
                                        <item.icon
                                          className="flex-shrink-0 h-5 w-5 text-secondary"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <div></div>
                                      )}
                                      <p className="ml-4 text-sm xl:text-base font-medium text-black hover:text-secondary">
                                        {item.name}
                                      </p>
                                    </NavLink>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </a>

                          <div className="border-l-2 border-lightgray pl-8">
                            <h3 className="text-sm font-medium tracking-wide text-black uppercase pb-5">
                              I WANT TO
                            </h3>
                            <ul role="list" className="mt-5 space-y-6">
                              {iWantToCollectionServices && (
                                <>
                                  {iWantToCollectionServices.map((item) => (
                                    <li key={item.id}>
                                      <a
                                        href={item.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="-m-3 p-3 flex items-center rounded-md text-sm font-sm text-gray hover:bg-lightgray"
                                      >
                                        <ArrowCircleRightIcon
                                          className="flex-shrink-0 h-5 w-5 text-secondary"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-4 text-gray">
                                          {item.description}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              {/* Disposal Services */}

              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      onClickCapture={() => DSOpenHandler()}
                      onMouseEnter={() => DSMouseOpenHandler()}
                      onMouseLeave={() => setIsShowingDS(false)}
                      className={classNames(
                        // open ? "text-secondary" : "text-black",
                        "group bg-white  rounded-md inline-flex items-center  py-5 px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      <span
                        className={`${
                          currentMenu === 2
                            ? " text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary"
                            : ""
                        }`}
                      >
                        Disposal Services
                      </span>
                      <ChevronDownIcon
                        className={`ml-2 h-5 w-5 ${
                          currentMenu === 2 ? "text-secondary" : ""
                        }`}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      show={isShowingDS}
                      onMouseEnter={() => setIsShowingDS(true)}
                      onMouseLeave={() => DSCloseHandler()}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                        <div className="max-w-5xl xl:max-w-7xl mx-auto grid gap-y-6 py-3 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-8 xl:py-16">
                          {disposalServices.map((item) => (
                            <NavLink
                              onClick={() => setIsShowingDS(false)}
                              key={item.name}
                              to={item.href}
                              className="m-3 p-1 flex flex-col justify-between rounded-lg"
                            >
                              <div className="flex md:h-full lg:flex-col xl:p-6 p-2 rounded-md hover:bg-lightgray">
                                <div className="flex-shrink-0">
                                  <span className="inline-flex items-center justify-center h-12 w-12 rounded-md bg-secondary text-white sm:h-12 sm:w-12">
                                    {icons && (
                                      <>
                                        {icons.length >= 1 && (
                                          <img
                                            src={
                                              window.location.origin +
                                              `/uploads/svg/others/${icons[item.id].icon}`
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-start lg:ml-0 lg:mt-4">
                                  <div>
                                    <p className="text-sm xl:text-base font-medium text-gray-900">
                                      {item.name}
                                    </p>
                                    <p className="mt-1 text-xs xl:text-sm text-gray">
                                      {item.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              {/* Other Services */}

              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      onClickCapture={() => OSOpenHandler()}
                      onMouseEnter={() => OSMouseOpenHandler()}
                      onMouseLeave={() => setIsShowingOS(false)}
                      className={classNames(
                        // open ? "text-secondary" : "text-black",
                        "group bg-white rounded-md inline-flex items-center text-sm xl:text-base py-5 px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      <span
                        className={`${
                          currentMenu === 3
                            ? " text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary"
                            : ""
                        }`}
                      >
                        Other Services
                      </span>
                      <ChevronDownIcon
                        className={`ml-2 h-5 w-5 ${
                          currentMenu === 3 ? "text-secondary" : ""
                        }`}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      show={isShowingOS}
                      onMouseEnter={() => setIsShowingOS(true)}
                      onMouseLeave={() => OSCloseHandler()}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                        <div className="max-w-5xl xl:max-w-7xl mx-auto grid gap-y-6 py-3 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-8 xl:py-16">
                          {otherServices.map((item) => (
                            <NavLink
                              onClick={() => setIsShowingOS(false)}
                              key={item.name}
                              to={item.href}
                              className="m-3 p-1 flex flex-col justify-between rounded-lg"
                            >
                              {item.icon && (
                                <div className="flex md:h-full lg:flex-col xl:p-6 p-2 rounded-md hover:bg-lightgray">
                                  <div className="flex-shrink-0">
                                    <span className="inline-flex items-center justify-center h-12 w-12 rounded-md bg-secondary text-white sm:h-12 sm:w-12">
                                      {icons && (
                                        <>
                                          {icons.length >= 1 && (
                                            <img
                                              src={
                                                window.location.origin +
                                                `/uploads/svg/others/${icons[item.id].icon}`
                                              }
                                            />
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                  <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-start lg:ml-0 lg:mt-4">
                                    <div>
                                      <p className="text-sm xl:text-base font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                      <p className="mt-1 text-xs xl:text-sm text-gray">
                                        {item.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </NavLink>
                          ))}

                          <div className="border-l-2 border-lightgray pl-8">
                            <h3 className="text-sm font-medium tracking-wide text-black uppercase pb-5">
                              I WANT TO
                            </h3>
                            <ul role="list" className="mt-5 space-y-6">
                              {iWantToOtherServices && (
                                <>
                                  {iWantToOtherServices.map((item) => (
                                    <li key={item.id}>
                                      <a
                                        href={item.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="-m-3 p-3 flex items-center rounded-md text-sm font-sm text-gray hover:bg-lightgray"
                                      >
                                        <ArrowCircleRightIcon
                                          className="flex-shrink-0 h-5 w-5 text-secondary"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-4 text-gray">
                                          {item.description}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              {/* ABout Us */}

              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      onClickCapture={() => AUOpenHandler(true)}
                      onMouseEnter={() => AUMouseOpenHandler(true)}
                      onMouseLeave={() => setIsShowingAU(false)}
                      className={classNames(
                        // open ? "text-secondary" : "text-black",
                        "group bg-white rounded-md inline-flex items-center text-sm xl:text-base py-5 px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      <span
                        className={`${
                          currentMenu === 4
                            ? " text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary"
                            : ""
                        }`}
                      >
                        About Us
                      </span>
                      <ChevronDownIcon
                        className={`ml-2 h-5 w-5 ${
                          currentMenu === 4 ? "text-secondary" : ""
                        }`}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      show={isShowingAU}
                      onMouseEnter={() => setIsShowingAU(true)}
                      onMouseLeave={() => AUCloseHandler()}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="block absolute z-10 pl-20 xl:pl-40 pt-0 xl:pt-14 top-full inset-x-0 transform shadow-lg xl:py-10 py-0 xl:p-20 bg-white">
                        <div className="absolute inset-0 flex">
                          <div className="bg-white w-1/2" />

                          <div className="bg-gray-50 w-1/2" />
                        </div>
                        <div className="relative max-w-5xl xl:max-w-7xl mx-auto  ">
                          <nav className="grid gap-y-10 py-0 xl:py-8 bg-white md:grid-cols-4 sm:grid-cols-3 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12 ">
                            <div>
                              {icons && (
                                <>
                                  {icons.length >= 1 && (
                                    <img
                                      className="h-12 w-12"
                                      src={
                                        window.location.origin +
                                        `/uploads/svg/others/${icons[9].icon}`
                                      }
                                    />
                                  )}
                                </>
                              )}
                              <h3 className="text-xs xl:text-sm font-medium mt-8 tracking-wide text-gray-500 uppercase">
                                Corporate Profile
                              </h3>
                              <ul role="list" className="mt-5 space-y-3">
                                {corporateProfile.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <NavLink
                                      onClick={() => setIsShowingAU(false)}
                                      to={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-xs xl:text-sm font-sm text-black hover:text-secondary"
                                    >
                                      <span>{item.name}</span>
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              {icons && (
                                <>
                                  {icons.length >= 1 && (
                                    <img
                                      className="h-12 w-12"
                                      src={
                                        window.location.origin +
                                        `/uploads/svg/others/${icons[10].icon}`
                                      }
                                    />
                                  )}
                                </>
                              )}
                              <h3 className="text-xs xl:text-sm font-medium mt-8 tracking-wide text-gray-500 uppercase">
                                Get in Touch
                              </h3>
                              <ul role="list" className="mt-5 space-y-6">
                                {contactUs.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <NavLink
                                      onClick={() => setIsShowingAU(false)}
                                      to={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-xs xl:text-sm font-sm text-black hover:text-secondary"
                                    >
                                      <span>{item.name}</span>
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              {icons && (
                                <>
                                  {icons.length >= 1 && (
                                    <img
                                      className="h-12 w-12"
                                      src={
                                        window.location.origin +
                                        `/uploads/svg/others/${icons[11].icon}`
                                      }
                                    />
                                  )}
                                </>
                              )}
                              <h3 className="text-xs xl:text-sm font-medium mt-8 tracking-wide text-gray-500 uppercase">
                                <a href="/about-us/media-center">
                                  Resource Center
                                </a>
                              </h3>
                              <ul role="list" className="mt-5 space-y-6">
                                {resourceCenter.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <NavLink
                                      onClick={() => setIsShowingAU(false)}
                                      to={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-xs xl:text-sm font-sm text-black hover:text-secondary"
                                    >
                                      <span>{item.name}</span>
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              {icons && (
                                <>
                                  {icons.length >= 1 && (
                                    <img
                                      className="h-12 w-12"
                                      src={
                                        window.location.origin +
                                        `/uploads/svg/others/${icons[12].icon}`
                                      }
                                    />
                                  )}
                                </>
                              )}
                              <h3 className="text-xs xl:text-sm font-medium mt-8 tracking-wide text-gray-500 uppercase">
                                Careers
                              </h3>
                              <ul role="list" className="mt-5 space-y-6">
                                {careers.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <NavLink
                                      onClick={() => setIsShowingAU(false)}
                                      to={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-xs xl:text-sm font-sm text-black hover:text-secondary"
                                    >
                                      <span>{item.name}</span>
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </nav>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
          </div>

          <div className="-my-2 flex flex-row items-center">
            <Container
              className="col-span-8 flex items-center justify-center"
              initial="rest"
              whileHover="hover"
              animate="rest"
            >
              <div className="text-xs uppercase cursor-pointer hover:drop-shadow-lg text-white flex justify-center rounded-full items-center flex-row bg-gradient-to-r font-medium p-1 px-3 mr-2 from-primary to-secondary">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wamco.com.mv/online/collection-report/"
                  className="w-full"
                >
                  <div className="flex flex-row justify-center items-center">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="9.671"
                      height="11.012"
                      viewBox="0 0 9.671 11.012"
                    >
                      <g
                        id="Group_4027"
                        data-name="Group 4027"
                        transform="translate(-9.324 0)"
                      >
                        <path
                          id="Path_5241"
                          data-name="Path 5241"
                          d="M1686.827,56.376a.622.622,0,0,1-.621.621h-2.418a.621.621,0,0,1,0-1.242h2.418a.622.622,0,0,1,.621.621Z"
                          transform="translate(-1670.838 -55.755)"
                          fill="#fff"
                        />
                        <path
                          id="Path_5242"
                          data-name="Path 5242"
                          d="M1682.072,58.7h-2.417s-3.625,4.248-3.625,7.254c0,2.518,2.366,2.518,4.833,2.518s4.838,0,4.838-2.518C1685.7,62.948,1682.072,58.7,1682.072,58.7Zm-1.554,1.827h.729a.22.22,0,0,1,.215.232l-.223,3.322a.216.216,0,0,1-.215.2h-.282a.22.22,0,0,1-.219-.2l-.223-3.322A.22.22,0,0,1,1680.518,60.527Zm.362,5.625a.6.6,0,1,1,.6-.594A.593.593,0,0,1,1680.88,66.152Z"
                          transform="translate(-1666.706 -57.46)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <p className="ml-2 text-sm">missed collection</p>
                  </div>
                </a>
              </div>
            </Container>
            {/* Search */}
            {/*<Popover.Group>
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        // open ? "text-secondary" : "text-black",
                        "group bg-white rounded-md inline-flex items-center text-base  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      <SearchIcon
                        className={classNames(
                          // open ? "text-secondary" : "text-black",
                          "h-6 w-6 mt-1 group-hover:text-secondary"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-300"
                      enterFrom="opacity-0 -translate-y-20"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className=" absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                        <div className="">
                          <Search />
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
            */}

            {/* Menu */}
            <Popover.Group className="bg-white ml-2 rounded-md p-2 lg:hidden inline-flex items-center justify-center  focus:outline-none">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        // open ? "text-secondary" : "text-black",
                        "group bg-white rounded-md inline-flex items-center text-base  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      {open && (
                        <>
                          <XIcon
                            className={classNames(
                              // open ? "text-secondary" : "text-black",
                              "h-8 w-8 mt-1 group-hover:text-secondary"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}

                      {!open && (
                        <>
                          <MenuIcon
                            className={classNames(
                              // open ? "text-secondary" : "text-black",
                              "h-8 w-8 mt-1 group-hover:text-secondary"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      onMouseEnter={() => setIsShowingOS(true)}
                      onMouseLeave={() => setIsShowingOS(false)}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-x-full"
                      enterTo="opacity-100 translate-x-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-x-0"
                      leaveTo="opacity-0 translate-x-full"
                    >
                      <Popover.Panel className=" absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                        <div className="">
                          {/* Add Search Component */}

                          <MobileMenu />
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
    </Popover>
  );
}
