import React from "react";
import { CursorClickIcon, MinusCircleIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
export default function index(props) {
  return (
    <section className="px-5 mb-0  lg:bg-gradient-to-l rounded-2xl  p-10">
      <h1 className="text-2xl lg:text-4xl font-medium text-black pb-5 lg:pb-10 border-b-2 border-b-lightgray">
        Media Center
      </h1>
      {/* Menu Items */}
      <div className="grid grid-cols-2 mt-5 lg:mt-10 lg:grid-cols-2 gap-y-6">
        {props.data.map((item) => (
          <Link
            to={item.href}
            className=" lg:col-span-6 flex flex-row justify-start items-center hover:text-secondary cursor-pointer active:text-primary"
            key={item.href}
          >
            <MinusCircleIcon
              className={`${
                item.isActive ? "text-primary" : "text-lightgray"
              } max-h-4 max-w-4 lg:w-8 lg:h-8 mr-2 md:mr-5  `}
            />
            <p
              className={`${
                item.isActive ? "lg:text-gitHub text-secondary" : "text-gitHub"
              }  text-sm lg:text-lg font-medium hover:text-secondary`}
            >
              {item.heading}
            </p>
          </Link>
        ))}
      </div>
    </section>
  );
}
