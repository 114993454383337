import React from "react";

function changeToCurrency(num) {
  const price = parseInt(num);
  return "MVR " + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function Index(props) {
  return (
    <section className="relative">
      <div className="max-w-7xl mx-auto lg:px-4 sm:px-6 py-10 px-10 lg:py-20 ">
        <div className="lg:grid grid-cols-1 gap-y-10 lg:grid-cols-2 lg:gap-6">
          {/* Picture */}
          <div
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/uploads/images/others/${props.image})`,
            }}
            className="bg-cover bg-center rounded-2xl"
            data-aos-once="true"
            data-aos="fade-up"
          />
          <div
            data-aos-once="true"
            data-aos="fade-up"
            className=" grid grid-cols-1 gap-y-5 lg:gap-y-2 text-left shadow-none rounded-lg border-none lg:border border-lightgray lg:shadow-lg py-10 lg:p-10 "
          >
            <div className="text-black flex  items-center justify-start text-lg rounded-xl">
              <div>
                <div className="lg:text-4xl text-xl font-medium leading-snug tracking-tight">
                  {props.name}
                </div>
              </div>
            </div>
            <div className="lg:text-xl text-base my-3 leading-6">
              <p className="text-black ">{props.data}</p>
            </div>
            <div>
              <div className="grid grid-cols-1 my-6 gap-2 border border-borderGray rounded-xl p-4">
                <div className="lg:mx-5 mx-0 grid grid-cols-2">
                  <p className="text-sm text-darkGray">Unit</p>
                  <p className="text-sm text-darkGray">Rate*</p>
                </div>
                <div className="border-b-2  border-lightgray" />
                {props.rates.map((rate) => (
                  <div className="lg:mx-5 mx-0 grid grid-cols-2 text-sm lg:text-lg text-black">
                    <p className="capitalize">{rate.category}</p>
                    <p className="font-medium">
                      {changeToCurrency(rate.price)}
                      {"      "}
                      {/* <span className="lg:text-sm text-xs text-gitHub">
                        (Including GST)
                      </span> */}
                    </p>
                  </div>
                ))}
              </div>
              <p className="mt-3">*The price includes GST</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
