import React, { useEffect, useState } from "react";
import Downloads from "../../../../components/media-downloads";
import Fuse from "fuse.js";
import MiniBanner from "../../../../components/mini-border";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Axios from "axios";

function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getPageData() {
    Axios.get(
      `${process.env.REACT_APP_DEV_API}/about-us/resource-center/downloads/page-load`,
      {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
          "api-secret": `${process.env.REACT_APP_API_SECRET}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setPageData(res.data);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  useEffect(async () => {
    getPageData();
    const AOS = (await import("aos")).default;
    AOS.init();
  }, []);

  return (
    <div className="min-h-screen">
      <MiniBanner />
      <Downloads data={pageData} />
    </div>
  );
}

export default Index;
