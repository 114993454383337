import Axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";

function Features(props) {
  const [objectivesSVG, setObjectivesSVG] = useState();
  const [loading, setLoading] = useState(false);
  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  async function getSVG() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/get-svgs`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setObjectivesSVG(res.data[1].imageURI);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  useEffect(() => {
    if (objectivesSVG == null) {
      getSVG();
    }
  }, []);

  return (
    <section className="relative bg-white">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="relative max-w-7xl mx-auto lg:px-4 sm:px-6 py-10 lg:py-20 px-10 ">
        <div className="max-w-7xl grid grid-cols-1 gap-y-10 lg:gap-y-20">
          {/* Section header */}
          <div
            className="max-w-3xl mx-auto text-center"
            data-aos-once="true"
            data-aos="fade-up"
          >
            <h1 className="h2 mb-4 text-3xl lg:text-4xl font-bold">
              Objectives
            </h1>
          </div>

          {/* Section content */}
          <div className="lg:grid lg:grid-cols-12 gap-y-12 lg:gap-6">
            {/* Content */}

            {/* Tabs items */}
            <div className=" flex items-center justify-center w-full mx-auto  col-span-4 mb-20 lg:mb-0 lg:order-1">
              <div
                className={`bg-lightgray col-span-1 lg:col-span-2 h-full rounded-xl p-10  hidden lg:flex justify-center items-center`}
              >
                {objectivesSVG && (
                  <img
                    src={
                      window.location.origin +
                      `/uploads/svg/others/${objectivesSVG}`
                    }
                    alt={objectivesSVG}
                    style={{ width: "100%" }}
                  />
                )}
              </div>
            </div>

            <div
              className="max-w-lg lg:max-w-none lg:w-full mx-auto lg:col-span-8 "
              data-aos-once="true"
              data-aos="fade-up"
            >
              {/* Tabs buttons */}
              <div className="mb-8 lg:mb-0">
                {props.data.map((objective) => (
                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    className={`flex items-center text-base lg:text-lg p-5 rounded border border-lightgray drop-shadow-lg bg-white transition duration-300 ease-in-out mb-3 `}
                    href="#0"
                  >
                    <div>
                      <div className="font-bold leading-snug tracking-tight mb-1">
                        {objective.title}:
                      </div>

                      <div className="text-gray-600">
                        {objective.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Strategies  */}
        </div>
      </div>
    </section>
  );
}

export default Features;
