import React from "react";
import { CheckCircleIcon, DownloadIcon } from "@heroicons/react/solid";

function index(props) {
  const componentMaker = (location) => {
    const arrayIndex = props.locations.findIndex(function (loc) {
      return loc.heading === location.heading;
    });

    return props.documents[arrayIndex].map((doc) => (
      <div className="grid grid-cols-1 gap-y-0 mt-5 lg:gap-y-5 mb-8  text-lg">
        <div className="flex items-start justify-start gap-x-2">
          <div className="colo-span-1 flex items-center justify-center">
            <CheckCircleIcon className="w-4 h-4 mt-2 md:mt-0 md:w-8 md:h-8  mr-3 lg:mr-5 text-primary" />
          </div>

          {!doc.docURI && (
            <div className="col-span-11">
              <p className="text-left text-base md:text-xl">{doc.heading}</p>
            </div>
          )}
          {doc.docURI && (
            <div className="flex  lg:col-span-2">
              <a
                href={
                  window.location.origin +
                  `/uploads/documents/${doc.docURI}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary text-base md:text-xl"
              >
                <p>{doc.heading}</p>
              </a>
            </div>
          )}
        </div>
      </div>
    ));
  };

  return (
    <section className="relative ">
      <div
        className="max-w-6xl  mx-auto  sm:px-6 h-full"
        data-aos-once="true"
        data-aos="fade-up"
      >
        {props.locations.map((location) => (
          <div className="lg:py-20 py-10 flex flex-col px-10 md:px-0 ">
            <div className="lg:text-4xl w-full text-2xl font-medium text-left ">
              <h1 className="lg:leading-relaxed ">{location.heading}</h1>
            </div>
            <div className="grid grid-cols-1  gap-y-0 gap-10 mt-3 lg:mt-20">
              {/* <div
                style={{
                  backgroundImage: `url(http://rswamco.ddns.net:8811/website/uploads/images/locations/${location.imageURI})`,
                }}
                className={`${
                  location.index / 2 == 1 ? "order-1" : ""
                } bg-cover bg-center invisible  lg:visible lg:h-card h-0 col-span-1 lg:col-span-5 rounded-xl p-0 lg:p-4  flex justify-center items-center`}
              /> */}
              <div className="flex flex-col col-span-1 lg:col-span-6 items-start p-1 ">
                <div className="grid grid-cols-1  lg:gap-y-10">
                  <div>{componentMaker(location)}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default index;
