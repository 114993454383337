import React, { useEffect, useState } from "react";
import ProductBanner from "../../../components/product-banner";
import PrivateVehicles from "../../../components/transfer-intro";
import ProductFeatures from "../../../components/product-features";
import Notice from "../../../components/caps-notice";
import TransferIndividuals from "../../../components/transfer-individuals";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Axios from "axios";

function Index() {
  const [pageData, setPageData] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [ratesData, setRatesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageRatesData, setPageRatesData] = useState([]);

  async function getPageData() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/products/page-load/8`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((response) => {
      setPageData(response.data.pageData);
      setStepsData(response.data.steps);
      setLocations(response.data.locations);
      setFeaturesData(response.data.locationFeatures);
      setRatesData(response.data.locationRates);
      setPageRatesData(response.data.rates);
      setLoading(false);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });

  return (
    <div>
      {loading && (
        <>
          <ProductBanner />
          {/* Description and features */}
          <ProductFeatures />

          {/* <TransferIndividuals/> */}
        </>
      )}
      {!loading && (
        <>
          <ProductBanner
            title={pageData.bannerTitle}
            heading={pageData.bannerHeading}
            buttonText={pageData.bannerButtonText}
            href={pageData.bannerButtonHref}
            svg={pageData.bannerImageURI}
          />
          {/* Notice */}
          {pageData.importantNotice && (
            <Notice data={pageData.importantNotice} />
          )}
          {/* Description and features */}
          <ProductFeatures data={pageData.pageDescription} />
          {/* Private Vehicles */}
          {locations && (
            <PrivateVehicles
              data={pageData.privateTSDescription}
              image={pageData.privateTSImageURI}
              locations={locations}
              rates={ratesData}
            />
          )}
          {/* <TransferIndividuals/> */}

          <TransferIndividuals
            data={pageData.individualTSDescription}
            image={pageData.individualTSImageURI}
            rates={pageRatesData}
          />
        </>
      )}
    </div>
  );
}

export default Index;
