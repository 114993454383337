import { Fragment } from "react";
import * as React from "react";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/solid";
import styled from "styled-components";

const menuData = [
  {
    logo: "../../assets/images/logo/logo-final.png",
    address: `Waste management Corporation Limited (WAMCO)
  Head Office, 03rd Floor, Ma. Jambugasdhoshuge,
  Majeedhee Magu, Male’, 20161, Maldives
  `,
    email: "1666@wamco.com.mv",
    hotline: "1666",
    phone: "+960 3025800",
    social: [
      {
        name: "Facebook",
        href: "https://m.facebook.com/WAMCOmaldives/",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: "https://instagram.com/wamco_mv",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Twitter",
        href: "https://mobile.twitter.com/WAMCOmv",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
    ],
    categories: [
      {
        name: "Collection Service",
        subCategories: [
          {
            name: "Household",
            pages: [
              {
                name: "building Service",
                href: "building-service",
              },
              /*{
                name: "building Plus Service",
                href: "building-plus-service",
              },*/
            ],
          },
          {
            name: "On Demand",
            pages: [
              {
                name: "Call and Pick-up Service",
                href: "caps",
              },
              {
                name: "Caps Lite",
                href: "caps-lite",
              },
              {
                name: "CAPS - Construction and Demolition Waste (CND)",
                href: "caps-cdw",
              },
            ],
          },
          {
            name: "Commercial",
            pages: [
              {
                name: "Commercial waste collection services",
                href: "commercial",
              },
            ],
          },
        ],
        pages: [],
      },
      {
        name: "Disposal Service",
        subCategories: [],
        pages: [
          {
            name: "Transfer Station Facilities",
            href: "transfer-station-facilities",
          },
          {
            name: "Waste Management Facilities",
            href: "waste-management-facilities",
          },

          {
            name: "Expired / Damaged",
            href: "expired-waste",
          },
        ],
      },
      {
        name: "Others Services",
        subCategories: [],
        pages: [
          {
            name: "Docking Service",
            href: "docking-service",
          },
          {
            name: "Vehicle Transfer Service",
            href: "vehicle-transfer-service",
          },

          {
            name: "Special Projects",
            href: "special-projects",
          },
        ],
      },
      {
        name: "About Us",
        subCategories: [],
        pages: [
          {
            name: "Corporate Profile",
            href: "our-company/1",
          },
          {
            name: "Contact Us",
            href: "location",
          },
          {
            name: "Media",
            href: "about-us/media/press-releases",
          },
          {
            name: "Downloads",
            href: "downloads",
          },
          {
            name: "Sustainability",
            href: "sustainability",
          },
          {
            name: "Careers",
            href: "careers",
          },
        ],
      },
    ],
  },
];

function Index() {
  const [screenHeight, setScreenHeight] = React.useState(0);

  React.useEffect(() => {
    setScreenHeight(window.innerWidth);
  }, []);

  // Framer Motion for icon animation
  const Container = styled(motion.div)`
    position: relative;
    max-width: 250px;
    cursor: pointer;
  `;

  const AvasPayLogoMotion = {
    rest: {
      color: "black",
      opacity: 0,
      x: 20,
      y: 10,
      ease: "easeOut",
      duration: 0.2,
      type: "tween",
    },
    hover: {
      color: "black",
      opacity: 1,
      y: 10,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut",
      },
    },
  };

  const Mousemotion = {
    rest: {
      color: "grey",
      x: 0,
      transition: {
        duration: 2,
        type: "tween",
        ease: "easeIn",
      },
    },
    hover: {
      color: "blue",
      x: 0,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut",
      },
    },
  };

  const textMotion = {
    rest: {
      fill: "#08A0A2",
      x: 1.5,
      opacity: 0.7,
      ease: "easeOut",
      duration: 0.4,
      type: "tween",
    },
    hover: {
      color: "#1CEAB9",
      fill: "#92C740",
      x: 5,
      opacity: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut",
      },
    },
  };

  return (
    <div className="grid grid-cols-1 h-screen">
      <div className="py-3 sm:pb-8  bg-white ">
        <div className="flex flex-row justify-around items-center">
          <div className="grid grid-cols-1 gap-y-0 h-14 w-full">
            <div className="grid grid-cols-2 px-5 ">
              {/* Missed */}

              <Container
                className="h-14  flex items-center justify-center"
                initial="rest"
                whileHover="hover"
                animate="rest"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wamco.com.mv/avaspay/"
                  className="w-full flex flex-col justify-center items-center"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      id="Component_17_6"
                      data-name="Component 17 6"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      viewBox="0 0 15.815 20.72"
                    >
                      <path
                        id="Path_5246"
                        data-name="Path 5246"
                        d="M1012.847,1033.378h-8.228l4.7-1.509A2.926,2.926,0,0,1,1012.847,1033.378Z"
                        transform="translate(-999.811 -1031.728)"
                        fill="#1acdce"
                      />
                      <path
                        id="Path_5247"
                        data-name="Path 5247"
                        d="M1007.108,1042.116a2.945,2.945,0,0,0,2.082,5.026h6.436v2.379a2.928,2.928,0,0,1-2.927,2.927h-9.961a2.928,2.928,0,0,1-2.927-2.927v-12.052a2.927,2.927,0,0,1,2.926-2.927h9.962a2.927,2.927,0,0,1,2.927,2.926h0v3.785h-6.436A2.936,2.936,0,0,0,1007.108,1042.116Z"
                        transform="translate(-999.811 -1031.728)"
                        fill="#1acdce"
                      />
                    </svg>

                    <div
                      variants={textMotion}
                      style={{
                        position: "absolute",
                        top: "18px",
                      }}
                      className="h-1 w-1  animate-ping  rounded-full bg-secondary"
                    />
                  </div>

                  <p
                    className="text-center text-xs mt-1.5  w-80"
                    variants={AvasPayLogoMotion}
                  >
                    Avas Pay
                  </p>
                </a>
              </Container>

              {/* WAMCO Online */}

              <Container
                className="h-14  flex items-center justify-center"
                initial="rest"
                whileHover="hover"
                animate="rest"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wamco.com.mv/online/"
                  className="w-full flex flex-col justify-center items-center"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      id="Component_16_7"
                      data-name="Component 16 7"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.855"
                      height="25.331"
                      viewBox="0 0 13.855 19.331"
                    >
                      <path
                        className="animate-pulse"
                        variants={Mousemotion}
                        id="Path_5243"
                        data-name="Path 5243"
                        d="M1042.262,1039.987h-6.317v-2.257a4.2,4.2,0,0,1,4.2-4.2h2.114Z"
                        transform="translate(-1035.944 -1033.527)"
                        fill="#28e4b8"
                      />
                      <path
                        id="Path_5244"
                        data-name="Path 5244"
                        d="M1049.8,1037.73v2.257h-6.317v-6.46h2.114a4.2,4.2,0,0,1,4.2,4.2Z"
                        transform="translate(-1035.944 -1033.527)"
                        fill="#28e4b8"
                      />
                      <path
                        id="Path_5245"
                        data-name="Path 5245"
                        d="M1035.945,1041.12H1049.8v4.729a6.927,6.927,0,0,1-13.853.164c0-.055,0-.109,0-.164Z"
                        transform="translate(-1035.944 -1033.527)"
                        fill="#28e4b8"
                      />
                    </svg>
                  </div>

                  <p
                    className="text-center text-xs mt-1.5  w-80"
                    variants={AvasPayLogoMotion}
                  >
                    WAMCO Online
                  </p>
                </a>
              </Container>
            </div>
          </div>
        </div>
      </div>
      {/* Menu */}
      <div
        style={{ overflow: "scroll" }}
        className="pt-3  px-5 py-2 pb-20  shadow-lg bg-white "
      >
        <div className="grid grid-cols-1">
          <div>
            {menuData.map((data) => (
              <div className="grid grid-cols-1 gap-y-3 pb-10">
                {data.categories.map((category) => (
                  <div className="grid grid-cols-1 gap-y-2">
                    <h1 className="text-sm text-secondary font-medium border-b pb-3">
                      {category.name}
                    </h1>
                    {category.subCategories.length >= 1 && (
                      <div className="grid grid-cols-1 gap-y-0">
                        {category.subCategories.map((subCategory) => (
                          <div>
                            <Disclosure key={subCategory.name}>
                              {({ open }) => (
                                <>
                                  <div className="grid grid-cols-1 ">
                                    <Disclosure.Button
                                      className={`${
                                        open
                                          ? " text-white flex-row rounded-b-none"
                                          : ""
                                      }  flex justify-between w-full px-2 py-2 text-base font-medium text-left text-gitHub  focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75`}
                                    >
                                      <span className="text-black">
                                        {subCategory.name}
                                      </span>
                                      <ChevronDownIcon
                                        className={`${
                                          open ? "transform rotate-180" : ""
                                        } w-4 h-4 text-black`}
                                      />
                                    </Disclosure.Button>
                                    <Transition
                                      className=" rounded-b-xl"
                                      enter="transition duration-150 ease-out"
                                      enterFrom="transform -translate-y-5 opacity-0"
                                      enterTo="transform translate-y-0 opacity-100"
                                      leave="transition duration-75 ease-out"
                                      leaveFrom="transform scale-100 opacity-100"
                                      leaveTo="transform scale-95 opacity-0"
                                    >
                                      <Disclosure.Panel className="px-2  bg-white pb-2 text-sm text-gray-500">
                                        <div className="grid capitalize text-base grid-cols-1 gap-y-4 my-1 p-2 ">
                                          {subCategory.pages.map((page) => (
                                            <a href={`/${page.href}`}>
                                              {page.name}
                                            </a>
                                          ))}
                                        </div>
                                      </Disclosure.Panel>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        ))}
                      </div>
                    )}
                    {category.pages.length >= 1 && (
                      <div className="grid capitalize grid-cols-1 gap-y-4 my-1 p-2 ">
                        {category.pages.map((page) => (
                          <a href={`/${page.href}`}>{page.name}</a>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
