import React, { useCallback, useState, useEffect, useRef } from "react";


function Index(props) {
  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );
  const colors = ["#23CDDC", "#44E7C1"];

  const handleIconError = useCallback((err) => console.error(err.message), []);
  return (
    <section className="relative ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 h-full pb-10 lg:pb-0 lg:py-20 flex flex-col">
        <div className="border-b mb-8 border-b-lightgray" />
        <div className="lg:pb-10 pb-0 mb-5 lg:mb-3 text-3xl lg:text-4xl  text-center lg:text-left font-medium text-darkGray">
          <h1 data-aos="fade-up" data-aos-once={true}>
            {props.heading}
          </h1>
        </div>
        <div
          className={`grid grid-cols-1 lg:grid-cols-${props.steps.length} gap-y-10 gap-x-10 lg:mb-20 p-10 lg:p-10`}
        >
          {/* card */}
          {props.steps && (
            <>
              {props.steps.map((step) => (
                <a
                  href={step.buttonHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-aos="fade-up"
                  data-aos-once={true}
                  className="bg-white hover:shadow-xl hover:brightness-105 cursor-pointer drop-shadow-lg rounded-xl flex items-center justify-center flex-col"
                >
                  <div className="bg-lightPrimary w-full rounded-t-xl flex items-center justify-center py-5 px-10 ">
                    <img
                      src={
                        window.location.origin +
                        `/uploads/svg/steps/${step.imageURI}`
                      }
                      alt={step.imageURI}
                    />
                  </div>
                  <div className="p-4 lg:py-10 py-5  text-center">
                    <p>{step.heading}</p>
                  </div>
                </a>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Index;
