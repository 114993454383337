import React, {useEffect, useState} from 'react';
import ProductBanner from '../../../../components/product-banner';
import "aos/dist/aos.css"; // You can also use <link> for styles

function Index() {


          useEffect(() => {
  window.scrollTo(0, 0)
}, [])

    useEffect(async () => {
        const AOS = (await import('aos')).default;
        AOS.init();
      });
    return (
        <div>
            <ProductBanner heading="Commercial" /> 
        </div>
    )
}

export default Index
