import React from "react";
import parse from "html-react-parser";

function Index(props) {
  return (
    <section className="relative bg-lightgray">
      <div className="sm:max-w-7xl md:max-w-7xl lg:max-w-7xl mx-auto px-10 md:px-20 lg:px-4 sm:px-6 py-20 lg:py-40 flex items-center justify-center">
        <div className="text-center  grid grid-cols-1 max-w-5xl ">
          {props.data && (
            <div className="max-w-5xl text-center mx-auto sm:text-sm md:text-sm lg:text-xl lg:leading-loose text-black mb-0">
              {parse(`${props.data}`)}
            </div>
          )}
          {!props.data && <div className="h-screen "></div>}
        </div>
      </div>
    </section>
  );
}

export default Index;
