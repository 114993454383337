import React, { useState, useEffect, useRef, useCallback } from "react";
import Wave from "react-wavify";
import styled from "@emotion/styled";
import { minWidth } from "@mui/system";

const WaveContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 400px;
  display: flex;
  transform: rotate(0deg);
`;

function Ads(props) {
  // const [name, setName] = useState("truck");

  // const handleOnCompleted = useCallback(
  //   (iconName) => console.log(`${iconName} successfully loaded`),
  //   []
  // );

  // const handleIconError = useCallback((err) => console.error(err.message), []);

  return (
    <section
      className={`relative object-left  bg-secondary  md:py-0 
          md:h-banner h-fit   
       `}
    >
      {/* {!props.isHome && (
        <div className="absolute md:left-1/2 right-1/2 pl-10  transform -translate-x-1/2 -translate-y-1/2">
          <div className=" animate-bounce z-20  bottom-3 bg-white drop-shadow-md rounded-full w-10 h-10 flex items-center justify-center">
            <ArrowDownIcon className="w-5 h-5 font-semibold" />
          </div>
        </div>
      )} */}
      {/* Wave */}
      <div style={{ marginTop: "0px", height: "" }}>
        <WaveContainer level={20}>
          <Wave
            fill="#C3F9ED"
            paused={false}
            opacity="0.13"
            options={{
              height: 160,
              amplitude: 120,
              speed: 0.09,
              points: 4,
            }}
          />
        </WaveContainer>
        <WaveContainer level={20}>
          <Wave
            fill="#7DFF7D"
            opacity="0.15"
            paused={false}
            options={{
              height: 190,
              amplitude: 120,
              speed: 0.1,
              points: 3,
            }}
          />
        </WaveContainer>
        {/* <WaveContainer level={60}>
          <Wave
            fill="#1F678D"
            paused={false}
            opacity="0.5"
            options={{
              height: 160,
              amplitude: 40,
              speed: 0.1,
              points: 4,
            }}
          />
        </WaveContainer> */}
      </div>

      <div className="max-w-7xl   inset-0 mx-auto px-4 z-10 sm:px-6  ">
        <div className="flex justify-center items-end md:items-center   md:justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-0 md:gap-y-20 md:gap-20 ">
            <div
              data-aos="fade-in"
              className="flex items-center justify-center "
            >
              <div className="grid grid-cols-1 py-20 max-w-[350px] md:max-w-[600px] text-center md:text-left gap-y-5 md:gap-y-10 ">
                <h1 className="text-5xl z-50 md:text-6xl text-white font-bold capitalize">
                  {props.heading}
                  {props.imageURI}
                </h1>
                {props.title && (
                  <div>
                    <p className="text-lg md:text-2xl font-medium text-white capitalize">
                      {props.title}
                    </p>
                  </div>
                )}
                {props.buttonText && props.href && (
                  <div className="">
                    <a
                      href={props.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" bg-button px-6 p-2 text-sm md:text-base cursor-pointer hover:shadow-md hover:brightness-105 uppercase text-white font-medium rounded-full "
                    >
                      {props.buttonText}
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-once={true}
              className="flex h-96 md:h-banner  justify-end items-end"
            >
              <img
                src={
                  window.location.origin +
                  `/uploads/svg/banner/${props.svg}`
                }
                className="min-w-0 md:min-w-[500px]"
                alt={props.svg}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ads;
