import React, { useEffect, useState } from "react";
import ProductBanner from "../../../components/product-banner";
import VehicleTransfer from "../../../components/vehicle-transfer";
import Notice from "../../../components/caps-notice";
import Downloads from "../../../components/downloads";
import ProductFeatures from "../../../components/product-features";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Axios from "axios";

function Index() {
  const [pageData, setPageData] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [locationDocumentsData, setLocationDocumentsData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [ratesData, setRatesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageRatesData, setPageRatesData] = useState([]);
  const [image, setImage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getPageData() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/products/page-load/12`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((response) => {
      setPageData(response.data.pageData);
      setStepsData(response.data.stepsData);
      setLocations(response.data.locations);
      setFeaturesData(response.data.featuresData);
      setRatesData(response.data.rates);
      setPageRatesData(response.data.pageRatesData);
      setLocationDocumentsData(response.data.locationPageDocuments);
      setDocumentsData(response.data.documents);
      setLoading(false);
    });
  }

  useEffect(() => {
    getPageData();
    Axios.get(`${process.env.REACT_APP_DEV_API}/vehicle-transfer-service`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      setImage(res.data);
    });
  }, []);

  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });
  return (
    <div>
      <ProductBanner
        title={pageData.bannerTitle}
        heading={pageData.bannerHeading}
        buttonText={pageData.bannerButtonText}
        href={pageData.bannerButtonHref}
        svg={pageData.bannerImageURI}
      />
      {/* Notice */}
      {pageData.importantNotice && <Notice data={pageData.importantNotice} />}
      {/* Description and features */}
      <ProductFeatures data={pageData.pageDescription} />
      <VehicleTransfer
        data={pageData.bannerHeading}
        documents={documentsData}
        rates={ratesData}
        image={image}
      />
    </div>
  );
}

export default Index;
