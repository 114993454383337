import { EyeIcon, KeyIcon } from "@heroicons/react/outline";
import Axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";

export default function VisionMission(props) {
  const [visionSVG, setVisionSVG] = useState();
  const [misionSVG, setMissionSVG] = useState();
  const [loading, setLoading] = useState(false);

  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  async function getSVG() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/get-svgs`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setVisionSVG(res.data[5].imageURI);
        setMissionSVG(res.data[0].imageURI);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  useEffect(() => {
    if (visionSVG == null && misionSVG == null) {
      getSVG();
    }
  }, []);

  return (
    <section className="relative">
      <div className="relative max-w-7xl mx-auto px-10 lg:px-4 sm:px-6 py-10 lg:py-20 ">
        <div className="max-w-7xl grid grid-cols-1 lg:gap-y-20 gap-y-10">
          {/* Vision */}
          <div className="grid grid-cols-1 lg:grid-cols-6 sm:gap-y-10 gap-10 gap-x-10">
            <div
              data-aos="fade-in"
              data-aos-once="true"
              className={`bg-gradient-to-l hidden lg:flex   col-span-1 lg:col-span-2 rounded-xl p-10   justify-center items-center`}
            >
              {visionSVG && (
                <img
                  src={
                    window.location.origin +
                    `/uploads/svg/others/${visionSVG}`
                  }
                  alt={visionSVG}
                />
              )}
            </div>
            <div className="flex flex-col col-span-1 lg:col-span-4 items-center justify-center p-5 ">
              <div className="grid grid-cols-1 gap-y-10">
                <div>
                  <div
                    data-aos="fade-left"
                    data-aos-once="true"
                    className="grid grid-cols-1 gap-y-4 lg:gap-y-8 justify-center items-center"
                  >
                    <div className="col-span-11 flex lg:items-left justify-center  lg:justify-start ">
                      <p className=" lg:text-left text-center text-black text-3xl lg:text-4xl font-bold">
                        Vision
                      </p>
                    </div>
                    <div>
                      <p className="lg:text-xl text-base text-center lg:text-left max-w-lg leading-relaxed">
                        {props.vision}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Mission */}
          <div
            data-aos="fade-left"
            data-aos-once="true"
            className="grid grid-cols-1 lg:grid-cols-6 sm:gap-y-10 gap-10 gap-x-10"
          >
            <div
              data-aos="fade-in"
              data-aos-once="true"
              className={`bg-gradient-to-l hidden lg:flex   col-span-1 lg:col-span-2 rounded-xl p-10   justify-center items-center`}
            >
              {misionSVG && (
                <img
                  src={
                    window.location.origin +
                    `/uploads/svg/others/${misionSVG}`
                  }
                  alt={misionSVG}
                />
              )}
            </div>
            <div className="flex flex-col col-span-1 lg:col-span-4 items-center justify-center p-5 ">
              <div className="grid grid-cols-1 gap-y-10">
                <div>
                  <div className="grid grid-cols-1 gap-y-4 lg:gap-y-8 justify-center items-center">
                    <div className="col-span-11 flex lg:items-left justify-center  lg:justify-start ">
                      <p className=" lg:text-left text-center text-black text-3xl lg:text-4xl font-bold">
                        Mission
                      </p>
                    </div>
                    <div>
                      <p className="lg:text-xl text-base text-center lg:text-left  max-w-lg leading-relaxed">
                        {props.mission}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
