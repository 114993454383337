import React, { useState, useRef, useEffect } from "react";
import {
  DownloadIcon,
  CalendarIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { DocumentIcon } from "@heroicons/react/solid";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import moment from "moment";

function Index(props) {
  const dateComponent = (date) => {
    const newDate = moment(`${date}`, "YYYY-MM-DD HH:mm:ss Z").format(
      "Do MMMM YYYY"
    );
    return <p className="lg:text-sm text-xs">{newDate.toString()}</p>;
  };

  return (
    <section className="relative">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 ">
        <div className="pt-12 lg:pt-20 lg:pb-20">
          {/* Strategies  */}
          {/* Section header */}
          <div
            data-aos-once="true"
            data-aos="fade-up"
            className="max-w-3xl mx-auto text-center pb-6 lg:pb-10"
          >
            <h1 className="h2 mb-4 text-3xl lg:text-3xl font-medium">
              Downloads
            </h1>
          </div>

          {/* Content */}
          <div
            className="max-w-xl lg:max-w-none lg:w-full mx-auto  lg:mt-6 mb-10 "
            data-aos-once="true"
            data-aos="fade-right"
          >
            {/* Donwloads */}
            <div>
              <div className="grid gap-y-3 lg:gap-y-5 lg:grid-cols-1 sm:grid-cols-1 grid-cols-1 lg:mt-6 mb-2 lg:px-0 px-5">
                {props.data.map((category) => (
                  <Disclosure key={category.category.id}>
                    {({ open }) => (
                      <>
                        <div className="grid grid-cols-1 ">
                          <Disclosure.Button
                            className={`${
                              open
                                ? "text-white   flex-row items-center rounded-b-none bg-gradient-to-r from-secondary to-primary "
                                : "bg-white text-black"
                            }  flex justify-between w-full   px-4 py-2 text-base lg:text-xl font-medium text-left drop-shadow-lg  rounded-lg `}
                          >
                            <div className="flex text-left items-center justify-start">
                              <span className="text-start">
                                {category.category.heading}
                              </span>
                            </div>

                            <ChevronDownIcon
                              className={`${
                                open ? "transform rotate-180 text-white" : ""
                              } w-6 h-6 text-primary`}
                            />
                          </Disclosure.Button>
                          <Transition
                            className="shadow-lg rounded-b-xl"
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel className="px-4 rounded-b-3xl  bg-white pb-2 text-sm text-gray-500">
                              <div className="grid grid-cols-1 gap-y-3 my-4 p-2 ">
                                {category.documents.map((document) => (
                                  <div className="grid grid-cols-1 gap-y-2 border-b border-borderGray rounded-none  lg:rounded-lg p-3">
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={document.href}
                                      className="grid p-2 hover:bg-lightgray rounded-lg grid-cols-1 gap-y-3 lg:grid-cols-12 justify-around items-center "
                                    >
                                      <h1 className="col-span-9 text-sm lg:text-base font-medium">
                                        {document.heading}
                                      </h1>
                                      <div className="col-span-1 lg:col-span-3 grid grid-cols-2 gap-y-3 gap-x-5">
                                        {document.enDocURI &&
                                          document.dhiDocURI && (
                                            <>
                                              <a
                                                className=" cursor-pointer order-2 hover:brightness-105 rounded-full hover:shadow-lg font-medium px-2 p-1.5  text-sm lg:text-base text-white lg:px-4 col-span-1 flex items-center justify-around bg-primary"
                                                href={
                                                  window.location.origin +
                                                  `/uploads/documents/${document.enDocURI}`
                                                }
                                                rel="noopener noreferrer"
                                                target="_blank"
                                              >
                                                <p>English</p>
                                                <DownloadIcon className="lg:h-6 lg:w-6 h-4 w-4 text-white" />
                                              </a>

                                              <a
                                                className=" cursor-pointer order-2 hover:brightness-105 rounded-full hover:shadow-lg font-medium px-2 p-1.5  text-sm lg:text-base text-white lg:px-4 col-span-1 flex items-center justify-around bg-primary"
                                                href={
                                                  window.location.origin +
                                                  `/uploads/documents/${document.dhiDocURI}`
                                                }
                                                rel="noopener noreferrer"
                                                target="_blank"
                                              >
                                                <p>Dhivehi</p>
                                                <DownloadIcon className="lg:h-6 lg:w-6 h-4 w-4 text-white" />
                                              </a>
                                            </>
                                          )}

                                        {document.dhiDocURI &&
                                          !document.enDocURI && (
                                            <a
                                              href={
                                                window.location.origin +
                                                `/uploads/documents/${document.dhiDocURI}`
                                              }
                                              rel="noopener noreferrer"
                                              target="_blank"
                                              className=" cursor-pointer order-2 hover:brightness-105 rounded-full hover:shadow-lg font-medium px-2 p-1.5  text-sm lg:text-base text-white lg:px-4 col-span-1 flex items-center justify-around bg-primary"
                                            >
                                              <p>Dhivehi</p>
                                              <DownloadIcon className="lg:h-6 lg:w-6 h-4 w-4 text-white" />
                                            </a>
                                          )}

                                        {!document.dhiDocURI &&
                                          document.enDocURI && (
                                            <a
                                              href={
                                                window.location.origin +
                                                `/uploads/documents/${document.enDocURI}`
                                              }
                                              rel="noopener noreferrer"
                                              target="_blank"
                                              className=" cursor-pointer order-2 hover:brightness-105 rounded-full hover:shadow-lg font-medium px-2 p-1.5  text-sm lg:text-base text-white lg:px-4 col-span-1 flex items-center justify-around bg-primary"
                                            >
                                              <p>English</p>
                                              <DownloadIcon className="lg:h-6 lg:w-6 h-4 w-4 text-white" />
                                            </a>
                                          )}
                                      </div>
                                    </a>
                                    <div className="flex flex-row items-center ml-2 lg:my-3 lg:ml-5">
                                      <CalendarIcon className="w-4 h-4 text-darkGray text-xs lg:text-base" />
                                      <p className="ml-2 text-gray">
                                        {dateComponent(document.updatedAt)}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
