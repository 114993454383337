import React, { useState } from "react";
import parse from "html-react-parser";

const transition = { duration: 4, yoyo: Infinity, ease: "easeInOut" };

function HeroHome(props) {
  return (
    <section className="bg-lightgray ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-10 lg:py-20 px-10">
        {/* Hero content */}
        <div className="flex flex-col items-center justify-center text-center">
          {/* Section header */}
          <div className="text-center grid grid-cols-1 gap-y-7 ">
            <div className="max-w-6xl mx-auto ">
              <p
                className="lg:text-xl text-base text-black"
                data-aos="fade-in"
                data-aos-once="true"
              >
                {" "}
                {parse(`${props.data}`)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
