import React, { useEffect, useState } from "react";
import ProductBanner from "../../components/product-banner";
import "aos/dist/aos.css"; // You can also use <link> for styles
import ProductFeatures from "../../components/product-features";
import KeyFeatures from "../../components/product-keys";
import Notice from "../../components/caps-notice";
import Steps from "../../components/steps-svg";
import Downloads from "../../components/downloads";
import Axios from "axios";
import IsNext from "../../components/is-next";

function Index() {
  const [pageData, setPageData] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [locationFeatures, setLocationFeatures] = useState([]);
  const [ratesData, setRatesData] = useState([]);
  const [isNext, setIsNext] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getPageData() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/products/page-load/18`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((response) => {
      setPageData(response.data.pageData);
      setStepsData(response.data.steps);
      setLocations(response.data.locations);
      setFeaturesData(response.data.features);
      setLocationFeatures(response.data.locationFeatures);
      setRatesData(response.data.ratesData);
      console.log(response.data);
      setIsNext(response.data.nextSteps);
      setLoading(false);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });

  return (
    <div>
      {/* Banner */}
      {!loading && (
        <div>
          <ProductBanner
            title={pageData.bannerTitle}
            heading={pageData.bannerHeading}
            buttonText={pageData.bannerButtonText}
            href={pageData.bannerButtonHref}
            svg={pageData.bannerImageURI}
          />
          {/* Notice */}
          {pageData.importantNotice && (
            <Notice data={pageData.importantNotice} />
          )}
          {/* Description and features */}
          <ProductFeatures data={pageData.pageDescription} />
          {/* Key Features */}
          <KeyFeatures
            heading="Categories"
            locationData={locations}
            locationfeature={locationFeatures}
            features={featuresData}
          />
          {/* Steps */}
          <Steps steps={stepsData} />

          <IsNext heading="More Information" steps={isNext} />
        </div>
      )}
      {loading && (
        <div>
          <ProductBanner />
          {/* Description and features */}
          <ProductFeatures />
          {/* Steps */}
          <Steps />
        </div>
      )}
    </div>
  );
}

export default Index;
