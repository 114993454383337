import React from 'react'
import Team from '../../../../components/board-members';


function Index() {
    return (
        <div>
            <Team/>
        </div>
    )
}

export default Index
