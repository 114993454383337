import React, { useState, useEffect } from "react";
import AdBanner from "../../components/slider";
import { ChartBarIcon } from "@heroicons/react/outline";
import Axios from "axios";
import AnimatedNumber from "animated-number-react";
import Notice from "../../components/caps-notice";

function Home() {
  const [data, setData] = useState({});
  const formatValue = (value) => `${Number(value).toFixed(0)}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_DEV_API}/total-waste`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  }, []);

  return (
    <div>
      <AdBanner />

      {/* Floating Status Amount Component */}
      <div
        className="lg:absolute  p-6 right-3/3  lg:right-40 bottom-20  z-10 rounded-3xl bg-white lg:drop-shadow-xl "
        aria-hidden="true"
      >
        <div className="grid-cols-12 grid gap-x-4 ">
          <div className="bg-gradient-to-t from-primary to-secondary rounded-xl col-span-4 flex items-center justify-center p-4">
            <ChartBarIcon className="h-10 w-10 text-white" />
          </div>
          <div className="col-span-8 flex items-center justify-center flex-col">
            <h1 className="">Total tonnage of waste</h1>
            <div className="flex flex-row">
              <div className="text-4xl">
                <AnimatedNumber
                  value={parseInt(data.total)}
                  duration={800}
                  formatValue={formatValue}
                />
              </div>

              <p className="text-primary text-lg ml-2">+{data.recent} T</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
