import React, { useCallback, useState, useEffect, useRef } from "react";

/**
 * Simple wrapper for dynamic SVG import hook. You can implement your own wrapper,
 * or even use the hook directly in your components.
 */

function Index(props) {
  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);
  return (
    <section
      // style={{
      //   backgroundImage: `url(http://10.0.11.210:3001/uploads/images/others/ss.jpg)`,
      // }}
      className="relative   bg-gradient-to-l py-20 md:py-0
         h-full "
    >
      <div className="max-w-7xl bg-cover mx-auto  px-4 sm:px-6  py-20 flex items-center justify-center text-center flex-col">
        <div className="md:pb-10 pb-10  text-3xl md:text-4xl text-black font-semibold">
          <h1 data-aos="fade-up" data-aos-once={true}>
            Here are the steps
          </h1>
        </div>
        {props.steps && (
          <div className="flex justify-between flex-col overflow-hidden">
            {props.steps.map((step) => (
              <div key={step.index} className="max-w-6xl ">
                {step.index % 2 == 0 && (
                  <div
                    data-aos="fade-right"
                    data-aos-once={true}
                    className="grid grid-cols-1 w-full  md:grid-cols-6 fit-content gap-3 py-8"
                  >
                    {/* SVG */}
                    <div className="col-span-1 px-10 md:px-0 md:col-span-3 flex items-center justify-center">
                      {step.imageURI && (
                        <img
                          src={
                            window.location.origin +
                            `/uploads/svg/steps/${step.imageURI}`
                          }
                          alt={step.imageURI}
                          className="h-[35vh] md:h-[37vh] lg:h-[40vh]"
                        />
                      )}
                    </div>
                    <div className="col-span-1 md:col-span-3 p-4 h-full  px-10 md:px-6">
                      <div className="grid grid-cols-1 gap-6 text-left">
                        <div className="flex flex-row items-center">
                          <h1 className="text-7xl font-bold text-primary">
                            {step.index}
                          </h1>
                          <h1 className="ml-6 capitalize text-3xl font-bold text-gitHub">
                            {step.heading}
                          </h1>
                        </div>

                        <p className="text-lg ">{step.title}</p>
                        {step.buttonText && (
                          <div className="flex justify-center items-start uppercase bg-primary w-1/3 my-3 rounded-full hover:shadow-lg hover:brightness-110 cursor-pointer">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={step.buttonHref}
                              className="p-2 px-4 text-xs md:text-sm text-white font-medium"
                            >
                              {step.buttonText}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {step.index % 2 != 0 && (
                  <div
                    data-aos-once="true"
                    data-aos="fade-left"
                    className="grid grid-cols-1 md:grid-cols-6 gap-3 py-8"
                  >
                    {/* SVG */}

                    <div className="col-span-1 md:col-span-3 p-4 h-full md:order-first order-last px-10 md:px-6">
                      <div className="grid grid-cols-1 gap-6 text-left ">
                        <div className="flex flex-row items-center ">
                          <h1 className="text-7xl font-bold text-primary">
                            {step.index}
                          </h1>
                          <h1 className="ml-6 capitalize text-3xl font-bold text-gitHub">
                            {step.heading}
                          </h1>
                        </div>

                        <p className="text-lg  md:leading-loose">
                          {step.title}
                        </p>
                        {step.buttonText && (
                          <div className="flex justify-center items-start uppercase bg-primary w-1/3 my-3 rounded-full hover:shadow-lg hover:brightness-110 cursor-pointer">
                            <a
                              href={step.buttonHref}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="p-2 px-4 text-xs md:text-sm text-white font-medium"
                            >
                              {step.buttonText}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-1 md:col-span-3 px-10 md:px-0 flex items-center justify-center">
                      {step.imageURI && (
                        <img
                          src={
                            window.location.origin +
                            `/uploads/svg/steps/${step.imageURI}`
                          }
                          alt={step.imageURI}
                          className="h-[35vh] md:h-[37vh] lg:h-[40vh]"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Index;
