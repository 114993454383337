import React from 'react'
import Timeline from '../../../../components/timeline'
function index() {
    return (
        <div className='z-50'>
            <Timeline/>
        </div>
    )
}

export default index
