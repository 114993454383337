import React, { useEffect, useState } from "react";
import ProductBanner from "../../../../components/product-banner";
import ProductFeatures from "../../../../components/product-features";
import SimplySteps from "../../../../components/caps-simply";
import Notice from "../../../../components/caps-notice";
import Rates from "../../../../components/caps-rates";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Axios from "axios";

function Index() {
  const [pageData, setPageData] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [ratesData, setRatesData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getPageData() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/products/page-load/4`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((response) => {
      setPageData(response.data.pageData);
      setStepsData(response.data.steps);
      setLocations(response.data.locations);
      setFeaturesData(response.data.features);
      setRatesData(response.data.rates);
      console.log(response.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });

  return (
    <div>
      <ProductBanner
        title={pageData.bannerTitle}
        heading={pageData.bannerHeading}
        buttonText={pageData.bannerButtonText}
        href={pageData.bannerButtonHref}
        svg={pageData.bannerImageURI}
      />
      {/* Notice */}
      {pageData.importantNotice && <Notice data={pageData.importantNotice} />}
      {/* Description and features */}
      <ProductFeatures data={pageData.pageDescription} />
      {/* Simply Features */}
      <SimplySteps data={stepsData} />
      {/* Rates */}
      <Rates data={ratesData} />
    </div>
  );
}

export default Index;
