import React, { useEffect, useState } from "react";
import ProductBanner from "../../../components/product-banner";
import ProductFeatures from "../../../components/product-features";
import Dispose from "../../../components/waste-dispose";
import Notice from "../../../components/caps-notice";
import ExpiredDocuments from "../../../components/expire-documents";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Axios from "axios";

function Index() {
  const [pageData, setPageData] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [locationDocumentsData, setLocationDocumentsData] = useState([]);
  const [ratesData, setRatesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageRatesData, setPageRatesData] = useState([]);

  async function getPageData() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/products/page-load/10`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((response) => {
      setPageData(response.data.pageData);
      setStepsData(response.data.steps);
      setLocations(response.data.locations);
      setFeaturesData(response.data.features);
      setRatesData(response.data.locationRates);
      setPageRatesData(response.data.rates);
      setLocationDocumentsData(response.data.locationDocuments);
      setLoading(false);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });

  return (
    <div>
      {loading && (
        <>
          <ProductBanner
            title={pageData.bannerTitle}
            buttonText={pageData.bannerButtonText}
            heading={pageData.bannerHeading}
            href={pageData.bannerButtonHref}
            svg={pageData.bannerImageURI}
          />
          {/* Description and features */}
          <ProductFeatures data={pageData.pageDescription} />
          {/* Dispose ways */}
          <Dispose data={stepsData} />
          {/* Documents */}
        </>
      )}
      {!loading && (
        <>
          <ProductBanner
            title={pageData.bannerTitle}
            heading={pageData.bannerHeading}
            buttonText={pageData.bannerButtonText}
            href={pageData.bannerButtonHref}
            svg={pageData.bannerImageURI}
          />
          {/* Notice */}
          {pageData.importantNotice && (
            <Notice data={pageData.importantNotice} />
          )}
          {/* Description and features */}
          <ProductFeatures data={pageData.pageDescription} />
          {/* Dispose ways */}
          <Dispose data={stepsData} />
          {/* Documents */}
          <ExpiredDocuments
            locations={locations}
            documents={locationDocumentsData}
          />
        </>
      )}
    </div>
  );
}

export default Index;
