import React from "react";
import parse from "html-react-parser";
function index(props) {
  return (
    <section className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-10 lg:py-20  flex items-center justify-center ">
        <div
          data-aos="fade-in"
          data-aos-once="true"
          className="md:grid flex lg:grid-cols-2 gap-x-5 gap-y-0 lg:gap-y-20"
        >
          <div
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/uploads/images/others/${props.image})`,
            }}
            className="bg-cover bg-center min-h-[50vh] hidden md:flex invisible lg:visible max-w-2xl rounded-lg"
          />
          <div className="gap-5 flex items-start justify-center flex-col  bg-white min-h-imagel p-10 rounded-full ">
            <h1 className=" text-2xl lg:text-4xl pb-4 lg:pb-14 font-bold text-black">
              {props.heading}
            </h1>
            <div className="flex flex-row">
              <p className="lg:text-xl text-base">{parse(props.description)}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default index;
