import React, { useState, useEffect } from "react";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Menu from "../../../../components/media-center-menu";
import View from "../../../../components/media-center-home";
import Axios from "axios";
import PDFView from "../../../../components/pdf-viewer";
import MiniBanner from "../../../../components/mini-border";

function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const id = 4;

  const [menu, setMenu] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getMenu() {
    Axios.get(
      `${process.env.REACT_APP_DEV_API}/about-us/media-center/page-load/${id}`,
      {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
          "api-secret": `${process.env.REACT_APP_API_SECRET}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setMenu(res.data.menu);
        setPosts(res.data.posts);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  useEffect(async () => {
    getMenu();
    const AOS = (await import("aos")).default;
    AOS.init();
  }, []);
  return (
    <div className="relative min-h-screen ">
      <MiniBanner />
      <div className=" max-w-7xl mx-auto px-4 sm:px-6 ">
        <div className="pt-10 lg:pt-10 grid grid-cols-1 lg:grid-cols-12">
          <div className="col-span-3">
            <Menu active="Press Releases" data={menu} />
          </div>

          <div className="col-span-9">
            <PDFView />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
