import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";

function Index(props) {
  const [calculatedRate, setCalculatedRate] = useState(0.0);

  const calculateRate = (i) => {
    if (i === "Select a Vehicle") {
      setCalculatedRate("00.00");
    } else {
      const indexOfObject = props.rates.findIndex((obj) => {
        if (obj.index == i) {
          return true;
        } else {
          return false;
        }
      });
      const finalRate = props.rates[indexOfObject].price;
      setCalculatedRate(finalRate);
    }
  };

  function changeToCurrency(num) {
    const price = parseInt(num);
    return "MVR " + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return (
    <section className="relative">
      <div className="max-w-7xl mx-auto lg:px-4 sm:px-6 py-10 px-10 lg:py-20 ">
        <div className="lg:grid grid-cols-1 gap-y-5 lg:grid-cols-2 lg:gap-6">
          {/* Picture */}
          <div
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/uploads/images/others/${props.image})`,
            }}
            className="bg-cover bg-center rounded-2xl"
            data-aos-once="true"
            data-aos="fade-up"
          />
          <div
            data-aos-once="true"
            data-aos="fade-up"
            className=" grid grid-cols-1 h-image-l gap-y-5 lg:gap-y-2 text-left shadow-none rounded-lg border-none lg:border border-lightgray lg:shadow-lg py-10 lg:p-10 "
          >
            <div className="text-black flex  items-center justify-start text-lg rounded-xl">
              <div className="text-black flex  items-center justify-start text-lg rounded-xl">
                <div>
                  <div className="lg:text-4xl text-xl font-medium leading-snug tracking-tight">
                    {props.data}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:text-xl text-base leading-6">
              <p className="text-black my-3 ">
                Please select the category of your vehicle to see the price for
                vehicle transfer service.
              </p>
            </div>
            <div>
              <div className="grid grid-cols-1 my-6  gap-y-2 gap-2 rounded-xl">
                <div className="lg:mx-5 mx-0 grid gap-x-3 grid-cols-2">
                  <p className="text-sm text-darkGray">Unit</p>
                  <p className="text-sm text-darkGray">Rate</p>
                </div>
                <div className="border-b-2  border-lightgray" />
                <div className="lg:mx-5 mx-0 grid grid-cols-2 gap-x-3 text-sm lg:text-lg font-medium text-black">
                  <div class="flex justify-center col-span-1">
                    <select
                      onChange={(e) => calculateRate(e.target.value)}
                      class="form-select appearance-none
                                                cursor-pointer
                                                block
                                                rounded-xl
                                                w-full
                                                px-3
                                                py-1.5
                                                text-sm
                                                lg:text-base
                                                font-normal
                                                text-gray
                                                bg-white bg-clip-padding bg-no-repeat
                                                transition
                                                ease-in-out
                                                m-0
                                                border-borderGray
                                                focus:text-black focus:ring-button focus:border-parrot focus:bg-white focus:outline-none"
                      aria-label="Default select example"
                    >
                      <option className="" selected>
                        Select a Vehicle
                      </option>
                      {props.rates.map((rate) => (
                        <>
                          <option
                            key={rate.index}
                            className=" rounded-lg"
                            value={rate.index}
                          >
                            {rate.category}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-row border border-borderGray px-5 rounded-xl items-center justify-between ">
                    <p className="font-bold text-sm lg:text-base">
                      {changeToCurrency(calculatedRate)}
                    </p>
                  </div>
                </div>
                <div className="mt-4 text-xs lg:text-sm">
                  <p>*Rates for transfer within greater Male’ Area </p>
                </div>
              </div>
            </div>
            {props.documents.map((doc) => (
              <div className="flex flex-row items-center justify-between">
                <p className="text-xl">{doc.heading}</p>
                <a
                  href={
                    window.location.origin + `/uploads/documents/${doc.uri}`
                  }
                  className="p-2 uppercase px-4 rounded-full cursor-pointer bg-button hover:shadow-lg hover:brightness-105 text-white font-medium"
                >
                  Download
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
