import SubHeader from "../ts-subheaders";
import React, { useState, useEffect, useRef, useCallback } from "react";
import Axios from "axios";

function Index(props) {
  const [svg, setSvg] = useState("");
  const [loading, setLoading] = useState(true);

  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  async function getSVG() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/transfer-station-individual`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      setSvg(res.data);
      console.log(res.data);
    });
    setLoading(false);
  }

  useEffect(() => {
    getSVG();
  }, []);

  return (
    <section className="bg-white">
      <div className="">
        <SubHeader
          image={props.image}
          heading="For Waste Drop Off"
          description={props.data}
        />

        <div className="relative bg-gradient-to-l from-primary to-secondary">
          <div className="max-w-7xl mx-auto px-6 lg:px-4  py-10 lg:py-20 h-full flex items-center justify-center ">
            <div className="lg:mt-6 mt-0 w-full grid gap-x-20 grid-cols-1 lg:grid-cols-2">
              <div className="p-4">
                <div className="grid grid-cols-1 gap-y-10 pb-5">
                  <div className="grid grid-cols-1 gap-y-3 text-white ">
                    <h1
                      data-aos="fade-in"
                      data-aos-once="true"
                      className="font-bold text-xl lg:text-3xl"
                    >
                      Prices for Waste Drop Off
                    </h1>
                  </div>

                  <div className="grid grid-cols-1 gap-y-6">
                    <div>
                      <div
                        data-aos="fade-up"
                        data-aos-once="true"
                        className="grid grid-cols-1 gap-2 gap-x-5"
                      >
                        {props.rates && (
                          <>
                            {props.rates.map((rate) => (
                              <div className=" text-black grid pt-2 grid-cols-10 gap-1 gap-x-2 lg:gap-x-5 text-sm">
                                <p className="lg:text-lg col-span-6 text-sm p-2 px-4 bg-white rounded-lg shadow-sm">
                                  {rate.category}
                                </p>
                                <div className="flex flex-row items-center p-2 col-span-4 bg-white rounded-lg shadow-sm ">
                                  <p className="lg:text-lg text-sm ">
                                    MVR{" "}
                                    <span className="font-medium">
                                      {rate.price}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-once={true}
                className="h-card justify-center hidden md:flex items-center"
              >
                {svg && (
                  <img
                    src={
                      window.location.origin +
                      `/uploads/svg/others/${svg}`
                    }
                    alt={svg}
                    className="h-96 w-96"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
