import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSpring, useSprings, animated as a } from "react-spring";
// Animations
import "aos/dist/aos.css"; // You can also use <link> for styles

function Index(props) {
  const [index, setIndex] = useState(null);
  const [isDelayed, setIsDelayed] = useState(true);
  const [cardStatus, displayCard] = useState(false);
  const [loading, setLoading] = useState(false);

  const tier1 = []; // Chairman
  const tier2 = []; // Managing Director
  const tier3 = []; // Deputy Managing Director
  const tier4 = []; // Non-executive Directors
  const rest = [];

  async function segregateMembers(members) {
    await members.map((member) => {
      if (member.href === "Chairman") {
        tier1.push(member);
      } else if (member.href === "Managing Director") {
        tier2.push(member);
      } else if (member.href === "Deputy Managing Director") {
        tier3.push(member);
      } else if (member.href === "Non-executive Directors") {
        tier4.push(member);
      } else {
        rest.push(member);
      }
    });
  }

  useEffect(() => {
    segregateMembers(props.data);
    if (tier1.length === 0 || rest.length === 0) {
      setLoading(true);
    }
    console.log(props.data);
    console.log(tier1);
  }, []);

  const springs = useSprings(
    props.data.length,
    props.data.map((item, i) => ({
      delay: isDelayed ? 500 * i : 0,
      opacity: 1,
      transform: "scaleY(120px)",
      overlayOpacity: i === index ? 0 : 1,
      textOpacity: i === index ? 1 : 0,
      curve: i === index ? "30px" : "500px",
      textHeight: i === index ? "100%" : "0%",
      translate: i === index ? "100%" : "0%",
      from: {
        opacity: 0,
        transform: "translateY(100px)",
        overlayOpacity: 1,
        textOpacity: 0,
        textHeight: "0%",
      },
    }))
  );

  return (
    <div className="">
      <div className="max-w-7xl mx-auto  lg:px-4 text-center px-10 py-10 lg:py-10">
        <div className="space-y-6">
          <div
            data-aos-once="true"
            data-aos="fade-up"
            className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl"
          >
            <h1 className="text-3xl font-bold tracking-tight lg:text-4xl mt-20">
              Board of Directors
            </h1>
          </div>
          {/* Chairman */}
          <div cla>
            <ul role="list" className="lg:grid-cols-1 grid ">
              {springs.map(
                (
                  {
                    opacity,
                    transform,
                    overlayOpacity,
                    textOpacity,
                    curve,
                    translate,
                  },
                  i
                ) => (
                  <a.div
                    className=""
                    onMouseEnter={() => {
                      setIndex(i);
                      setIsDelayed(false);
                    }}
                    onMouseLeave={() => {
                      setIndex(null);
                    }}
                    key={i}
                  >
                    <div className="grid grid-cols-1  md:py-1">
                      {props.data[i].href === "Chairman" && (
                        <div className="flex justify-center items-center flex-col">
                          <div className="relative sm:m-10 lg:m-12 mb-5">
                            <a.img
                              // style={{ opacity: overlayOpacity }}
                              className="mx-auto grayscale   rounded-full w-full"
                              src={
                                window.location.origin +
                                `/uploads/images/team/${props.data[i].imageURI}`
                              }
                            />
                            <a.div
                              style={{
                                opacity: textOpacity,
                                borderRadius: curve,
                                transform: translate,
                              }}
                              className="absolute inset-0 p-4  bg-white content-evenly rounded-xl drop-shadow-lg  scroll-bar"
                            >
                              <a.h1 className="text-sm">
                                {props.data[i].description}
                              </a.h1>
                            </a.div>
                          </div>
                          <a.div>
                            <div className=" space-y-1">
                              <h3 className="text-gray text-lg leading-6 font-medium">
                                {props.data[i].title}
                              </h3>
                              <p className="text-sm text-primary uppercase">
                                {props.data[i].href}
                              </p>
                            </div>
                          </a.div>
                        </div>
                      )}
                    </div>
                  </a.div>
                )
              )}
            </ul>
          </div>
          <div cla>
            <ul role="list" className="  lg:grid-cols-1 grid ">
              {springs.map(
                (
                  {
                    opacity,
                    transform,
                    overlayOpacity,
                    textOpacity,
                    curve,
                    translate,
                  },
                  i
                ) => (
                  <a.div
                    className=""
                    onMouseEnter={() => {
                      setIndex(i);
                      setIsDelayed(false);
                    }}
                    onMouseLeave={() => {
                      setIndex(null);
                    }}
                    key={i}
                  >
                    <div className="grid grid-cols-1  md:py-1">
                      {props.data[i].href === "Managing Director" && (
                        <div className="flex justify-center items-center flex-col">
                          <div className="relative sm:m-10 lg:m-12 mb-5">
                            <a.img
                              // style={{ opacity: overlayOpacity }}
                              className="mx-auto grayscale   rounded-full w-full"
                              src={
                                window.location.origin +
                                `/uploads/images/team/${props.data[i].imageURI}`
                              }
                            />
                            <a.div
                              style={{
                                opacity: textOpacity,
                                borderRadius: curve,
                                transform: translate,
                              }}
                              className="absolute inset-0 p-4  bg-white content-evenly rounded-xl drop-shadow-lg  scroll-bar"
                            >
                              <a.h1 className="text-sm">
                                {props.data[i].description}
                              </a.h1>
                            </a.div>
                          </div>
                          <a.div>
                            <div className=" space-y-1">
                              <h3 className="text-gray text-lg leading-6 font-medium">
                                {props.data[i].title}
                              </h3>
                              <p className="text-sm text-primary uppercase">
                                {props.data[i].href}
                              </p>
                            </div>
                          </a.div>
                        </div>
                      )}
                    </div>
                  </a.div>
                )
              )}
            </ul>
          </div>
          <div cla>
            <ul role="list" className="  lg:grid-cols-1 grid ">
              {springs.map(
                (
                  {
                    opacity,
                    transform,
                    overlayOpacity,
                    textOpacity,
                    curve,
                    translate,
                  },
                  i
                ) => (
                  <a.div
                    className=""
                    onMouseEnter={() => {
                      setIndex(i);
                      setIsDelayed(false);
                    }}
                    onMouseLeave={() => {
                      setIndex(null);
                    }}
                    key={i}
                  >
                    <div className="grid grid-cols-1  md:py-1">
                      {props.data[i].href === "Deputy Managing Director" && (
                        <div className="flex justify-center items-center flex-col">
                          <div className="relative sm:m-10 lg:m-12 mb-5">
                            <a.img
                              // style={{ opacity: overlayOpacity }}
                              className="mx-auto grayscale   rounded-full w-full"
                              src={
                                window.location.origin +
                                `/uploads/images/team/${props.data[i].imageURI}`
                              }
                            />
                            <a.div
                              style={{
                                opacity: textOpacity,
                                borderRadius: curve,
                                transform: translate,
                              }}
                              className="absolute inset-0 p-4  bg-white content-evenly rounded-xl drop-shadow-lg  scroll-bar"
                            >
                              <a.h1 className="text-sm">
                                {props.data[i].description}
                              </a.h1>
                            </a.div>
                          </div>
                          <a.div>
                            <div className=" space-y-1">
                              <h3 className="text-gray text-lg leading-6 font-medium">
                                {props.data[i].title}
                              </h3>
                              <p className="text-sm text-primary uppercase">
                                {props.data[i].href}
                              </p>
                            </div>
                          </a.div>
                        </div>
                      )}
                    </div>
                  </a.div>
                )
              )}
            </ul>
          </div>
          {/* Board */}
          <div cla>
            <ul role="list" className="  lg:grid-cols-2 grid ">
              {springs.map(
                (
                  {
                    opacity,
                    transform,
                    overlayOpacity,
                    textOpacity,
                    curve,
                    translate,
                  },
                  i
                ) => (
                  <a.div
                    className=""
                    onMouseEnter={() => {
                      setIndex(i);
                      setIsDelayed(false);
                    }}
                    onMouseLeave={() => {
                      setIndex(null);
                    }}
                    key={i}
                  >
                    <div className="grid grid-cols-1  py-5">
                      {props.data[i].href === "Non-executive Director" && (
                        <div className="flex justify-center items-center flex-col">
                          <div className="relative sm:m-10 lg:m-12  mb-5">
                            <a.img
                              // style={{ opacity: overlayOpacity }}
                              className="mx-auto grayscale   rounded-full w-full"
                              src={
                                window.location.origin +
                                `/uploads/images/team/${props.data[i].imageURI}`
                              }
                            />
                            <a.div
                              style={{
                                opacity: textOpacity,
                                borderRadius: curve,
                                transform: translate,
                              }}
                              className="absolute inset-0 p-4  bg-white content-evenly rounded-xl drop-shadow-lg  scroll-bar"
                            >
                              <a.h1 className="text-sm">
                                {props.data[i].description}
                              </a.h1>
                            </a.div>
                          </div>
                          <a.div>
                            <div className=" space-y-1">
                              <h3 className="text-gray text-lg leading-6 font-medium">
                                {props.data[i].title}
                              </h3>
                              <p className="text-sm text-primary uppercase">
                                {props.data[i].href}
                              </p>
                            </div>
                          </a.div>
                        </div>
                      )}
                    </div>
                  </a.div>
                )
              )}
            </ul>
          </div>
          {/* Company Secretary */}
          <div cla>
            <ul role="list" className="  lg:grid-cols-1 grid ">
              {springs.map(
                (
                  {
                    opacity,
                    transform,
                    overlayOpacity,
                    textOpacity,
                    curve,
                    translate,
                  },
                  i
                ) => (
                  <a.div
                    className=""
                    onMouseEnter={() => {
                      setIndex(i);
                      setIsDelayed(false);
                    }}
                    onMouseLeave={() => {
                      setIndex(null);
                    }}
                    key={i}
                  >
                    <div className="grid grid-cols-1  md:py-1">
                      {props.data[i].href === "Company Secretary" && (
                        <div className="flex justify-center items-center flex-col">
                          <div className="relative sm:m-10 lg:m-12 mb-5">
                            <a.img
                              // style={{ opacity: overlayOpacity }}
                              className="mx-auto grayscale   rounded-full w-full"
                              src={
                                window.location.origin +
                                `/uploads/images/team/${props.data[i].imageURI}`
                              }
                            />
                            <a.div
                              style={{
                                opacity: textOpacity,
                                borderRadius: curve,
                                transform: translate,
                              }}
                              className="absolute inset-0 p-4  bg-white content-evenly rounded-xl drop-shadow-lg  scroll-bar"
                            >
                              <a.h1 className="text-sm">
                                {props.data[i].description}
                              </a.h1>
                            </a.div>
                          </div>
                          <a.div>
                            <div className=" space-y-1">
                              <h3 className="text-gray text-lg leading-6 font-medium">
                                {props.data[i].title}
                              </h3>
                              <p className="text-sm text-primary uppercase">
                                {props.data[i].href}
                              </p>
                            </div>
                          </a.div>
                        </div>
                      )}
                    </div>
                  </a.div>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
