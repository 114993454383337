/* This example requires Tailwind CSS v2.0+ */
import {
  PhoneIcon,
  MailIcon,
  PhoneIncomingIcon,
} from "@heroicons/react/outline";
import { ReactComponent as WAMCOLogo } from "../../assets/logo/wamco-white-logo.svg";

const footerData = [
  {
    // logo: "../../assets/images/logo/logo-final.png",
    address: `Waste management Corporation Limited (WAMCO)
  Ground Floor, Saafu Raajje Building,
  Boduthakurufaanu Magu, 20386, Male', Maldives
  `,
    email: "1666@wamco.com.mv",
    hotline: "1666",
    phone: "1666",
    social: [
      {
        name: "Facebook",
        href: "https://m.facebook.com/WAMCOmaldives/",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: "https://instagram.com/wamco_mv",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Twitter",
        href: "https://mobile.twitter.com/WAMCOmv",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
    ],
    categories: [
      {
        name: "Collection Service",
        subCategories: [
          {
            name: "Household",
            pages: [
              {
                name: "building Service",
                href: "building-service",
              },
              {
                name: "building Plus Service",
                href: "building-plus-service",
              },
            ],
          },
          {
            name: "On Demand",
            pages: [
              {
                name: "Call and Pick-up Service",
                href: "caps",
              },
              {
                name: "Caps Lite",
                href: "caps-lite",
              },
              {
                name: "CAPS - Construction and Demolition Waste (CND)",
                href: "caps-cdw",
              },
            ],
          },
          {
            name: "Commercial",
            pages: [
              {
                name: "Commercial waste collection services",
                href: "commercial",
              },
            ],
          },
        ],
        pages: [],
      },
      {
        name: "Disposal Service",
        subCategories: [],
        pages: [
          {
            name: "Waste Management Facilities",
            href: "waste-management-facilities",
          },
          {
            name: "Transfer Station Facilities",
            href: "transfer-station-facilities",
          },
          {
            name: "Special Projects",
            href: "special-projects",
          },
          {
            name: "Expired / Damaged",
            href: "expired-waste",
          },
        ],
      },
      {
        name: "Others Services",
        subCategories: [],
        pages: [
          {
            name: "Docking Service",
            href: "docking-service",
          },
          {
            name: "Vehicle Transfer Service",
            href: "vehicle-transfer-service",
          },
        ],
      },
      {
        name: "About Us",
        subCategories: [],
        pages: [
          {
            name: "Corporate Profile",
            href: "our-company/1",
          },
          {
            name: "Contact Us",
            href: "location",
          },
          {
            name: "Media",
            href: "/website/about-us/media/press-releases",
          },
          {
            name: "sustainability",
            href: "sustainability",
          },
          {
            name: "Downloads",
            href: "downloads",
          },
          {
            name: "Careers",
            href: "careers",
          },
        ],
      },
    ],
  },
];

export default function Index() {
  return (
    <footer
      className="bg-gitHub text-lightgray py-10 lg:py-0 "
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      {footerData.map((data) => (
        <div className="max-w-7xl mx-auto  px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <div className="grid grid-cols-6 gap-2">
                <div className="col-span-2 ">
                  <WAMCOLogo className="h-20" />
                </div>
              </div>
              <p className="text-gray-500 text-base">{data.address}</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="flex justify-center flex-col">
                  <div className="text-gray-500 text-base flex flex-row items-center">
                    <MailIcon className="w-5 h-5 text-secondary" />
                    <div className="w-2" />
                    <a
                      className="hover:text-primary"
                      href="mailto:test@example.com"
                    >
                      {data.email}
                    </a>
                  </div>
                  <div className="mt-2 text-gray-500 text-base flex flex-row items-center">
                    <PhoneIcon className="w-5 h-5 text-secondary" />
                    <div className="w-2" />
                    <a
                      className="cursor-pointer hover:text-primary"
                      href="tel:1666"
                    >
                      {data.phone}
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex space-x-6">
                {data.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-400 hover:text-primary"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-12 grid sm:grid-cols-4 grid-cols-2 gap-4 gap-y-12 xl:mt-0 xl:col-span-2">
              {data.categories.map((category) => (
                <div className="">
                  <div>
                    <h3 className="text-sm font-semibold uppercase">
                      {category.name}
                    </h3>
                    <div className="">
                      {category.subCategories.length > 0 && (
                        <div className="pt-3 grid grid-cols-1 gap-y-3 text-sm">
                          {category.subCategories.map((subCategory) => (
                            <div>
                              <a
                                href={subCategory.href}
                                className="text-xs text-secondary "
                              >
                                {subCategory.name}
                              </a>
                              <div className="pt-3 grid grid-cols-1 gap-y-3 text-sm">
                                {subCategory.pages.map((page) => (
                                  <a
                                    href={window.location.origin+'/'+page.href}
                                    className="text-base cursor-pointer text-gray capitalize hover:text-lightgray"
                                  >
                                    {page.name}
                                  </a>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      <div />
                      <div className="">
                        {category.pages.length > 0 && (
                          <div className="pt-3 grid grid-cols-1 gap-y-3">
                            {category.pages.map((page) => (
                              <div key={page.name}>
                                <a
                                  href={window.location.origin+'/'+page.href}
                                  className="text-base cursor-pointer capitalize text-gray hover:text-lightgray"
                                >
                                  {page.name}
                                </a>
                              </div>
                            ))}
                          </div>
                        )}
                        <div />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      <div className="max-w-7xl mx-auto py-0 px-4 sm:px-6 lg:py-5 lg:px-8">
        <div className="my-12 border-t border-gray pt-3">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; 2022 WAMCO. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
