import React, { useState, useRef, useEffect } from "react";
// Animations
import { Tab } from "@headlessui/react";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Transition from "../../../../components/utils/Transition";
import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import MiniBanner from "../../../../components/mini-border";
import Axios from "axios";

function Index() {
  const [tab, setTab] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const tabs = useRef(null);

  useEffect(async () => {
    const AOS = (await import("aos")).default;
    AOS.init();
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_DEV_API}/about-us/contact-us/page-load`,
      {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
          "api-secret": `${process.env.REACT_APP_API_SECRET}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setPageData(res.data);
        console.log(res.data);
      } else {
        setLoading(false);
      }
    });
  }, []);

  // const heightFix = () => {
  //   if (tabs.current.children[tab]) {
  //     tabs.current.style.height =
  //       tabs.current.children[tab - 1].offsetHeight + "px";
  //   }
  // };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <section className="relative">
      <MiniBanner />
      <div className=" max-w-7xl mx-auto px-4 sm:px-6  my-10 ">
        <div className="py-10 lg:py-10 grid grid-cols-1 lg:grid-cols-12">
          {/* Map */}
          <div
            className="col-span-3 h-0 lg:h-map mt-40 hidden lg:flex invisible lg:visible flex items-center justify-center"
            aria-hidden="true"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="141.638"
                height="840.258"
                viewBox="0 0 141.638 840.258"
              >
                <defs>
                  <filter
                    id="Path_5163-2"
                    x="45.521"
                    y="655.425"
                    width="75.253"
                    height="89.976"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5164-2"
                    x="89.865"
                    y="784.756"
                    width="17.16"
                    height="17.238"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-2" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5165-2"
                    x="55.018"
                    y="813.903"
                    width="29.931"
                    height="26.355"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-3" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-3" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5164-2-2"
                    x="89.865"
                    y="784.756"
                    width="17.16"
                    height="17.238"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-4" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-4" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5165-2-2"
                    x="55.019"
                    y="813.903"
                    width="29.931"
                    height="26.355"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-5" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-5" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5168-2"
                    x="27.999"
                    y="433.794"
                    width="34.254"
                    height="50.641"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-6" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-6" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5169-2"
                    x="27.056"
                    y="400.133"
                    width="37.308"
                    height="42.215"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-7" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-7" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5170-2"
                    x="34.465"
                    y="481.896"
                    width="63.773"
                    height="55.321"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-8" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-8" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5171-2"
                    x="71.013"
                    y="527.446"
                    width="49.878"
                    height="51.059"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-9" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-9" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5172-2"
                    x="85.473"
                    y="416.292"
                    width="43.66"
                    height="58.618"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-10" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-10" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5174-2"
                    x="14.686"
                    y="300.124"
                    width="44.833"
                    height="99.259"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-11" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-11" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5167-2"
                    x="86.386"
                    y="246.031"
                    width="27.318"
                    height="20.901"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-12" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-12" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5217"
                    x="84.095"
                    y="315.471"
                    width="33.642"
                    height="47.06"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-13" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-13" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5218"
                    x="82.299"
                    y="254.785"
                    width="52.295"
                    height="69.933"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-14" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-14" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5167-2-2"
                    x="86.386"
                    y="246.031"
                    width="27.318"
                    height="20.901"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-15" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-15" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5206"
                    x="84.095"
                    y="315.471"
                    width="33.642"
                    height="47.06"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-16" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-16" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5211"
                    x="82.299"
                    y="254.785"
                    width="52.295"
                    height="69.933"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-17" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-17" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5161-2"
                    x="33.471"
                    y="14.757"
                    width="58.443"
                    height="125.557"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-18" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-18" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5177-2"
                    x="25.173"
                    y="0"
                    width="35.099"
                    height="34.361"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-19" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-19" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5179-2"
                    x="0"
                    y="73.836"
                    width="29.874"
                    height="36.604"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-20" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-20" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5215"
                    x="75.468"
                    y="362.501"
                    width="66.17"
                    height="54.111"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-21" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-21" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5182-2"
                    x="40.152"
                    y="293.853"
                    width="22.734"
                    height="21.241"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-22" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-22" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5162-2"
                    x="58.746"
                    y="119.501"
                    width="52.663"
                    height="49.542"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-23" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-23" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5166-2"
                    x="25.185"
                    y="127.913"
                    width="56.022"
                    height="109.351"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-24" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-24" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5173-2"
                    x="93.339"
                    y="225.873"
                    width="17.717"
                    height="16.749"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-25" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-25" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5176-2"
                    x="83.53"
                    y="164.465"
                    width="46.262"
                    height="47.116"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-26" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-26" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5180-2"
                    x="30.833"
                    y="235.892"
                    width="30.875"
                    height="20.966"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-27" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-27" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5162-2-2"
                    x="58.746"
                    y="119.501"
                    width="52.663"
                    height="49.542"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-28" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-28" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5166-2-2"
                    x="25.185"
                    y="127.913"
                    width="56.022"
                    height="109.351"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-29" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-29" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5173-2-2"
                    x="93.339"
                    y="225.873"
                    width="17.717"
                    height="16.749"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-30" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-30" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5176-2-2"
                    x="83.53"
                    y="164.465"
                    width="46.262"
                    height="47.116"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-31" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-31" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_5180-2-2"
                    x="30.833"
                    y="235.892"
                    width="30.875"
                    height="20.966"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur-32" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur-32" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="Group_4026"
                  data-name="Group 4026"
                  transform="translate(-84.521 -186.518)"
                >
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5163-2)"
                  >
                    <path
                      id="Path_5163-2-2"
                      data-name="Path 5163-2"
                      d="M4734.592-134.38a18.08,18.08,0,0,0-9.168,2.583,87.566,87.566,0,0,0-10.766,8.115c-.136.285-2.727,2.664-4.58,3.007-.922.184-6.447.167-7.921.167-4.763.091-7.644,2.735-11.661,2.66-2.76-.09-4.373,2.923-5.1,3.646a20.287,20.287,0,0,0-2.023,5.866c-.671,7.376,2.965,15.688,7.461,16.33,3.47.177,5.647-3.729,8.519-6.616,1.626-1.425,2.71-.917,3.035-.81,5.084,2.533,6.231,9.174,6.677,10.518,1.124,4.247,10.453,16.753,18.271,16.753,6.892.529,14.165-3.627,19.446-3.387,2.18.327,4.494-7.891,6.778-8.712,4.267-2.193,6.023-5.394,6.673-10.114.5-4.294-7.312-21.677-8.487-24.279-.648-1.957-4.106-8.754-7.078-9.913-2.115-1.03-3.186-3.13-6.474-4.855A6.4,6.4,0,0,0,4734.592-134.38Z"
                      transform="translate(-20.11 -4024.36) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    id="Component_37_4"
                    data-name="Component 37 – 4"
                    transform="translate(146.039 974.763)"
                    onClick={() => setTab(2)}
                    className="cursor-pointer hover:brightness-105"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, -61.52, -788.25)"
                      filter="url(#Path_5164-2)"
                    >
                      <path
                        id="Path_5164-2-3"
                        data-name="Path 5164-2"
                        d="M6506.957,59.732a5.773,5.773,0,0,0-2.023,1.618c-.461.616-.941,1.387-.4,2.021.951.885,2.4-.248,2.832-.607.9-.9,1.616-1.524,1.011-2.63A1.025,1.025,0,0,0,6506.957,59.732Z"
                        transform="translate(160.1 -5716.05) rotate(90)"
                        fill={`${tab == 2 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -61.52, -788.25)"
                      filter="url(#Path_5165-2)"
                    >
                      <path
                        id="Path_5165-2-3"
                        data-name="Path 5165-2"
                        d="M6916.367,384.5c-1.165,1.165-1.218.618-1.214,1.965a.678.678,0,0,0,.684.675,4.427,4.427,0,0,0,2.553-.616c5.2-2.994,9.863-4.544,9.91-6.877l-.045-1.873-.008-.319s-3.646-5.647-7.444-5.9a12.583,12.583,0,0,1-4.236-1.011c-.592-.448-1.729-.654-1.612.806-.012,4.327.558,4.139,1.818,6.674l1.212,3.844C6918.243,382.545,6917.089,383.746,6916.367,384.5Z"
                        transform="translate(448.66 -6097.54) rotate(90)"
                        fill={`${tab == 2 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -61.52, -788.25)"
                      filter="url(#Path_5164-2-2)"
                    >
                      <path
                        id="Path_5164-2-4"
                        data-name="Path 5164-2"
                        d="M6506.957,59.732a5.773,5.773,0,0,0-2.023,1.618c-.461.616-.941,1.387-.4,2.021.951.885,2.4-.248,2.832-.607.9-.9,1.616-1.524,1.011-2.63A1.025,1.025,0,0,0,6506.957,59.732Z"
                        transform="translate(160.1 -5716.05) rotate(90)"
                        fill={`${tab == 2 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -61.52, -788.25)"
                      filter="url(#Path_5165-2-2)"
                    >
                      <path
                        id="Path_5165-2-4"
                        data-name="Path 5165-2"
                        d="M6916.367,384.5c-1.165,1.165-1.218.618-1.214,1.965a.678.678,0,0,0,.684.675,4.427,4.427,0,0,0,2.553-.616c5.2-2.994,9.863-4.544,9.91-6.877l-.045-1.873-.008-.319s-3.646-5.647-7.444-5.9a12.583,12.583,0,0,1-4.236-1.011c-.592-.448-1.729-.654-1.612.806-.012,4.327.558,4.139,1.818,6.674l1.212,3.844C6918.243,382.545,6917.089,383.746,6916.367,384.5Z"
                        transform="translate(448.66 -6097.54) rotate(90)"
                        fill={`${tab == 2 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5168-2)"
                  >
                    <path
                      id="Path_5168-2-2"
                      data-name="Path 5168-2"
                      d="M1580.188,710.424c1.66-.418,4.73,1.495,7.081.809,2.277-.873,4.78.24,9.71-2.225,2.2-1.387,2.744-3.017,2.631-4.452-.808-8.553-5.814-12.143-8.1-13.145-3.1-1.17-20.129-1.437-22.655-1.213-2.761.138-6.563.728-6.875,4.45-.021,2.805,1.682,7.825,2.023,9.908C1564.728,706.96,1572.9,711.527,1580.188,710.424Z"
                      transform="translate(745.85 -1124.69) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5169-2)"
                  >
                    <path
                      id="Path_5169-2-2"
                      data-name="Path 5169-2"
                      d="M1105,681.725c4.9-7.259,11.29,6.048,12.337-5.665.141-1.576-.777-6.269-1.006-7.485-.1-.548-.22-2.681-1.012-3.843a14.871,14.871,0,0,0-2.831-2.832,16,16,0,0,0-4.651-2.022c-1.584-.066-7.814-.747-10.114.809-2.315,2.018-9.2,6.516-9.306,10.313-.8,3.511.2,8.682,3.034,11.531,2.919,2.921,2.275.131,7.889,1.416C1101.352,684.408,1104.858,681.818,1105,681.725Z"
                      transform="translate(717.56 -684.5) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5170-2)"
                  >
                    <path
                      id="Path_5170-2-2"
                      data-name="Path 5170-2"
                      d="M2240.068,205.972c-.578,1.61-1.032,5.861-.2,7.282,1.012,1.7,1.815,7.835,3.236,10.52,2.31,4.73,7.126,10.295,15.168,9.3,7.072-.678,18-5.055,18.206-5.257,2.384-.914,4.592-5.008,4.855-7.488a19.073,19.073,0,0,0,.2-6.473c-.607-3.113-8.73-14.212-9.506-15.979,0-.009-3.663-7.017-4.855-8.5-.768-1.662-4.532-4.976-6.473-6.27a12.412,12.412,0,0,0-3.844-.607c-1.973-.323-3.89-.2-7.078,3.641-1.212,1.012-3.681,1.739-4.855,2.226a1.737,1.737,0,0,0-.809,2.224,8.12,8.12,0,0,1,.2,4.854C2244.317,196.315,2241.476,200.884,2240.068,205.972Z"
                      transform="translate(274.17 -1754.01) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5171-2)"
                  >
                    <path
                      id="Path_5171-2-2"
                      data-name="Path 5171-2"
                      d="M2879.484-137.38c-1.184.827,3.646,10.225,4.585,12.2.559,2.857.994,2.592,3.035,6.676,1.967,3.137,1.161,1.615,7.08,9.507a17.038,17.038,0,0,0,2.225,4.248c0,.007,2.623,2.63,2.63,2.63.282.355,2.419,1.618,6.675,1.618.118-.009,5.365.216,10.111-7.078.366-.871,3.3-5.017.406-9.913.04,0-2.284-4.229-2.427-5.462.18-1.979.613-5.242-2.022-7.482a18.79,18.79,0,0,0-12.945-3.641c-1.69.214-8.727,2.1-11.529,1.008C2886-133.648,2880.141-138.254,2879.484-137.38Z"
                      transform="translate(-22.99 -2348.46) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5172-2)"
                  >
                    <path
                      id="Path_5172-2-2"
                      data-name="Path 5172-2"
                      d="M1325.17-252.119c-.4,0-3.374-.548-2.831,5.057-.392,3.512-5.267,10.925-5.26,10.925a11.944,11.944,0,0,0-.2,12.745c1.361,1.835,9.425.448,11.328.4,5.366-.084,18.117.766,23.063,1.415,2.894.3,9.509-.145,9.506-2.226,0-1.653.164-4.119-2.023-5.056-.021-.022-4.37-.388-8.5-7.08-.753-1.179.443-2.25.2-3.237-.3-1.238-1.256-3.842-1.212-3.842a3.234,3.234,0,0,0-2.629-1.82c-1.359-.007-4.813-.258-6.069-1.214a21.647,21.647,0,0,0-8.5-3.439C1328.25-249.991,1326.965-252.119,1325.17-252.119Z"
                      transform="translate(-129.5 -895.37) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5174-2)"
                  >
                    <path
                      id="Path_5174-2-2"
                      data-name="Path 5174-2"
                      d="M-276.737,730.43c-4.92.922-21.5.409-25.086,1.82-3.774,1.483-12.022,4.232-13.552,7.282-1.048,2.088-2.835,3.438-3.237,5.462a14.048,14.048,0,0,1-.808,3.237c-1.791,3.111-3.259,1.905-2.026,3.844,4.013,5.512,8.571,2.366,13.755,4.451a24.308,24.308,0,0,0,7.889,2.022c13.4-.584,36.749,2.339,49.161,1.012,1.378-.259,6.259-1.58,9.1-2.022,2.16-.056,3.285-.164,3.439-3.235.217-4.322,1.582-3.329,2.429-7.688.7-3.436-.977-5.628-2.022-7.688-1.8-3.543-2.637-3.045-5.462-5.459C-253.51,724.619-264.524,728.775-276.737,730.43Z"
                      transform="translate(781.04 625.42) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    id="Component_35_4"
                    data-name="Component 35 – 4"
                    transform="translate(173.282 436.051)"
                    onClick={() => setTab(1)}
                    className="cursor-pointer hover:brightness-105"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, -88.76, -249.53)"
                      filter="url(#Path_5167-2)"
                    >
                      <path
                        id="Path_5167-2-3"
                        data-name="Path 5167-2"
                        d="M-1079.726-21.651c3.752,3.43,6.323-1.128,5.056-5.057-.232-2.431-.881-10.556-4.854-7.28C-1082.74-31.831-1083.292-23.91-1079.726-21.651Z"
                        transform="translate(72.44 1331.71) rotate(90)"
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="1"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -88.76, -249.53)"
                      filter="url(#Path_5217)"
                    >
                      <path
                        id="Path_5217-2"
                        data-name="Path 5217"
                        d="M-70.521-79.981c-2.938-6.418-7.09-3.065-10.922-6.27-15.6-8.417-17.629-5.355-21.441-3.639-2.487,1.12-1.079,9.9,0,12.539,1.124,2.746,6.316,3.671,8.5,4.449C-88.077-70.65-67.494-69.177-70.521-79.981Z"
                        transform="translate(19.38 423.24) rotate(90)"
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -88.76, -249.53)"
                      filter="url(#Path_5218)"
                    >
                      <path
                        id="Path_5218-2"
                        data-name="Path 5218"
                        d="M-950.16-315.04c-4.812,1.8-9.622,6.146-8.5,11.529.757,2.32,6.082,6.631,8.233,8.358,9.27,6.121,13.6,3.8,20.09,3.782,9.8,2.749,18.9,2.655,25.489-4.043,2.064-2.432,5.09-14.166.609-15.175-10.37-2.939-6.886,1.757-18.412-10.724C-935.844-338.426-936.326-322.752-950.16-315.04Z"
                        transform="translate(-200.91 1217.11) rotate(90)"
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -88.76, -249.53)"
                      filter="url(#Path_5167-2-2)"
                    >
                      <path
                        id="Path_5167-2-4"
                        data-name="Path 5167-2"
                        d="M-1079.726-21.651c3.752,3.43,6.323-1.128,5.056-5.057-.232-2.431-.881-10.556-4.854-7.28C-1082.74-31.831-1083.292-23.91-1079.726-21.651Z"
                        transform="translate(72.44 1331.71) rotate(90)"
                        fill={`${tab == 1 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -88.76, -249.53)"
                      filter="url(#Path_5206)"
                    >
                      <path
                        id="Path_5206-2"
                        data-name="Path 5206"
                        d="M-70.521-79.981c-2.938-6.418-7.09-3.065-10.922-6.27-15.6-8.417-17.629-5.355-21.441-3.639-2.487,1.12-1.079,9.9,0,12.539,1.124,2.746,6.316,3.671,8.5,4.449C-88.077-70.65-67.494-69.177-70.521-79.981Z"
                        transform="translate(19.38 423.24) rotate(90)"
                        fill={`${tab == 1 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -88.76, -249.53)"
                      filter="url(#Path_5211)"
                    >
                      <path
                        id="Path_5211-2"
                        data-name="Path 5211"
                        d="M-950.16-315.04c-4.812,1.8-9.622,6.146-8.5,11.529.757,2.32,6.082,6.631,8.233,8.358,9.27,6.121,13.6,3.8,20.09,3.782,9.8,2.749,18.9,2.655,25.489-4.043,2.064-2.432,5.09-14.166.609-15.175-10.37-2.939-6.886,1.757-18.412-10.724C-935.844-338.426-936.326-322.752-950.16-315.04Z"
                        transform="translate(-200.91 1217.11) rotate(90)"
                        fill={`${tab == 1 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5161-2)"
                  >
                    <path
                      id="Path_5161-2-2"
                      data-name="Path 5161-2"
                      d="M-4253.717,271.979c-3.928,0-2.959-.492-6.875,3.032-3.057,1.434-4.9,2.077-6.674,3.44-12.558,1.592-8.345,21.618-25.9,20.229-2.746-.435-3.644-2.236-7.686-2.628-2.944-1.326-2.246-.742-5.462-3.034-5.1-3.632-4.979-5.145-9.306-7.486-1.961-1.188-4.853-1.887-6.475-4.045-3.211-4.274-8.368-3.107-14.983-1.013-5.376,1.759,1.411,5.823,1.022,9.3,6.493,8.455,11.522,4.428,8.9,17.6,1.93,2.506,7.15,1.1,9.509,3.235,4.161,3.758,6.509,3.471,14.771,5.867,26.372,2.83,19.59-.657,34.188-9.509,5.542-3.979,14.739-.226,20.231-5.054,9.679-.273,7.269-6.815,17.8-8.9,4.7-.944,3.226-.7,4.047-1.213l-.407-13.52-7.372-4.521C-4239.438,272.857-4246.284,273.274-4253.717,271.979Z"
                      transform="translate(357.38 4357.41) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5177-2)"
                  >
                    <path
                      id="Path_5177-2-2"
                      data-name="Path 5177-2"
                      d="M-4531.9,718.978c-7.022-3.708-13.131,4.8-14.768,9.1a22.922,22.922,0,0,0,2.225,9.713c5.465,7.006,8.6-3.6,12.335-7.281,5.3-4.057,2.606-.807,6.675-4.652C-4524.676,721.188-4528.936,720.233-4531.9,718.978Z"
                      transform="translate(771.81 4550.23) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5179-2)"
                  >
                    <path
                      id="Path_5179-2-2"
                      data-name="Path 5179-2"
                      d="M-3503.133,1147.687c-2.1-3.168-3.655-1.806-3.844,0-.606,5.791,4.4,8.336,6.474,9.1,2.692,1,2.413,2.26,3.843,2.83,2.977,1.188,3.909,3.178,8.091,3.032,8.519-.3,4.558-5.723,1.417-6.469C-3495.113,1153.862-3500.613,1151.963-3503.133,1147.687Z"
                      transform="translate(1169.16 3584.37) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5215)"
                  >
                    <path
                      id="Path_5215-2"
                      data-name="Path 5215"
                      d="M598.546-399.023c-5.991-5.689-4.975-13.9-6.267-22.059-.483-3.045-.429-3.642-3.44-4.853-1.809-.728-4.524-.907-5.666-1.82-1.815-1.452-2.594,1.731-3.841,2.017-4.335,1.083-3.472.343-1.416,5.463,2.694,6.424,6.8,12.73-.81,16.583-6.268,3.171-11.129,1.988-16.994,3.641-1.926.571-.531,2.559-2.023,6.07-1.768,5.945,1.163,7.66,6.88,10.932-.437-.25,8.206,4.613,10.721,6.07,1.2.743,2.955,2.734,4.653,1.82,1.873-.621,2.143-1.328,3.032-4.45C583.763-389.447,600.616-389.3,598.546-399.023Z"
                      transform="translate(-293 -191.61) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    transform="matrix(1, 0, 0, 1, 84.52, 186.52)"
                    filter="url(#Path_5182-2)"
                  >
                    <path
                      id="Path_5182-2-2"
                      data-name="Path 5182-2"
                      d="M-408.186,685.132l-.607,1.416c-.394,2.9-.229,5.681,2.425,6.27,2.777.617,3.085-.325,5.058-2.428a2.579,2.579,0,0,0,.607-1.618c-.08-.464-.1-2.728-.2-3.235-.229-1.1-.422-1-1.618-1.616C-403.307,683.52-406.469,682.454-408.186,685.132Z"
                      transform="translate(739.58 706.29) rotate(90)"
                      fill="rgba(28,234,185,0.34)"
                      stroke="rgba(255,248,248,0.45)"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    id="Component_36_4"
                    data-name="Component 36 – 4"
                    transform="translate(116.195 309.485)"
                    onClick={() => setTab(3)}
                    className="cursor-pointer hover:brightness-110"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5162-2)"
                    >
                      <path
                        id="Path_5162-2-3"
                        data-name="Path 5162-2"
                        d="M-2828,14.943l-1.108,3.888s-5.077,4.425-7.793,6.225c-4.682,3.762-7.316,5.065-13.757,8.5a21.82,21.82,0,0,0-6.066,1.831l-.407-13.52-7.372-4.521c-.344-1.077.84-4.174,2.316-10.085.718-2.9,5.321-7.74,13.552-11.131,2.805-.934,4.1,3.437,7.485,6.272,1.472,1.542,6.948.221,10.114,2.225,2.371,1.714,1.357,1.558,2.628,4.855C-2827.763,11.434-2828.061,12.7-2828,14.943Z"
                        transform="translate(100.95 2987.53) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0.005"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5166-2)"
                    >
                      <path
                        id="Path_5166-2-3"
                        data-name="Path 5166-2"
                        d="M-2704.978,465.918c1.438-.869,5.121-4.482,8.089-6.27,3.425-2.064,8.894.969,13.352-.2,2.692-.708,16.787.432,20.429,5.259,1.107,1.466,5.417-.989,8.093-1.415,1.69-.269,3.826-.083,4.854-1.82,1.939-3.279-.154-10.455-.815-12.486-.472-1.149-1.149-3.747-7.881-10.575-4.817-4.886-10.862-8.856-12.342-12.138-.165-.365-.763-2.443-1.417-2.828-2.133-1.26-4.459.487-5.456,2.219-2.142,2.715-1.978,5.677-4.854,7.282a50.779,50.779,0,0,0-6.676,4.45c-2.649,2.905.159,5.107-4.043,4.452-3.8-.592-2.811-2.63-5.259-2.626-1.7,0-3.785.3-5.664-.2-12.1-3.211-22.8,3.983-34.8,4.448a16.34,16.34,0,0,0-4.046,1.214c-1.714.767-2.218,3-2.225,3.843-.027,2.917.638,2.366,3.035,3.645,3.432,1.832,1.378,2.7,4.853,4.045,2.946,1.137,5.28,3.53,7.284,4.246,3.338,1.192,5.4.341,7.888.808,2.958.449,6.234-.229,8.292,1.214C-2712.668,463.614-2706.216,466.666-2704.978,465.918Zm-10.923-26.9a.743.743,0,0,0-.606.2A.744.744,0,0,1-2715.9,439.014Z"
                        transform="translate(497.71 2877.05) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0.005"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5173-2)"
                    >
                      <path
                        id="Path_5173-2-3"
                        data-name="Path 5173-2"
                        d="M-1364.085,7.233c1.343.72,3.91-2.03,1.011-4.248C-1365.108,1.43-1366.592,5.753-1364.085,7.233Z"
                        transform="translate(107.2 1594.83) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0.005"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5176-2)"
                    >
                      <path
                        id="Path_5176-2-3"
                        data-name="Path 5176-2"
                        d="M-2231.169-242.783c-.838,7.254,4.161,3.967,6.065,8.5a14.528,14.528,0,0,0,5.664,4.855c19.068,7.037-.647-7.5,20.229-15.577,5.735-1.717-2.246-7.446-1.417-9.911-.462-1.522-.126-2.634-2.023-3.844-9.91-5.674-18.016,1.1-21.639,12.14C-2227.65-245.784-2229.488-246.131-2231.169-242.783Z"
                        transform="translate(-137.56 2399.2) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0.005"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5180-2)"
                    >
                      <path
                        id="Path_5180-2-3"
                        data-name="Path 5180-2"
                        d="M-1218.529,706.087c-1.294-2.227-2.728-12.251-6.066-7.283-1.071,1.894-.542,6.977-.607,10.519a8.029,8.029,0,0,0,1.617,4.45C-1218.454,718.594-1215.762,710.99-1218.529,706.087Z"
                        transform="translate(752.65 1464.65) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                        opacity="0.005"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5162-2-2)"
                    >
                      <path
                        id="Path_5162-2-4"
                        data-name="Path 5162-2"
                        d="M-2828,14.943l-1.108,3.888s-5.077,4.425-7.793,6.225c-4.682,3.762-7.316,5.065-13.757,8.5a21.82,21.82,0,0,0-6.066,1.831l-.407-13.52-7.372-4.521c-.344-1.077.84-4.174,2.316-10.085.718-2.9,5.321-7.74,13.552-11.131,2.805-.934,4.1,3.437,7.485,6.272,1.472,1.542,6.948.221,10.114,2.225,2.371,1.714,1.357,1.558,2.628,4.855C-2827.763,11.434-2828.061,12.7-2828,14.943Z"
                        transform="translate(100.95 2987.53) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5166-2-2)"
                    >
                      <path
                        id="Path_5166-2-4"
                        data-name="Path 5166-2"
                        d="M-2704.978,465.918c1.438-.869,5.121-4.482,8.089-6.27,3.425-2.064,8.894.969,13.352-.2,2.692-.708,16.787.432,20.429,5.259,1.107,1.466,5.417-.989,8.093-1.415,1.69-.269,3.826-.083,4.854-1.82,1.939-3.279-.154-10.455-.815-12.486-.472-1.149-1.149-3.747-7.881-10.575-4.817-4.886-10.862-8.856-12.342-12.138-.165-.365-.763-2.443-1.417-2.828-2.133-1.26-4.459.487-5.456,2.219-2.142,2.715-1.978,5.677-4.854,7.282a50.779,50.779,0,0,0-6.676,4.45c-2.649,2.905.159,5.107-4.043,4.452-3.8-.592-2.811-2.63-5.259-2.626-1.7,0-3.785.3-5.664-.2-12.1-3.211-22.8,3.983-34.8,4.448a16.34,16.34,0,0,0-4.046,1.214c-1.714.767-2.218,3-2.225,3.843-.027,2.917.638,2.366,3.035,3.645,3.432,1.832,1.378,2.7,4.853,4.045,2.946,1.137,5.28,3.53,7.284,4.246,3.338,1.192,5.4.341,7.888.808,2.958.449,6.234-.229,8.292,1.214C-2712.668,463.614-2706.216,466.666-2704.978,465.918Zm-10.923-26.9a.743.743,0,0,0-.606.2A.744.744,0,0,1-2715.9,439.014Z"
                        transform="translate(497.71 2877.05) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5173-2-2)"
                    >
                      <path
                        id="Path_5173-2-4"
                        data-name="Path 5173-2"
                        d="M-1364.085,7.233c1.343.72,3.91-2.03,1.011-4.248C-1365.108,1.43-1366.592,5.753-1364.085,7.233Z"
                        transform="translate(107.2 1594.83) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5176-2-2)"
                    >
                      <path
                        id="Path_5176-2-4"
                        data-name="Path 5176-2"
                        d="M-2231.169-242.783c-.838,7.254,4.161,3.967,6.065,8.5a14.528,14.528,0,0,0,5.664,4.855c19.068,7.037-.647-7.5,20.229-15.577,5.735-1.717-2.246-7.446-1.417-9.911-.462-1.522-.126-2.634-2.023-3.844-9.91-5.674-18.016,1.1-21.639,12.14C-2227.65-245.784-2229.488-246.131-2231.169-242.783Z"
                        transform="translate(-137.56 2399.2) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -31.67, -122.97)"
                      filter="url(#Path_5180-2-2)"
                    >
                      <path
                        id="Path_5180-2-4"
                        data-name="Path 5180-2"
                        d="M-1218.529,706.087c-1.294-2.227-2.728-12.251-6.066-7.283-1.071,1.894-.542,6.977-.607,10.519a8.029,8.029,0,0,0,1.617,4.45C-1218.454,718.594-1215.762,710.99-1218.529,706.087Z"
                        transform="translate(752.65 1464.65) rotate(90)"
                        fill={`${tab == 3 ? "#9ff291" : "#1CEAB9"}`}
                        stroke="#1acdce"
                        stroke-width="1"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>

          {/* Desktop */}
          <div className="col-span-9 invisible w-0 lg:w-full h-0 lg:visible lg:h-fit flex justify-center">
            <div className="py-2 flex flex-col">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center">
                <h1 className="h2 mb-4 text-4xl font-bold " data-aos="fade-up">
                  Locations
                </h1>
                <h1 className="h2 text-lg font-base" data-aos="fade-up">
                  Please select one of the highlighted atolls
                </h1>
              </div>

              <div className="flex justify-center items-center flex-row lg:mt-10 mt-5">
                <div className="grid grid-cols-3 gap-3 lg:gap-6">
                  {pageData.map((location) => (
                    <div
                      className={`cursor-pointer flex items-center justify-center text-lg p-1 px-2 lg:px-4 rounded-full border transition duration-300 ease-in-out mb-3 ${
                        tab !== location.index
                          ? "bg-white shadow-lg border-lightgray hover:shadow-lg"
                          : "bg-primary text-white border-none"
                      }`}
                      href="0"
                      onClick={(e) => {
                        e.preventDefault();
                        setTab(location.index);
                      }}
                    >
                      <h1 className="text-sm sm:text-base font-medium">
                        {" "}
                        {location.location}
                      </h1>
                    </div>
                  ))}
                </div>
              </div>
              <div className="grid gap-2 lg:grid-cols-3 sm:grid-cols-1 grid-cols-1 mb-2 mt-1 lg:mt-8">
                {/* Tabs buttons */}
                {pageData.map((loc) => (
                  <>
                    {/* Tabs items */}
                    <div
                      className="lg:col-span-3 gap-x-10 mb-8 lg:mb-0 lg:order-1"
                      ref={tabs}
                    >
                      {/* Item 1 */}
                      <Transition
                        show={tab === loc.index}
                        appear={true}
                        className="w-full"
                        enter="ease-in duration-300"
                        enterStart="opacity-0 translate-y-10"
                        enterEnd="opacity-100 translate-y-0"
                        leave=" ease-out duration-200 "
                        leaveStart="opacity-100 translate-y-0"
                        leaveEnd="opacity-0 -translate-y-2"
                      >
                        <div className="flex justify-center">
                          <div
                            className={`max-w-sm  mx-auto grid gap-10 grid-cols-1 ${
                              loc.addressData.length > 3
                                ? `lg:grid-cols-3`
                                : `lg:grid-cols-${loc.addressData.length}`
                            }   items-start lg:max-w-3xl lg:max-w-none`}
                          >
                            {loc.addressData.reverse().map((ld) => (
                              <>
                                {" "}
                                {/* Panel Items */}
                                <div className="bg-white h-card w-60 shadow-lg border  border-lightgray rounded-lg flex items-start justify-start pb-6">
                                  {/* Location */}
                                  <div className="grid grid-cols-6">
                                    {/* Content */}
                                    <div className=" col-span-6 p-4 grid grid-col-1 gap-5 px-4">
                                      <h1 className="text-lg font-medium py-3 border-b border-b-lightgray ">
                                        {ld.locationAddress.name}
                                      </h1>

                                      <div className="flex flex-row justify-start items-center">
                                        <ClockIcon className="h-4 w-4 text-parrot" />
                                        <p className="pl-2 text-sm font-medium">
                                          Opening Hours
                                        </p>
                                      </div>
                                      <div className="grid grid-cols-2 gap-0 gap-y-1 gap-x-4">
                                        <div className="font-medium items-center border-b pb-2 border-b-lightgray text-center text-xs">
                                          <p>SUN - THUR</p>
                                        </div>
                                        <div className="font-medium text-center border-b border-b-lightgray pb-2 items-start text-xs">
                                          <p>FRI - SAT</p>
                                        </div>
                                        {ld.adressOpeningHours.map((ao) => (
                                          <div className="text-center border-b border-b-lightgray pb-2 items-start text-xs">
                                            <p>{ao.saturday_to_thursday}</p>
                                          </div>
                                        ))}
                                        {ld.adressOpeningHours.map((ao) => (
                                          <div className="text-center border-b border-b-lightgray pb-2 items-start text-xs">
                                            <p>{ao.friday}</p>
                                          </div>
                                        ))}
                                      </div>

                                      <div className="flex flex-row justify-start items-center">
                                        <PhoneIcon className="h-4 w-4 text-parrot" />
                                        <p className="pl-2 font-medium capitalize text-sm">
                                          {ld.locationAddress.phone}
                                        </p>
                                      </div>
                                      <div className="flex flex-row justify-start items-center">
                                        <MailIcon className="h-4 w-4 text-parrot" />
                                        <p className="pl-2 font-medium text-sm">
                                          {ld.locationAddress.email}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>

          {/* Mobile */}
          <div className="col-span-9 flex md:hidden visible lg:invisible  flex justify-center items-start">
            <div className="py-2 flex flex-col items-center justify-center text-center">
              {/* Section header */}
              <div className="text-start grid grid-cols-1 gap-y-2 lg:gap-y-5">
                <h1
                  className="h2 mb-4 lg:text-5xl text-4xl font-bold "
                  data-aos="fade-up"
                >
                  Locations
                </h1>
                <h1
                  className="h2  text-base lg:text-lg font-base text-darkGray"
                  data-aos="fade-up"
                >
                  Please select one of the highlighted atollsss
                </h1>

                {/* Tabs & Panels */}
                <div className="">
                  <Tab.Group>
                    <div className="">
                      <Tab.List className={`grid py-2 grid-cols-3`}>
                        {pageData.map((loc) => (
                          <Tab
                            onClick={() => setTab(loc.index)}
                            className={({ selected }) =>
                              classNames(
                                "py-2.5 text-black border-b-4 border-b-primary/[0.1] border-transparent font-semibold text-base leading-5  px-4",
                                "focus:outline-none",
                                selected
                                  ? "border-b-lime/[9] "
                                  : "text-blue-100 hover:bg-white/[0.12]"
                              )
                            }
                          >
                            <p className="lg:text-xl text-sm">{loc.location}</p>
                          </Tab>
                        ))}
                      </Tab.List>
                    </div>

                    {/* Panels */}
                    <div className="px-10">
                      {pageData.map((loc) => (
                        <Tab.Panel>
                          <div
                            className={`grid mt-6 grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-4`}
                          >
                            {loc.addressData.map((ld) => (
                              <>
                                {" "}
                                {/* Panel Items */}
                                <div className="bg-white shadow-lg border border-lightgray rounded-lg flex items-center justify-center pb-6">
                                  {/* Location */}
                                  <div className="grid grid-cols-6">
                                    {/* Content */}
                                    <div className=" col-span-6 p-4 grid grid-col-1 gap-5 px-10">
                                      <h1 className="text-lg font-medium py-3 border-b border-b-lightgray ">
                                        {ld.locationAddress.name}
                                      </h1>

                                      <div className="flex flex-row justify-start items-center">
                                        <ClockIcon className="h-4 w-4 text-parrot" />
                                        <p className="pl-2 text-sm font-medium">
                                          Opening Hours
                                        </p>
                                      </div>
                                      <div className="grid grid-cols-2 gap-0 gap-y-1 gap-x-4">
                                        <div className="font-medium items-center border-b pb-2 border-b-lightgray text-center text-xs">
                                          <p>SUN - THUR</p>
                                        </div>
                                        <div className="font-medium text-center border-b border-b-lightgray pb-2 items-start text-xs">
                                          <p>FRI - SAT</p>
                                        </div>
                                        {ld.adressOpeningHours.map((ao) => (
                                          <div className="text-center border-b border-b-lightgray pb-2 items-start text-xs">
                                            <p>{ao.saturday_to_thursday}</p>
                                          </div>
                                        ))}
                                        {ld.adressOpeningHours.map((ao) => (
                                          <div className="text-center border-b border-b-lightgray pb-2 items-start text-xs">
                                            <p>{ao.friday}</p>
                                          </div>
                                        ))}
                                      </div>

                                      <div className="flex flex-row justify-start items-center">
                                        <PhoneIcon className="h-4 w-4 text-parrot" />
                                        <p className="pl-2 font-medium capitalize text-sm">
                                          {ld.locationAddress.phone}
                                        </p>
                                      </div>
                                      <div className="flex flex-row justify-start items-center">
                                        <MailIcon className="h-4 w-4 text-parrot" />
                                        <p className="pl-2 font-medium text-sm">
                                          {ld.locationAddress.email}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </Tab.Panel>
                      ))}
                    </div>
                  </Tab.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Illustration behind hero content */}
    </section>
  );
}

export default Index;
