import React, { useState, useEffect } from "react";
import Axios from "axios";

function Index() {
  const [video, setVideo] = useState();
  const [loading, setLoading] = useState(true);

  async function getVideo() {
    Axios.get(`${process.env.REACT_APP_DEV_API}/corp-video`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      setVideo(res.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    getVideo();
  }, []);
  return (
    <div className="">
      {/* <iframe className="absolute w-full h-full" src="https://www.youtube.com/watch?v=Kp81yR8bTBA" title="Video" allowFullScreen></iframe> */}
      {video && (
        <video
          className="xl:h-[94vh] h-full w-full object-fill"
          controls
          muted
          autoPlay
          loop
        >
          <source
            src={window.location.origin + `/uploads/videos/${video}`}
            type="video/mp4"
          />{" "}
          Your browser does not support the video tag.
        </video>
      )}
      {!video && (
        <video className="my-video" controls muted autoPlay loop>
          <source
            src={window.location.origin + `/uploads/videos/${video}`}
            type="video/mp4"
          />{" "}
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default Index;
