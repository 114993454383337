import React, { useState, useEffect, useRef, useCallback } from "react";

function Index(props) {
  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);
  return (
    <section className="relative bg-gradient-to-l">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 h-full py-14 flex items-center justify-center text-center flex-col">
        <div
          data-aos="fade-in"
          data-aos-once="true"
          className="pb-10 text-3xl lg:text-4xl text-black font-bold"
        >
          <h1>Rates</h1>
        </div>
        <div className="">
          <div>
            <div>
              <div
                className={`py-10 grid grid-cols-l gap-y-10 lg:grid-cols-${props.data.length} gap-x-10`}
              >
                {props.data.map((rate) => (
                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    className="rounded-3xl bg-lightSecondary  p-4  grid grid-cols-1 gap-y-4"
                  >
                    <h1 className="text-xl font-medium pb-4 border-b border-b-white">
                      MVR {rate.price}
                    </h1>
                    <div className="p-10 flex items-center justify-center">
                      {rate.imageURI && (
                        <img
                          src={
                            window.location.origin +
                            `/uploads/svg/rates/${rate.imageURI}`
                          }
                          alt={rate.imageURI}
                          className="h-[20vh]"
                        />
                      )}
                    </div>

                    <div className="bg-white shadow-lg rounded-3xl p-2  text-secondary">
                      <p className="uppercase text-2xl font-base">
                        {rate.category}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
