import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import React, { useState, useEffect, useRef, useCallback } from "react";
import styles, { bgWrap, bgText } from "./ads.module.css";
import Axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

SwiperCore.use([Autoplay, Pagination, Navigation]);

const Ads = (props) => {
  const [ads, setAds] = useState([]);
  const [screenHeight, setScreenHeight] = React.useState(0);
  const [path, setPath] = useState();
  React.useEffect(() => {
    setScreenHeight(window.innerWidth);
    console.log(screenHeight);
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_DEV_API}/advertisements`, {
      headers: {
        "api-key": `${process.env.REACT_APP_API_KEY}`,
        "api-secret": `${process.env.REACT_APP_API_SECRET}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setAds(res.data);
        setPath(
          window.location.origin + `/website/uploads/images/others/ddaa.jpg`
        );
      }
    });
  }, []);

  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);
  return (
    <div className="relative  h-screen xl:h-screen ">
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{ delay: 6000 }}
        pagination={true}
        className={styles.swiper}
      >
        <>
          {ads && (
            <>
              {ads.length >= 1 && (
                <>
                  {ads.map((ad) => (
                    <SwiperSlide
                      key={ad.id}
                      className={
                        (styles.swiperSlide, "flex items-center justify-center")
                      }
                    >
                      <div
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/uploads/images/others/${ad.bgImageURI})`,
                        }}
                        className="h-screen w-screen bg-cover bg-right-bottom  mx-auto px-4 sm:px-6  flex items-start justify-start lg:items-center lg:justify-center "
                      >
                        <div className="flex mx-0 lg:mx-10 w-full items-center justify-center">
                          <div className="grid grid-cols-1 w-full lg:grid-cols-2 gap-y-20 lg:gap-20 ">
                            <div className="flex items-center justify-center w-full">
                              <div className="grid w-full ml-0 lg:ml-20 xl:ml-40 mt-20 lg:mt-0 grid-cols-1 text-center lg:text-left gap-y-8 lg:gap-y-10 ">
                                <h1 className="text-5xl md:text-6xl xl:text-7xl text-white font-bold capitalize">
                                  {ad.title}
                                </h1>

                                <div>
                                  <p className="text-xl xl:text-4xl  text-white capitalize">
                                    {ad.description}
                                  </p>
                                </div>

                                {ad.href && ad.buttonText && (
                                  <>
                                    <div className="">
                                      <a
                                        href={ad.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className=" bg-button px-6 p-2 md:p-3 md:px-8 text-sm lg:text-xl cursor-pointer hover:shadow-lg hover:brightness-105 uppercase text-white font-medium rounded-full "
                                      >
                                        {ad.buttonText}
                                      </a>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>

                            {/*<div className="flex justify-center h-96   items-center">
                              <img
                                src={
                                  window.location.origin +
                                  `/website/uploads/images/others/${ad.imageURI}`
                                }
                                className=""
                                alt={ad.imageURI}
                              />
                            </div>*/}
                          </div>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          backgroundImage: `url(http://10.0.11.210:3001/uploads/images/others/ddaa.jpg)`,
                        }}
                        className="h-ad w-screen"
                      >
                        <div className="h-full w-full flex justify-center items-center">
                          <div className="h-banner max-w-6xl  flex items-center justify-center">
                            <div className="w-card">
                              <div className="grid grid-cols-1 text-center lg:text-left gap-y-5 lg:gap-y-10 ">
                                <h1 className="text-6xl lg:text-8xl text-white font-bold capitalize">
                                  {ad.title}
                                </h1>

                                <div>
                                  <p className="text-lg lg:text-2xl font-medium text-white capitalize">
                                    {ad.description}
                                  </p>
                                </div>

                                {ad.href && ad.buttonText && (
                                  <>
                                    <div className="">
                                      <a
                                        href={ad.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className=" bg-button px-6 p-2 text-sm lg:text-base cursor-pointer hover:shadow-lg hover:brightness-105 uppercase text-white font-medium rounded-full "
                                      >
                                        {ad.buttonText}
                                      </a>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="w-card flex items-center justify-center">
                              {/* <SVG
                                name={ad.imageURI}
                                onCompleted={handleOnCompleted}
                                onError={handleIconError}
                              /> */}
                    </SwiperSlide>
                  ))}
                  ``
                </>
              )}
            </>
          )}
        </>
      </Swiper>
    </div>
  );
};

export default Ads;
